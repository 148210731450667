import React, { useMemo } from "react";

import { Form, Table, Alert, Button, Input, Spin } from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { PageHeaderWrapper } from "@ant-design/pro-layout";

import { useQuery } from "hooks/useRouter";
import { renderRecordActions } from "utils/helpers";
import { useTable } from "hooks/useTableApi";
import { FormInstance } from "antd/lib/form";
import { DrawerFormWithForwardRef, DrawerFormChildProps } from "components/DrawerFormApi";
import UserForm from "pages/users/form";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerFormApi";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { useStoreState, useStoreActions } from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import { axiosConfig } from "utils/request";
import { useAxiosRequest } from "use-axios-request";
import { find } from "lodash";

import api from "api";
import { IUser } from "api/interfaces/User";

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    name: undefined
  })
}

const UsersList: React.FC = () => {
  // useWhyDidYouUpdate('UsersList', props);
  const rolesRequest = useMemo(() => ({...axiosConfig, url: "/users/roles"}), []);

  const { data, isFetching: isFetchingRoles } = useAxiosRequest<{ items: SelectOption[] }>(rolesRequest);
  const { items: roles = [] } = data || {};

  const { currentUser: currentAppUser = { id: "" } } = useStoreState(state => state.app);
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const setCurrentAppUser = useStoreActions(actions => actions.app.setCurrentUser);

  const [form] = Form.useForm();

  const { filters: query } = useQuery();

  const { tableProps, error, refresh, reload, search } = useTable<IUser>(api.users, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IUser> => {
    const refreshTableAndCurrentUser = (item: IUser) => {
      refresh();

      if (item.id === currentAppUser.id) {
        setCurrentAppUser(item);
      }
    };

    return {
      title: "Usuari",
      handleCreated: refreshTableAndCurrentUser,
      handleUpdated: refreshTableAndCurrentUser,
      newRecord: api.users.newInstance()
    }
  }, [refresh, setCurrentAppUser, currentAppUser])

  const { create, edit, drawerProps } = useDrawerForm<IUser>(api.users, formOptions);

  const columns: ColumnType<IUser>[] = [
    {
      title: 'Nom',
      dataIndex: ['attributes', 'full_name'],
      key: "name"
    },
    {
      title: 'Correu electrònic',
      dataIndex: ['attributes', 'email'],
      key: "email"
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: "role",
      render: (text: string, record: IUser) : React.ReactNode => {
        return isFetchingRoles ? <Spin /> : find(roles, { value: record.attributes.role })?.label;
      }
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="name" style={{marginRight: 0}}>
          <Input.Search placeholder="Nom" loading={tableProps.loading as boolean} onSearch={form.submit} />
        </Form.Item>
        <Form.Item>
         <Button type="link" onClick={resetSearh}>Reiniciar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["users:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper >
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <UserForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </PageHeaderWrapper>
  );
};

export default UsersList;
