import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { IAttachmentCollectionResult, IAttachmentCollectionsResult, IAttachmentCollection } from '../interfaces/AttachmentCollection'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";
import {Routes} from "../routes";

export default class AttachmentCollections extends Http implements IQueryList, IQueryEditable {
  private readonly path: string;
  private readonly vaultId: string|number;

  constructor(path: string, vaultId: string|number, host?: string) {
    super(host);
    this.path = path;
    this.vaultId = vaultId;
  }

  public async list(params: IQuery = {}): Promise<IAttachmentCollectionsResult> {
    return await this.request(GET, Routes.attachmentCollectionsPath(this.path, this.vaultId), {}, params) as IAttachmentCollectionsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IAttachmentCollectionResult> {
    return await this.request(GET, Routes.attachmentCollectionPath(this.path, this.vaultId, id), {}, params) as IAttachmentCollectionResult
  }

  public async create(params: JsonApiDocument): Promise<IAttachmentCollectionResult> {
    return await this.request(POST, Routes.attachmentCollectionsPath(this.path, this.vaultId), {}, params) as IAttachmentCollectionResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IAttachmentCollectionResult> {
    return await this.request(PATCH, Routes.attachmentCollectionPath(this.path, this.vaultId, id), {}, params) as IAttachmentCollectionResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.attachmentCollectionPath(this.path, this.vaultId, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance(): IAttachmentCollection {
    return {
      id: null,
      type: "AttachmentCollections",
      attributes: {
        name: "",
        updated_at: null,
        is_empty: true,
        has_files: false,
        ancestors: []
      },
      meta: {
        permissions: {
          can_edit: true,
          can_show: true,
          can_destroy: true
        }
      }
    };
  }
};
