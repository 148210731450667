import React, {useMemo} from "react";

import {Form, Table, Alert, Button, DatePicker, Select} from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { FormInstance } from "antd/lib/form";
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined } from "@ant-design/icons";
import { useAxiosRequest } from "use-axios-request";

import {useRouter, useQuery} from "hooks/useRouter";
import { useTable } from "hooks/useTableApi";
import {IIncidence} from "api/interfaces/Incidence";
import {filterOptionByLabel, handleDestroy, renderRecordActions, renderRecordDateAndTime} from "utils/helpers";
import { useStoreState } from "utils/store";
import api from "api";
import { compact } from "lodash";
import { dateFormats, dateRangeFromServer } from "utils/formats";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { IDictionary } from "api/interfaces/Dictionary";
import { IUser } from "api/interfaces/User";

const { RangePicker } = DatePicker;

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    date_between: undefined,
    space: undefined,
    type: undefined,
    severity_level: undefined,
    user: undefined,
    closing_reason: undefined
  })
}

const editURL = (id: string) => `/incidences/${id}`;

const incidenceTypesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("incidence_types"), params: { items: "all" } };
const closingReasonsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("incidence_closing_reasons"), params: { items: "all" } };
const severityLevelsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("incidence_severity_levels"), params: { items: "all" } };
const spacesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("spaces"), params: { items: "all" } };
const usersRequest = { ...axiosConfig, baseURL: "/", url: Routes.usersPath(), params: { items: "all" } };

const IncidencesList: React.FC = () => {
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();
  const router = useRouter();
  const { filters: query } = useQuery();

  const searchQuery = useMemo(() => {
    if (!query) {
      return undefined;
    }

    const { date_between: dateRange } = query;

    const date_between = dateRange ? dateRangeFromServer(dateRange) : undefined;

    return { ...query, date_between };
  }, [query]);

  const { data: incidenceTypesData, isFetching: isFetchingIncidenceTypes } = useAxiosRequest<{ data: IDictionary[] }>(incidenceTypesRequest);
  const { data: incidenceTypes = [] } = incidenceTypesData || {};

  const { data: severityLevelsData, isFetching: isFetchingSeverityLevels } = useAxiosRequest<{ data: IDictionary[] }>(severityLevelsRequest);
  const { data: severityLevels = [] } = severityLevelsData || {};

  const { data: closingReasonsData, isFetching: isFetchingClosingReasons } = useAxiosRequest<{ data: IDictionary[] }>(closingReasonsRequest);
  const { data: closingReasons = [] } = closingReasonsData || {};

  const { data: spacesData, isFetching: isFetchingSpaces } = useAxiosRequest<{ data: IDictionary[] }>(spacesRequest);
  const { data: spaces = [] } = spacesData || {};

  const { data: usersData, isFetching: isFetchingUsers } = useAxiosRequest<{ data: IUser[] }>(usersRequest);
  const { data: users = [] } = usersData || {};

  const { tableProps, error, reload, search } = useTable<IIncidence>(api.incidences, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearch } = search!;

  const goToIncidence = (id: string) => router.push(editURL(id));
  const goToCreateIncidence = () => router.push(`/incidences/new`);

  const destroyAction = useMemo(() => {
    return handleDestroy(api.incidences, reload)
  }, [reload]);

  const columns: ColumnType<IIncidence>[] = [
    {
      title: 'Inici',
      key: "start_date",
      render: renderRecordDateAndTime("start_date", "starts_at")
    },
    {
      title: 'Fi',
      key: "end_date",
      render: renderRecordDateAndTime("end_date", "ends_at")
    },
    {
      title: 'Gravetat',
      key: "severity_level",
      ellipsis: { showTitle: false },
      dataIndex: ["attributes", "incidence_severity_level_name"]
    },
    {
      title: 'Tipus',
      key: "types",
      ellipsis: { showTitle: false },
      dataIndex: ["attributes", "incidence_type_names"]
    },
    {
      title: 'Espais',
      key: "spaces",
      ellipsis: { showTitle: false },
      dataIndex: ["attributes", "space_names"]
    },
    {
      title: 'Professional',
      key: "user_name",
      ellipsis: { showTitle: false },
      dataIndex: ["attributes", "user_name"]
    },
    {
      title: 'M. Tancament',
      key: "closing_reason",
      ellipsis: { showTitle: false },
      dataIndex: ["attributes", "incidence_closing_reason_name"]
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToIncidence, destroyAction, { editURL })
    }
  ];

  const onFinish = (formValues: {[key: string]: any}) : void =>  {
    const dateRange = compact<moment.Moment>(formValues["date_between"]);

    const date_between = dateRange.length > 0
      ? [dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')].join("...")
      : undefined;

    searchSubmit({ ...formValues, date_between});
  }

  const toolbar = (
    <>
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "start", justifyContent: 'end' }}>
      <PermissibleRender userPermissions={userPermissions} requiredPermissions={["incidences:create"]}>
        <Button icon={<PlusOutlined />} onClick={goToCreateIncidence}>Afegir</Button>
      </PermissibleRender>
    </div>
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "start", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={searchQuery} layout="inline" onFinish={onFinish}>
        <Form.Item name="date_between">
          <RangePicker className="w100" format={dateFormats.display} placeholder={["Des de", "Fins"]} separator="-" />
        </Form.Item>
        <Form.Item name="severity_level">
          <Select placeholder="Nivell de gravetat" loading={isFetchingSeverityLevels} filterOption={filterOptionByLabel} showSearch dropdownMatchSelectWidth={false} allowClear>
            {severityLevels.map((item) => <Select.Option key={item.id} value={item.id}>{item.attributes.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="type">
          <Select placeholder="Tipus d'incidències" loading={isFetchingIncidenceTypes} filterOption={filterOptionByLabel} showSearch dropdownMatchSelectWidth={false} allowClear>
            {incidenceTypes.map((item) => <Select.Option key={item.id} value={item.id}>{item.attributes.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="space">
          <Select placeholder="Espai" loading={isFetchingSpaces} filterOption={filterOptionByLabel} showSearch dropdownMatchSelectWidth={false} allowClear>
            {spaces.map((item) => <Select.Option key={item.id} value={item.id}>{item.attributes.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="closing_reason">
          <Select placeholder="Motiu de tancament" loading={isFetchingClosingReasons} filterOption={filterOptionByLabel} showSearch dropdownMatchSelectWidth={false} allowClear>
            {closingReasons.map((item) => <Select.Option key={item.id} value={item.id}>{item.attributes.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="user">
          <Select placeholder="Professional" loading={isFetchingUsers} filterOption={filterOptionByLabel} showSearch dropdownMatchSelectWidth={false} allowClear>
            {users.map((item) => <Select.Option key={item.id} value={item.id}>{item.attributes.full_name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearch}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
    </div>
    </>
  );


  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper >
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />
    </PageHeaderWrapper>
  );
};

export default IncidencesList;
