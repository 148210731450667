import { GET } from '../../../../api/constants'
import Http from '../../../../api/Http'
import { IQuery  as IRequestParams, IQueryList } from '../../../../api/interfaces/Query'
import { IQueriesResult } from '../interfaces/Query'
import { Routes } from '../routes'

export default class QueryExecutions extends Http implements IQueryList {
  private readonly queryId: string;

  constructor(queryId: string, host?: string) {
    super(host);
    this.queryId = queryId;
  }

  public async list(params: IRequestParams = {}): Promise<IQueriesResult> {
    return await this.request(GET, Routes.executeQueryPath(this.queryId), {}, params) as IQueriesResult
  }
}
