const basePath = `api`

export const Routes = {
  categoriesPath: () => `${basePath}/consultes/categories`,
  categoryPath: (id: string) => `${basePath}/consultes/categories/${id}`,
  queriesPath: () => `${basePath}/consultes/queries`,
  queryPath: (id: string) => `${basePath}/consultes/queries/${id}`,
  queryChangePosition: (id: string) => `${basePath}/consultes/queries/${id}/change-position`,
  executeQueryPath: (id: string) => `${basePath}/consultes/queries/${id}/execute`,
}
