import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { IUserResult, IUsersResult, IUser } from '../interfaces/User'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";

export default class Users extends Http implements IQueryList, IQueryEditable {
  public async list(params: IQuery = {}): Promise<IUsersResult> {
    return await this.request(GET, Routes.usersPath(), {}, params) as IUsersResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IUserResult> {
    return await this.request(GET, Routes.userPath(id), {}, params) as IUserResult
  }

  public async create(params: JsonApiDocument): Promise<IUserResult> {
    return await this.request(POST, Routes.usersPath(), {}, params) as IUserResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IUserResult> {
    return await this.request(PATCH, Routes.userPath(id), {}, params) as IUserResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.userPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IUser {
    return {
      id: null,
      type: "users",
      attributes: {
        dni: "",
        first_name: "",
        last_name: "",
        email: "",
        role: "",
        full_name: "",
        avatar_color: undefined,
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
