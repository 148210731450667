import React from 'react';

import { DAYS } from '../../../constants/index';

const RepeatMonthlyOnThe = ({
  mode,
  onThe,
  hasMoreModes,
  handleChange,
}) => {
  const isActive = mode === 'on the';

  return (
    <div className={`form-group row d-flex align-items-sm-center ${!isActive && 'opacity-50'}`}>
      <div className="col-sm-1 offset-sm-2">
        {hasMoreModes && (
          <input
            type="radio"
            name="repeat.monthly.mode"
            aria-label="Repeat monthly on the"
            value="on the"
            checked={isActive}
            onChange={handleChange}
          />
        )}
      </div>
      <div className="col-sm-1">
        El
      </div>

      <div className="col-sm-2">
        <select
          name="repeat.monthly.onThe.which"
          aria-label="Repeat monthly on the which"
          className="form-control"
          value={onThe.which}
          disabled={!isActive}
          onChange={handleChange}
        >
          <option value="First">Primer</option>
          <option value="Second">Segon</option>
          <option value="Third">Tercer</option>
          <option value="Fourth">Quart</option>
          <option value="Last">Últim</option>
        </select>
      </div>

      <div className="col-sm-3">
        <select
          name="repeat.monthly.onThe.day"
          aria-label="Repeat monthly on the day"
          className="form-control"
          value={onThe.day}
          disabled={!isActive}
          onChange={handleChange}
        >
          {DAYS.map(day => <option key={day} value={day}>{day.toLowerCase()}</option>)}
        </select>
      </div>

    </div>
  );
};
// RepeatMonthlyOnThe.propTypes = {
//   id: PropTypes.string.isRequired,
//   mode: PropTypes.oneOf(['on', 'on the']).isRequired,
//   onThe: PropTypes.shape({
//     which: PropTypes.oneOf(['First', 'Second', 'Third', 'Fourth', 'Last']).isRequired,
//     day: PropTypes.oneOf(DAYS).isRequired,
//   }).isRequired,
//   hasMoreModes: PropTypes.bool.isRequired,
//   handleChange: PropTypes.func.isRequired,
//   translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default RepeatMonthlyOnThe;
