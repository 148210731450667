import React, { useState } from "react";

import { IdcardOutlined, LockOutlined } from '@ant-design/icons';
import { Col, Input, Button, Checkbox, Form, Row, message, Alert } from "antd";

import BlankLayout from "layouts/BlankLayout";

import { useRouter } from "hooks/useRouter";
import { CurrentUser } from "models/app";
import { login, LoginParamsType } from "services/auth";
import { RequestResult } from "utils/request";
import { useStoreActions } from "utils/store";

import "./login.less";
import logo from "assets/logo.svg";

const LoginForm : React.FC = (props) => {
  const { history } = useRouter();

  const signInUser = useStoreActions(actions => actions.app.signIn);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (formValues: {[key: string]: any}) : Promise<void> =>  {
    message.destroy();

    setErrorMessage("");
    setLoading(true);

    const response = await login(formValues as LoginParamsType)

    setLoading(false);

    if (response.success) {
      handleSubmitSuccess(response.data.user);
    } else {
      handleSubmitFailure(formValues, response);
    }
  };

  const handleSubmitSuccess = (user: CurrentUser) : void => {
    // response: AxiosResponse
    signInUser({ user, history });
  };

  const handleSubmitFailure = (values: any, response: RequestResult) : void => {
    if (response.statusCode === 401) {
      setErrorMessage(response.message!);
    } else {
      message.error("Hi hagut un error desconegut", 10);
    }
  };

  return (
    <BlankLayout>
      <Row justify="center" align="middle" className="full-height">
        <Col xs={20} md={12} lg={6} className="login-form">
          <img src={logo} alt="logo" />

          <Form
            name="login_form"
            onFinish={onFinish}
          >
            {errorMessage && <Alert type="error" message={errorMessage} style={{ marginBottom: "10px" }} />}
            <Form.Item
              name="dni"
              rules={[
                { required: true, message: "Aquest camp és requerit." }
              ]}
            >
              <Input
                prefix={<IdcardOutlined
                style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="DNI" disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Aquest camp és requerit." }
              ]}
            >
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} type="password"
                placeholder="Contrasenya"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="remember_me"
              valuePropName="checked"
            >
                <Checkbox disabled={loading}>Mantenir-me identificat</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} className="login-form-button">
                Iniciar sessió
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </BlankLayout>
  );
};

export default LoginForm;
