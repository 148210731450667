
const computeYearlyOnMonth = (data, rruleObj) => {
  if (rruleObj.freq !== 0 || !rruleObj.bymonthday) {
    return data.repeat.yearly.on.month;
  }

  if (typeof rruleObj.bymonth === 'number') {
    return rruleObj.bymonth;
  }

  return rruleObj.bymonth[0];
};

export default computeYearlyOnMonth;
