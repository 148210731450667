import React, { useMemo } from "react";
import {Button, Table, Alert} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {
  renderRecordDate,
  renderObservations,
  renderRecordTime,
  renderRecordActions,
  handleDestroy,
} from "utils/helpers";
import {ITeamPresence} from "api/interfaces/team/Presence";

interface PresencesListProps {
  teamId: string
}

const PresencesList: React.FC<PresencesListProps> = ({ teamId }) => {
  const apiEndpoint = React.useMemo(() => api.teamPresences(teamId), [teamId]);

  const { tableProps, error, reload } = useInnerTable<ITeamPresence>(apiEndpoint);

  const destroyAction = useMemo(() => {
    return handleDestroy(apiEndpoint, reload)
  }, [apiEndpoint, reload]);

  const renderActions = (text: string, record: any, index: number) => {
    console.log(record.attributes.kind)
    if (record.attributes.kind !== "space_stat")
       return <></>;

    record.meta.permissions.can_show = false;
    record.meta.permissions.can_edit = false;

    return (
      renderRecordActions(null, destroyAction)("", record, index)
    );
  }

  const columns: ColumnType<ITeamPresence>[] = [
    { title: "Dia", key: "date", render: renderRecordDate("day") },
    { title: "Entrada", key: "from", render: renderRecordTime("from") },
    { title: "Sortida", key: "to", render: renderRecordTime("to") },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: "",
      key: "actions",
      align: "right",
      render: renderActions
    }
  ];

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />
    </>
  );
};

export default PresencesList;
