import React, {useMemo} from "react";
import {Button, Table, Alert} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {handleDestroy, renderRecordActions} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {ModalFormChildProps, ModalFormWithForwardRef} from "components/ModalFormApi";
import {ICessionEntity} from "api/interfaces/cession/Entity";
import RemoteSelector from "components/Forms/RemoteSelector";

interface EntitiesListProps {
  cessionId: string
}

const EntitiesList: React.FC<EntitiesListProps> = ({ cessionId }) => {
  const apiEndpoint = React.useMemo(() => api.cessionEntities(cessionId), [cessionId]);

  const { tableProps, error, refresh, reload } = useInnerTable<ICessionEntity>(apiEndpoint);

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<ICessionEntity> => {
    return {
      title: "Afegir entitat",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, drawerProps } = useDrawerForm<ICessionEntity>(apiEndpoint, formOptions);

  const destroyAction = useMemo(() => {
    return handleDestroy(apiEndpoint, refresh)
  }, [apiEndpoint, refresh]);

  const columns: ColumnType<ICessionEntity>[] = [
    { title: "Nom", key: "name", dataIndex: ["attributes","entity_name"] },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(null, destroyAction)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["cessions:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: ModalFormChildProps) => (
          <RemoteSelector
            form={form}
            readOnly={readOnly}
            valuePath={["id"]}
            labelPath={["attributes", "name"]}
            attributeLabel="Entitat"
            attributeName={["attributes", "entity_id"]}
            queryParam="name"
            endpoint={api.entities} />
        )}
      </ModalFormWithForwardRef>
    </>
  );
};

export default EntitiesList;
