import React, {useMemo} from "react";
import {Button, Table, Alert} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions, renderRecordDate, renderObservations, handleDestroy} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {IIndividualDemandNeedAnswer} from "api/interfaces/individual/DemandNeedAnswer";
import DemandNeedAnswerForm from "../forms/demand_need_answer";

interface DemandNeedAnswersListProps {
  individualId: string
}

const DemandNeedAnswersList: React.FC<DemandNeedAnswersListProps> = ({ individualId }) => {
  const apiEndpoint = React.useMemo(() => api.individualDemandsNeedsAnswers(individualId), [individualId]);

  const { tableProps, error, refresh, reload } = useInnerTable<IIndividualDemandNeedAnswer>(apiEndpoint);

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IIndividualDemandNeedAnswer> => {
    return {
      title: "Resposta demanda/necessitat",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<IIndividualDemandNeedAnswer>(apiEndpoint, formOptions);

  const destroyAction = useMemo(() => {
    return handleDestroy(apiEndpoint, refresh)
  }, [apiEndpoint, refresh]);

  const columns: ColumnType<IIndividualDemandNeedAnswer>[] = [
    { title: "Dia", key: "date", render: renderRecordDate("date") },
    { title: "Tipus", key: "demand_need_response_type", dataIndex: ["attributes", "demand_need_response_type_name"] },
    { title: "Activitat", key: "activity_name", dataIndex: ["attributes", "activity_name"] },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, destroyAction)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["individuals:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <DemandNeedAnswerForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default DemandNeedAnswersList;
