import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { ITeamMisbehaviourResult, ITeamMisbehavioursResult, ITeamMisbehaviour } from '../../interfaces/team/Misbehaviour'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class TeamMisbehaviours extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly team_id: string;

  protected dateFields: string[] = ["date"];

  constructor(team_id: string, host?: string) {
    super(host);
    this.team_id = team_id;
  }

  public async list(params: IQuery = {}): Promise<ITeamMisbehavioursResult> {
    return await this.request(GET, Routes.teamMisbehavioursPath(this.team_id), {}, params) as ITeamMisbehavioursResult
  }

  public async show(id: string, params: IQuery = {}): Promise<ITeamMisbehaviourResult> {
    return await this.request(GET, Routes.teamMisbehaviourPath(this.team_id, id), {}, params) as ITeamMisbehaviourResult
  }

  public async create(params: JsonApiDocument): Promise<ITeamMisbehaviourResult> {
    return await this.request(POST, Routes.teamMisbehavioursPath(this.team_id), {}, params) as ITeamMisbehaviourResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ITeamMisbehaviourResult> {
    return await this.request(PATCH, Routes.teamMisbehaviourPath(this.team_id, id), {}, params) as ITeamMisbehaviourResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.teamMisbehaviourPath(this.team_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : ITeamMisbehaviour {
    return {
      id: null,
      type: "team_misbehaviours",
      attributes: {
        date: moment.utc(),
        observations: "",
        incivive_behaviors: false,
        bets: false,
        alcohol: false,
        toxics: false
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
