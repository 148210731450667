import React from 'react';
import { Col, InputNumber, Form } from 'antd';
import { twoColumns } from 'utils/constants';

const EndAfter = ({
  after,
  handleChange,
}) => (
  <Col {...twoColumns}>
    <Form.Item label="Vegades" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
      <InputNumber
        step={1}
        min={1}
        value={after}
        onChange={(value) => handleChange("end.after", value)}
      />
    </Form.Item>
  </Col>
);

// EndAfter.propTypes = {
//   id: PropTypes.string.isRequired,
//   after: PropTypes.number.isRequired,
//   handleChange: PropTypes.func.isRequired,
//   translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default EndAfter;
