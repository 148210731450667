import React from "react";
import { Form, Row, Col, Input, Button, Select } from "antd";
import {rowConfig, oneColumn, twoColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {IQuery} from "../../api/interfaces/Query";
import {IQueryEditable} from "api/interfaces/Query";
import { axiosConfig } from "utils/request";
import { useAxiosRequest } from "use-axios-request";
import { filterOptionByLabel } from "utils/helpers";
import { Routes } from "packages/progess-consultes/api/routes";
import { ICategory } from "packages/progess-consultes/api/interfaces/Category";

interface QueryFormProps {
  api: IQueryEditable,
  record: IQuery,
  handleCreated?: (item: IQuery | IQuery[]) => void,
  handleUpdated?: (item: IQuery) => void,
}

class InnerForm extends PageForm<IQuery> {}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
}

const categoriesRequest = { ...axiosConfig, baseURL: "/", url: Routes.categoriesPath(), params: { items: "all" } };

const QueryForm: React.FC<QueryFormProps> = ({ api, record, handleCreated = () => void 0, handleUpdated = () => void 0 }) => {
  const [form] = Form.useForm();
  const { data: categoriesData, isFetching: isFetchingCategories } = useAxiosRequest<{ data: ICategory[] }>(categoriesRequest);
  const { data: categories = [] } = categoriesData || {};

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleUpdated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Row {...rowConfig}>
            <Col {...oneColumn}>
              <Form.Item name={["attributes", "name"]} label="Nom" rules={rules.required}>
                <Input disabled={readOnly} placeholder="Nom de la consulta" />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "description"]} label="Descripció">
                <Input.TextArea disabled={readOnly} placeholder="Consulta que retorna ..." autoSize={{minRows: 3}} />
              </Form.Item>
            </Col>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "category_ids"]} label="Categories" rules={rules.required}>
                <Select disabled={readOnly} placeholder="Sel·lecciona un element" mode="multiple" loading={isFetchingCategories} filterOption={filterOptionByLabel} showSearch>
                  {categories.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...oneColumn}>
              <Form.Item name={["attributes", "sql"]} label="Consulta SQL" rules={rules.required}>
                <Input.TextArea disabled={readOnly} placeholder="SELECT ..." autoSize={{minRows: 3}} />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...oneColumn}>
              <Form.Item name={["attributes", "fields"]} label="Ordre dels camps" rules={rules.required}>
                <Input disabled={readOnly} placeholder="Camps a mostrar separats per ':'" />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default QueryForm;
