import React from "react"
import {DatePicker, Form, Input, Radio} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "utils/formats";


export interface MisbehaviourFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const MisbehaviourForm: React.FC<MisbehaviourFormProps> = ({ readOnly = false }) => {
  return (
    <>
      <Form.Item name={["attributes", "date"]} label="Dia" rules={rules.required}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "incivive_behaviors"]} label="Actes incívics">
        <Radio.Group disabled={readOnly}>
          <Radio value={false}>No</Radio>
          <Radio value>Sí</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={["attributes", "bets"]} label="Apostes">
        <Radio.Group disabled={readOnly}>
          <Radio value={false}>No</Radio>
          <Radio value>Sí</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={["attributes", "alcohol"]} label="Alcohol">
        <Radio.Group disabled={readOnly}>
          <Radio value={false}>No</Radio>
          <Radio value>Sí</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={["attributes", "toxics"]} label="Tòxics">
        <Radio.Group disabled={readOnly}>
          <Radio value={false}>No</Radio>
          <Radio value>Sí</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={["attributes", "observations"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default MisbehaviourForm;
