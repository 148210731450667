import React, {useState} from "react";
import {Form, Row, Col, Input, Button, Radio, DatePicker, Select, Collapse, InputNumber} from "antd";
import {rowConfig, twoColumns, fourColumns, oneColumn, threeColumns, sixColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {IActivity} from "api/interfaces/Activity";
import {IQueryEditable} from "../../api/interfaces/Query";
import { dateFormats } from "utils/formats";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { useAxiosRequest } from "use-axios-request";
import { IDictionary } from "api/interfaces/Dictionary";
import { filterOptionByLabel } from "utils/helpers";
import {IEntity} from "../../api/interfaces/Entity";

interface ActivityFormProps {
  api: IQueryEditable,
  record: IActivity,
  handleCreated?: (item: IActivity | IActivity[]) => void,
  handleUpdated?: (item: IActivity) => void,
}

class InnerForm extends PageForm<IActivity> {}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const activitySeasonalitiesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("activity_seasonalities"), params: { items: "all" } };
const activityCategoriesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("activity_categories"), params: { items: "all" } };
const activityFormatsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("activity_formats"), params: { items: "all" } };
const activityGoalsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("activity_goals"), params: { items: "all" } };
const activityTypesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("activity_types"), params: { items: "all" } };
const agesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("ages"), params: { items: "all" } };
const entitiesRequest = { ...axiosConfig, baseURL: "/", url: Routes.entitiesPath(), params: { items: "all" } };

const ActivityForm: React.FC<ActivityFormProps> = ({ api, record, handleCreated = () => void 0, handleUpdated = () => void 0 }) => {
  const [form] = Form.useForm();
  const { getFieldValue } = form;

  const { data: activitySeasonalitiesData, isFetching: isFetchingActivitySeasonalities } = useAxiosRequest<{ data: IDictionary[] }>(activitySeasonalitiesRequest);
  const { data: activitySeasonalities = [] } = activitySeasonalitiesData || {};

  const { data: activityCategoriesData, isFetching: isFetchingActivityCategories } = useAxiosRequest<{ data: IDictionary[] }>(activityCategoriesRequest);
  const { data: activityCategories = [] } = activityCategoriesData || {};

  const { data: activityFormatsData, isFetching: isFetchingActivityFormats } = useAxiosRequest<{ data: IDictionary[] }>(activityFormatsRequest);
  const { data: activityFormats = [] } = activityFormatsData || {};

  const { data: activityGoalsData, isFetching: isFetchingActivityGoals } = useAxiosRequest<{ data: IDictionary[] }>(activityGoalsRequest);
  const { data: activityGoals = [] } = activityGoalsData || {};

  const { data: activityTypesData, isFetching: isFetchingActivityTypes } = useAxiosRequest<{ data: IDictionary[] }>(activityTypesRequest);
  const { data: activityTypes = [] } = activityTypesData || {};

  const { data: agesData, isFetching: isFetchingAges } = useAxiosRequest<{ data: IDictionary[] }>(agesRequest);
  const { data: ages = [] } = agesData || {};

  const { data: entitiesData, isFetching: isFetchingentities } = useAxiosRequest<{ data: IEntity[] }>(entitiesRequest);
  const { data: entities = [] } = entitiesData || {};

  const [state, setState] = useState({
    groupAssistance: form.getFieldValue(["attributes", "group_assistance"]),
    individualAssistance: form.getFieldValue(["attributes", "individual_assistance"])
  })

  const onValuesChange = (changedValues, values) => {
    const attributes = changedValues["attributes"] || {};

    if (attributes["group_assistance"] !== undefined)
      setState({...state, groupAssistance: attributes["group_assistance"]})

    if (attributes["individual_assistance"] !== undefined)
      setState({...state, individualAssistance: attributes["individual_assistance"]})
  }

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleUpdated}
      onValuesChange={onValuesChange}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Collapse bordered={false} className="mb-20">
            <Collapse.Panel header="Activitat" key="1">
              <Row {...rowConfig}>
                <Col {...twoColumns}>
                  <Form.Item name={["attributes", "name"]} label="Nom" rules={rules.required}>
                    <Input type="text" disabled={readOnly} placeholder="Nom" />
                  </Form.Item>
                </Col>
                <Col {...sixColumns}>
                  <Form.Item name={["attributes", "start_date"]} label="Data d'inici">
                    <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
                  </Form.Item>
                </Col>
                <Col {...sixColumns}>
                  <Form.Item name={["attributes", "end_date"]} label="Data de fi">
                    <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
                  </Form.Item>
                </Col>
                <Col {...sixColumns}>
                  <Form.Item name={["attributes", "cancelled_at"]} label="Cancel·lada el dia">
                    <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "activity_seasonality_id"]} label="Temporalitat" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingActivitySeasonalities} filterOption={filterOptionByLabel} showSearch>
                      {activitySeasonalities.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "activity_category_id"]} label="Categoria" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingActivityCategories} filterOption={filterOptionByLabel} showSearch>
                      {activityCategories.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "activity_type_id"]} label="Tipus" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingActivityTypes} filterOption={filterOptionByLabel} showSearch>
                      {activityTypes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "activity_format_id"]} label="Format" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingActivityFormats} filterOption={filterOptionByLabel} showSearch>
                      {activityFormats.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...oneColumn}>
                  <Form.Item name={["attributes", "goal_ids"]} label="Objectius">
                    <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingActivityGoals} filterOption={filterOptionByLabel} showSearch>
                      {activityGoals.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Configuració" key="2">
              <Row {...rowConfig}>
                <Col {...threeColumns}>
                  <Form.Item name={["attributes", "age_ids"]} label="Edats">
                    <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingAges} filterOption={filterOptionByLabel} showSearch>
                      {ages.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...threeColumns}>
                  <Form.Item name={["attributes", "available_places"]} label="Places disponibles">
                    <InputNumber type="text" disabled={readOnly} placeholder="Número de places disponibles" />
                  </Form.Item>
                </Col>
                <Col {...threeColumns}>
                  <Form.Item name={["attributes", "organize_tracks"]} label="Organitza pistes">
                    <Radio.Group disabled={readOnly}>
                      <Radio value={false}>No</Radio>
                      <Radio value>Sí</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...twoColumns}>
                  <Form.Item name={["attributes", "organizer_ids"]} label="Organitzadors">
                    <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingentities} filterOption={filterOptionByLabel} showSearch>
                      {entities.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...twoColumns}>
                  <Form.Item name={["attributes", "collaborator_ids"]} label="Col·laboradors">
                    <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingentities} filterOption={filterOptionByLabel} showSearch>
                      {entities.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Assistència" key="3">
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "individual_assistance"]} label="Assistència individual">
                    <Radio.Group disabled={readOnly}>
                      <Radio value={false}>No</Radio>
                      <Radio value>Sí</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "group_assistance"]} label="Assitència grupal">
                    <Radio.Group disabled={readOnly}>
                      <Radio value={false}>No</Radio>
                      <Radio value>Sí</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {!getFieldValue(["attributes", "group_assistance"]) && !getFieldValue(["attributes", "individual_assistance"]) &&
                  <Col {...fourColumns}>
                    <Form.Item name={["attributes", "approximate_participants"]} label="Participants aproximats" rules={rules.required}>
                      <InputNumber type="text" disabled={readOnly} placeholder="Número de participants"/>
                    </Form.Item>
                  </Col>
                }
                {getFieldValue(["attributes", "individual_assistance"]) &&
                  <Col {...fourColumns}>
                    <Form.Item name={["attributes", "individual_places"]} label="Places individuals"
                               rules={rules.required}>
                      <InputNumber type="text" disabled={readOnly} placeholder="Número de places"/>
                    </Form.Item>
                  </Col>
                }
                {getFieldValue(["attributes", "group_assistance"]) &&
                  <Col {...fourColumns}>
                    <Form.Item name={["attributes", "team_places"]} label="Places d'equip" rules={rules.required}>
                      <InputNumber type="text" disabled={readOnly} placeholder="Número de places"/>
                    </Form.Item>
                  </Col>
                }
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Observacions" key="4">
              <Form.Item name={["attributes", "observations"]}>
                <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default ActivityForm;
