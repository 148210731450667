import Client, { IClientConfig } from './Client'
import * as errors from '../../../api/errors'
import Result from '../../../api/helpers/Result'
import Http from '../../../api/Http'

const makeClient = (config: IClientConfig = {}) => new Client(config)

const client = new Client({
  host: "/"
})

export default client;

export { Client, Http, Result, errors, makeClient }
