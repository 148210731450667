import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { ITeamObservationResult, ITeamObservationsResult, ITeamObservation } from '../../interfaces/team/Observation'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class TeamObservations extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly team_id: string;

  protected dateFields: string[] = ["starts_at", "ends_at"];

  constructor(team_id: string, host?: string) {
    super(host);
    this.team_id = team_id;
  }

  public async list(params: IQuery = {}): Promise<ITeamObservationsResult> {
    return await this.request(GET, Routes.teamObservationsPath(this.team_id), {}, params) as ITeamObservationsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<ITeamObservationResult> {
    return await this.request(GET, Routes.teamObservationPath(this.team_id, id), {}, params) as ITeamObservationResult
  }

  public async create(params: JsonApiDocument): Promise<ITeamObservationResult> {
    return await this.request(POST, Routes.teamObservationsPath(this.team_id), {}, params) as ITeamObservationResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ITeamObservationResult> {
    return await this.request(PATCH, Routes.teamObservationPath(this.team_id, id), {}, params) as ITeamObservationResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.teamObservationPath(this.team_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : ITeamObservation {
    return {
      id: null,
      type: "team_observations",
      attributes: {
        starts_at: moment.utc(),
        ends_at: moment.utc(),
        observation: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
