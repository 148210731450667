import React, {useMemo} from "react";
import {Button, Table, Alert} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {handleDestroy, renderRecordActions, renderRecordDate} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {ITeamComposition} from "api/interfaces/team/Composition";
import CompositionForm from "../forms/composition";

interface CompositionsListProps {
  teamId: string
}

const CompositionsList: React.FC<CompositionsListProps> = ({ teamId }) => {
  const apiEndpoint = React.useMemo(() => api.teamCompositions(teamId), [teamId]);

  const { tableProps, error, refresh, reload } = useInnerTable<ITeamComposition>(apiEndpoint);

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<ITeamComposition> => {
    return {
      title: "Composició",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<ITeamComposition>(apiEndpoint, formOptions);

  const destroyAction = useMemo(() => {
    return handleDestroy(apiEndpoint, refresh)
  }, [apiEndpoint, refresh]);

  const columns: ColumnType<ITeamComposition>[] = [
    { title: "Dia", key: "date", render: renderRecordDate("date") },
    { title: "Nois", dataIndex: ["attributes" , "guys"], key: "guys" },
    { title: "Noies", dataIndex: ["attributes" , "girls"], key: "girls" },
    { title: "Altres", dataIndex: ["attributes" , "others"], key: "others" },
    { title: "Edat predominant", dataIndex: ["attributes" , "age_name"], key: "to" },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, destroyAction)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["teams:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <CompositionForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default CompositionsList;
