import React, {useMemo} from "react";
import {Button, Table, Alert} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions, renderRecordDate, renderObservations, handleDestroy, renderRecordTime} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {IIncidenceAction} from "api/interfaces/incidence/Action";
import ActionForm from "../forms/action";

interface DatesListProps {
  incidenceId: string
}

const DatesList: React.FC<DatesListProps> = ({ incidenceId }) => {
  const apiEndpoint = React.useMemo(() => api.incidenceActions(incidenceId), [incidenceId]);

  const { tableProps, error, refresh, reload } = useInnerTable<IIncidenceAction>(apiEndpoint);

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IIncidenceAction> => {
    return {
      title: "Acción d'incidència",
      handleCreated: reload,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh, reload]);

  const { create, edit, drawerProps } = useDrawerForm<IIncidenceAction>(apiEndpoint, formOptions);


  const destroyAction = useMemo(() => {
    return handleDestroy(apiEndpoint, reload)
  }, [apiEndpoint, reload]);

  const columns: ColumnType<IIncidenceAction>[] = [
    { title: "Dia", key: "day", render: renderRecordDate("day") },
    { title: "Hora", key: "hour", render: renderRecordTime("hour") },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, destroyAction)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["activities:create"]}>
          <Button icon={<PlusOutlined />} onClick={create} style={{marginRight: "5px"}}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <ActionForm form={form} readOnly={readOnly}/>
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default DatesList;
