import React, { useEffect, useState } from "react"
import { Form, Row, Col, Input, Select } from "antd"
import { FormInstance } from "antd/lib/form";
import { rowConfig, twoColumns } from "utils/constants";
import { useAxiosRequest } from "use-axios-request";
import { axiosConfig } from "utils/request";
import {filterOptionByLabel} from "../../utils/helpers";

import { SliderPicker } from "react-color";

export interface UserFormProps {
  form: FormInstance;
  profile?: boolean;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
  dni: [ { required: true, message: "Aquest camp és requerit" } ],
  role: [ { required: true, message: "Aquest camp és requerit" } ],
  first_name: [ { required: true, message: "Aquest camp és requerit" } ],
  email: [ { required: true, message: "Aquest camp és requerit" } ],
}

const rolesRequest = { ...axiosConfig, url: "users/roles", params: { items: "all" } };

interface ColorPickerInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

const ColorPickerInput: React.FC<ColorPickerInputProps> = ({ value, onChange }) => {
  console.log(value);
  const [color, setColor] = useState(value);

  useEffect(() => {
    setColor(value);
}, [value])

  const handleOnChange = color => {
    setColor(color.hex);

    if (onChange) {
      onChange(color.hex);
    }
  };

  console.log(color)
  return (
    <SliderPicker color={ color } onChange={ handleOnChange } />
  );
};

const UserForm: React.FC<UserFormProps> = ({ profile = false, readOnly = false }) => {
  const { data, isFetching } = useAxiosRequest<{ items: SelectOption[] }>(rolesRequest)
  const { items: roles = [] } = data || {};

  return (
    <>
      { !profile &&
        <Row {...rowConfig}>
          <Col {...twoColumns}>
            <Form.Item name={["attributes", "dni"]} label="DNI" rules={rules.dni}>
              <Input disabled={readOnly} placeholder="12345678P" />
            </Form.Item>
          </Col>
          <Col {...twoColumns}>
            <Form.Item name={["attributes", "role"]} label="Rol" rules={rules.role}>
              <Select disabled={readOnly} placeholder="Rol" loading={isFetching} filterOption={filterOptionByLabel} showSearch>
                {roles.map((item) => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      }
      <Form.Item name={["attributes", "first_name"]} label="Nom" rules={rules.first_name}>
        <Input disabled={readOnly} placeholder="Nom" />
      </Form.Item>
      <Form.Item name={["attributes", "last_name"]} label="Cognoms">
        <Input disabled={readOnly} placeholder="Cognoms" />
      </Form.Item>
      <Form.Item name={["attributes", "email"]} label="Correu electrònic" rules={rules.email}>
        <Input disabled={readOnly} placeholder="user@example.com" />
      </Form.Item>
      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "password"]} label="Contraseña">
            <Input.Password disabled={readOnly} placeholder="********" />
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "password_confirmation"]} label="Repetir contraseña">
            <Input.Password disabled={readOnly} placeholder="********" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={["attributes", "avatar_color"]} label="Color de l'usuari" rules={rules.required}>
        <ColorPickerInput />
      </Form.Item>
    </>
  )
}

export default UserForm;
