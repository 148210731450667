import request from 'utils/request';

export interface LoginParamsType {
  dni: string;
  password: string;
  remember_me: boolean;
}

export async function login(params: LoginParamsType) {
  return request('auth/login', {
    method: 'POST',
    data: { user: params },
  });
}

export async function logout() {
  return request('auth/logout', {
    method: 'POST'
  });
}

export async function whoami() {
  return request('users/whoami');
}

export async function permissions() {
  return request('users/permissions');
}
