import React from "react"
import { Form, Input, Select } from "antd"
import { FormInstance } from "antd/lib/form";
import { IDictionary } from "api/interfaces/Dictionary";
import { axiosConfig } from "utils/request";
import { useAxiosRequest } from "use-axios-request";
import {filterOptionByLabel} from "utils/helpers"
import { Routes } from "api/routes";

export interface DictionaryFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  name: [ { required: true, message: "Aquest camp és requerit" } ],
  activity_goal_type_id: [ { required: true, message: "Aquest camp és requerit" } ],
}

const goalTypesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("activity_goal_types"), params: { items: "all" } };

const ActivityGoalForm: React.FC<DictionaryFormProps> = ({ readOnly = false }) => {
  const { data: response, isFetching: isFetchingGoalTypes } = useAxiosRequest<{ data: IDictionary[] }>(goalTypesRequest);
  const { data: goalTypes = [] } = response || {};

  return (
    <>
      <Form.Item name={["attributes", "name"]} label="Nom" rules={rules.name}>
        <Input disabled={readOnly} placeholder="Nom" />
      </Form.Item>
      <Form.Item name={["attributes", "activity_goal_type_id"]} label="Grup" rules={rules.activity_goal_type_id}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingGoalTypes} filterOption={filterOptionByLabel} showSearch>
          {goalTypes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
    </>
  )
}

export default ActivityGoalForm;
