export const CANCEL_REQUEST_MESSAGE = 'cancel request'

export const weekdays: SelectOption[] = [
  { value: "1", label: "Dilluns" },
  { value: "2", label: "Dimarts" },
  { value: "3", label: "Dimecres" },
  { value: "4", label: "Dijous" },
  { value: "5", label: "Divendres" },
  { value: "6", label: "Dissabte" },
  { value: "0", label: "Diumenge" }
];

export const repeatPeriod: SelectOption[] = [
  { value: "week",   label: "Setmanal" },
  { value: "biweek", label: "Quinzenal" }
];

export const rowConfig = { gutter: 16 };
export const oneColumn = { xs: 24 }
export const twoColumns   = { xs: 24, lg: 12, xl: 12 };
export const threeColumns = { xs: 12, lg: 8 };
export const fourColumns  = { xs: 12, lg: 6 };
export const sixColumns  = { xs: 12, lg: 4 };
export const eightColumns  = { xs: 12, lg: 3 };

export const codeMessage : {[key: number]: string} = {
  200: "El servidor ha retornat les dades sol·licitades amb èxit.",
  201: "S'han modificat les dades correctament",
  202: "La sol·licitud s'ha afegit a una cua en segon pla (tasca asíncrona).",
  204: "S'han suprimit les dades amb èxit.",
  400: "La sol·licitud enviada té un error i el servidor no ha creat ni modificat dades.",
  401: "L'usuari no té permís (token, nom d'usuari, contrasenya incorrectes).",
  403: "L'usuari té l'accés prohibit.",
  404: "La sol·licitud es va fer per un registre que no existeix i el servidor no va fer res.",
  406: "El format sol·licitat no està disponible.",
  410: "El recurs sol·licitat es va suprimir definitivament i ja no estarà disponible.",
  422: "En crear un objecte, es va produir un error de validació.",
  500: "S'ha produït un error en el servidor. Comproveu el servidor.",
  502: "Error de la passarel·la",
  503: "El servei no està disponible temporalment.",
  504: "La passarel·la ha esgotat el temps d'espera",
};

export const optionsPlaceholders: SelectOption[] = [
  { value: "option-1", label: "Opció 1" },
  { value: "option-2", label: "Opció 2" },
  { value: "option-2", label: "Opció 3" }
];
