import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import {IIncidenceResult, IIncidencesResult, INewIncidence} from '../interfaces/Incidence'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";

export default class Incidences extends Http implements IQueryList, IQueryEditable {
  protected dateFields: string[] = ["start_date", "end_date"];
  protected dateTimeFields: string[] = ["starts_at", "ends_at"];

  public async list(params: IQuery = {}): Promise<IIncidencesResult> {
    return await this.request(GET, Routes.incidencesPath(), {}, params) as IIncidencesResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IIncidenceResult> {
    return await this.request(GET, Routes.incidencePath(id), {}, params) as IIncidenceResult
  }

  public async create(params: JsonApiDocument): Promise<IIncidenceResult> {
    return await this.request(POST, Routes.incidencesPath(), {}, params) as IIncidenceResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IIncidenceResult> {
    return await this.request(PATCH, Routes.incidencePath(id), {}, params) as IIncidenceResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.incidencePath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : INewIncidence {
    return {
      id: null,
      type: "incidences",
      attributes: {
        start_date: null,
        starts_at: null,
        end_date: null,
        ends_at: null,
        user_id: null,
        incidence_severity_level_id: null,
        incidence_closing_reason_id: null,
        observations: "",
        incidence_type_ids: [],
        space_ids: [],
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
