import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { ICessionEntityResult, ICessionEntitiesResult, ICessionEntity } from '../../interfaces/cession/Entity'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";

export default class CessionEntities extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly cession_id: string;

  constructor(cession_id: string, host?: string) {
    super(host);
    this.cession_id = cession_id;
  }

  public async list(params: IQuery = {}): Promise<ICessionEntitiesResult> {
    return await this.request(GET, Routes.cessionEntitiesPath(this.cession_id), {}, params) as ICessionEntitiesResult
  }

  public async show(id: string, params: IQuery = {}): Promise<ICessionEntityResult> {
    return await this.request(GET, Routes.cessionEntityPath(this.cession_id, id), {}, params) as ICessionEntityResult
  }

  public async create(params: JsonApiDocument): Promise<ICessionEntityResult> {
    return await this.request(POST, Routes.cessionEntitiesPath(this.cession_id), {}, params) as ICessionEntityResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ICessionEntityResult> {
    return await this.request(PATCH, Routes.cessionEntityPath(this.cession_id, id), {}, params) as ICessionEntityResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.cessionEntityPath(this.cession_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : ICessionEntity {
    return {
      id: null,
      type: "cessions_entities",
      attributes: {
        entity_id: null,
        entity_name: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
