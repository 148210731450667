import React from 'react';
import { Select, Form } from "antd"

const RepeatMonthlyOn = ({
  mode,
  on,
  hasMoreModes,
  handleChange,
}) => {
  const isActive = mode === 'on';

  return (
    <div className={`form-group row d-flex align-items-sm-center ${!isActive && 'opacity-50'}`}>
      {/* <div className="col-sm-1 offset-sm-2">
        {hasMoreModes && (
          <input
            type="radio"
            name="repeat.monthly.mode"
            aria-label="Repeat monthly on"
            value="on"
            checked={isActive}
            onChange={handleChange}
          />
        )}
      </div>
      <div className="col-sm-1">
        El dia
      </div> */}

      <Form.Item label="El dia">
        <Select
          name="repeat.monthly.on.day"
          aria-label="Repeat monthly on a day"
          className="form-control"
          value={on.day}
          disabled={!isActive}
          onChange={(value) => handleChange("repeat.monthly.on.day", value)}
        >
          {[...new Array(31)].map((day, i) => <Select.Option key={i} value={i + 1}>{i + 1}</Select.Option>)}
        </Select>
      </Form.Item>
    </div>
  );
};
// RepeatMonthlyOn.propTypes = {
//   id: PropTypes.string.isRequired,
//   mode: PropTypes.oneOf(['on', 'on the']).isRequired,
//   on: PropTypes.shape({
//     day: PropTypes.number.isRequired,
//   }).isRequired,
//   hasMoreModes: PropTypes.bool.isRequired,
//   handleChange: PropTypes.func.isRequired,
//   translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default RepeatMonthlyOn;
