import { GET, DELETE, PATCH, POST } from '../../../../api/constants'
import Http from '../../../../api/Http'
import { IQuery  as IRequestParams, IQueryList, IQueryEditable } from '../../../../api/interfaces/Query'
import { ICategoryResult, ICategoriesResult, ICategory } from '../interfaces/Category'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from '../../../../api/interfaces/JsonApi'
import {ResultResponse} from "../../../../api/interfaces/ResultResponse";

export default class Categories extends Http implements IQueryList, IQueryEditable {
  public async list(params: IRequestParams = {}): Promise<ICategoriesResult> {
    return await this.request(GET, Routes.categoriesPath(), {}, params) as ICategoriesResult
  }

  public async show(id: string, params: IRequestParams = {}): Promise<ICategoryResult> {
    return await this.request(GET, Routes.categoryPath(id), {}, params) as ICategoryResult
  }

  public async create(params: JsonApiDocument): Promise<ICategoryResult> {
    return await this.request(POST, Routes.categoriesPath(), {}, params) as ICategoryResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ICategoryResult> {
    return await this.request(PATCH, Routes.categoryPath(id), {}, params) as ICategoryResult
  }

  public async destroy(id: string, params: IRequestParams = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.categoryPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : ICategory {
    return {
      id: null,
      type: "categories",
      attributes: {
        category_ids: [],
        category_names: "",
        description: "",
        fields: "",
        name: "",
        sql: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
