import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IIndividualPresenceResult, IIndividualPresencesResult, IIndividualPresence } from '../../interfaces/individual/Presence'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class IndividualPresences extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly individual_id: string;

  protected dateFields: string[] = ["day"];
  protected dateTimeFields: string[] = ["from", "to"];

  constructor(individual_id: string, host?: string) {
    super(host);
    this.individual_id = individual_id;
  }

  public async list(params: IQuery = {}): Promise<IIndividualPresencesResult> {
    return await this.request(GET, Routes.individualPresencesPath(this.individual_id), {}, params) as IIndividualPresencesResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IIndividualPresenceResult> {
    return await this.request(GET, Routes.individualPresencePath(this.individual_id, id), {}, params) as IIndividualPresenceResult
  }

  public async create(params: JsonApiDocument): Promise<IIndividualPresenceResult> {
    return await this.request(POST, Routes.individualPresencesPath(this.individual_id), {}, params) as IIndividualPresenceResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IIndividualPresenceResult> {
    return await this.request(PATCH, Routes.individualPresencePath(this.individual_id, id), {}, params) as IIndividualPresenceResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.individualPresencePath(this.individual_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IIndividualPresence {
    return {
      id: null,
      type: "individual_presences",
      attributes: {
        day: moment.utc(),
        observations: "",
        from: moment.utc(),
        to: moment.utc(),
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
