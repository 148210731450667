import Activities from './endpoints/Activities'
import ActivityAttendances from "./endpoints/activity/Attendances";
import ActivitySessions from "./endpoints/activity/Sessions";
import ActivityRegistrations from "./endpoints/activity/Registrations";
import ApplicationMessages from './endpoints/ApplicationMessages'
import Attachments from './endpoints/Attachments'
import AttachmentCollections from './endpoints/AttachmentCollections'
import CalendarEvent from "./endpoints/calendar/Event";
import Cessions from "./endpoints/Cessions";
import CessionDates from "./endpoints/cession/Date";
import CessionEntities from "./endpoints/cession/Entity";
import CessionIndividuals from "./endpoints/cession/Individual";
import CessionTeams from "./endpoints/cession/Team";
import Dictionaries from "./endpoints/Dictionaries"
import Entities from './endpoints/Entities'
import Incidences from "./endpoints/Incidences";
import IncidenceActions from "./endpoints/incidence/Action";
import IncidenceParticipants from "./endpoints/incidence/Participant";
import Individuals from './endpoints/Individual';
import IndividualPresences from "./endpoints/individual/Presences";
import IndividualDemandsNeeds from "./endpoints/individual/DemandsNeeds";
import IndividualDemandsNeedsAnswers from "./endpoints/individual/DemandsNeedsAnswers";
import IndividualRegistrations from "./endpoints/individual/Registrations";
import IndividualTeams from "./endpoints/individual/Teams";
import SpaceStats from './endpoints/SpaceStats'
import Talleristes from './endpoints/Talleristes'
import Teams from './endpoints/Teams'
import TeamCompositions from "./endpoints/team/Compositions";
import TeamIndividuals from "./endpoints/team/Individuals";
import TeamMisbehaviours from "./endpoints/team/Misbehaviours";
import TeamObservations from "./endpoints/team/Observations";
import TeamPresences from "./endpoints/team/Presences";
import TeamRegistrations from "./endpoints/team/Registrations";
import Users from './endpoints/Users'

export interface IClientConfig {
  host?: string
}

class Client {
  private readonly host?: string

  public activities: Activities
  public activityAttendances: (activityId: string, sessionId: string) => ActivityAttendances
  public activitySessions: (activityId: string) => ActivitySessions
  public activityRegistrations: (activityId: string) => ActivityRegistrations
  public applicationMessages: ApplicationMessages
  public attachments: (path: string, vaultId: string|number) => Attachments
  public attachmentCollections: (path: string, vaultId: string|number) => AttachmentCollections
  public calendarTasks:CalendarEvent
  public cessions: Cessions
  public cessionDates: (cessionId: string) => CessionDates
  public cessionEntities: (cessionId: string) => CessionEntities
  public cessionIndividuals: (cessionId: string) => CessionIndividuals
  public cessionTeams: (cessionId: string) => CessionTeams
  public dictionaries: (path: string) => Dictionaries
  public entities: Entities
  public incidences: Incidences
  public incidenceActions: (incidenceId: string) => IncidenceActions
  public incidenceParticipants: (incidenceId: string) => IncidenceParticipants
  public individuals: Individuals
  public individualPresences: (individualId: string) => IndividualPresences
  public individualDemandsNeeds: (individualId: string) => IndividualDemandsNeeds
  public individualDemandsNeedsAnswers: (individualId: string) => IndividualDemandsNeedsAnswers
  public individualRegistrations: (individualId: string) => IndividualRegistrations
  public individualTeams: (individualId: string) => IndividualTeams
  public spaceStats: SpaceStats
  public talleristes: Talleristes
  public teams: Teams
  public teamCompositions: (teamId: string) => TeamCompositions
  public teamMisbehaviours: (teamId: string) => TeamMisbehaviours
  public teamObservations: (teamId: string) => TeamObservations
  public teamPresences: (teamId: string) => TeamPresences
  public teamRegistrations: (teamId: string) => TeamRegistrations
  public teamIndividuals: (teamId: string) => TeamIndividuals
  public users: Users

  constructor(config: IClientConfig = {}) {
    this.host = config.host || null
    this.addEndpoints()
  }

  private addEndpoints() {
    this.activities = new Activities(this.host)
    this.activitySessions = (activityId: string) => new ActivitySessions(activityId, this.host)
    this.activityAttendances = (activityId: string, sessionId: string) => new ActivityAttendances(activityId, sessionId, this.host)
    this.activityRegistrations = (activityId: string) => new ActivityRegistrations(activityId, this.host)
    this.applicationMessages = new ApplicationMessages(this.host)
    this.attachments = (path: string, vaultId: string|number) => new Attachments(path, vaultId, this.host)
    this.attachmentCollections = (path: string, vaultId: string|number) => new AttachmentCollections(path, vaultId, this.host)
    this.calendarTasks = new CalendarEvent(this.host)
    this.cessions = new Cessions(this.host)
    this.cessionDates = (cessionId: string) => new CessionDates(cessionId, this.host)
    this.cessionEntities = (cessionId: string) => new CessionEntities(cessionId, this.host)
    this.cessionIndividuals = (cessionId: string) => new CessionIndividuals(cessionId, this.host)
    this.cessionTeams = (cessionId: string) => new CessionTeams(cessionId, this.host)
    this.dictionaries = (path: string) => new Dictionaries(path, this.host)
    this.entities = new Entities(this.host)
    this.incidences = new Incidences(this.host)
    this.incidenceActions = (incidenceId: string) => new IncidenceActions(incidenceId, this.host)
    this.incidenceParticipants = (incidenceId: string) => new IncidenceParticipants(incidenceId, this.host)
    this.individuals = new Individuals(this.host)
    this.individualPresences = (individualId: string) => new IndividualPresences(individualId, this.host)
    this.individualDemandsNeeds = (individualId: string) => new IndividualDemandsNeeds(individualId, this.host)
    this.individualDemandsNeedsAnswers = (individualId: string) => new IndividualDemandsNeedsAnswers(individualId, this.host)
    this.individualRegistrations = (individualId: string) => new IndividualRegistrations(individualId, this.host)
    this.individualTeams = (individualId: string) => new IndividualTeams(individualId, this.host)
    this.spaceStats = new SpaceStats(this.host)
    this.talleristes = new Talleristes(this.host)
    this.teams = new Teams(this.host)
    this.teamCompositions = (teamId: string) => new TeamCompositions(teamId, this.host)
    this.teamMisbehaviours = (teamId: string) => new TeamMisbehaviours(teamId, this.host)
    this.teamObservations = (teamId: string) => new TeamObservations(teamId, this.host)
    this.teamPresences = (teamId: string) => new TeamPresences(teamId, this.host)
    this.teamRegistrations = (teamId: string) => new TeamRegistrations(teamId, this.host)
    this.teamIndividuals = (teamId: string) => new TeamIndividuals(teamId, this.host)
    this.users = new Users(this.host)
  }
}

export default Client
