import React from 'react';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';

const RepeatDaily = ({
  daily: {
    interval,
  },
  handleChange,
}) => (
  <div className="form-group row d-flex align-items-sm-center">
    <div className="col-sm-1 offset-sm-2">
      Cada
    </div>
    <div className="col-sm-2">
      <input
        name="repeat.daily.interval"
        aria-label="Repeat daily interval"
        className="form-control"
        value={interval}
        onChange={numericalFieldHandler(handleChange)}
      />
    </div>
    <div className="col-sm-1">
      Dies
    </div>

  </div>
);
// RepeatDaily.propTypes = {
//   id: PropTypes.string.isRequired,
//   daily: PropTypes.shape({
//     interval: PropTypes.number.isRequired,
//   }).isRequired,
//   handleChange: PropTypes.func.isRequired,
//   translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default RepeatDaily;
