import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { PermissibleRender } from '@brainhubeu/react-permissible';

import SecurityLayout from "layouts/SecurityLayout";
import ApplicationLayout from "layouts/ApplicationLayout";
import { useStoreState } from "utils/store";
import Unauthorized from "components/Unauthorized";

export interface AuthorizedRouteProps {
  permissions: string[]
}

const AuthorizedRoute: React.FC<AuthorizedRouteProps & RouteProps> = ({ children, permissions, ...rest }) => {
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  return (
    <Route {...rest}>
      <SecurityLayout>
        <ApplicationLayout>
          <PermissibleRender userPermissions={userPermissions} requiredPermissions={permissions} renderOtherwise={<Unauthorized />}>
            { children }
          </PermissibleRender>
        </ApplicationLayout>
      </SecurityLayout>
    </Route>
  );
}

export default AuthorizedRoute;
