import React, { useState } from 'react';
import moment from 'moment';
import { range } from 'lodash';

import { Select, Form, Row, Col } from "antd"
import { rowConfig, twoColumns } from 'utils/constants';


const RepeatYearlyOn = ({
  mode,
  on,
  hasMoreModes,
  handleChange,
}) => {
  const isActive = mode === 'on';

  const [daysInMonth, setDaysInMonth] = useState(moment(on.month, 'M').daysInMonth());

  const onChangeMonth = (month) => {
    setDaysInMonth(moment(month, 'M').daysInMonth());
    handleChange("repeat.yearly.on.month", month)
  }

  return (
    <div className={`form-group row d-flex align-items-sm-center ${!isActive && 'opacity-50'}`}>
      {/* <div className="col-sm-1 offset-sm-2">

        {hasMoreModes && (
          <input
            type="radio"
            name="repeat.yearly.mode"
            aria-label="Repeat yearly on"
            value="on"
            checked={isActive}
            onChange={handleChange}
          />
        )}
      </div> */}

      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item label="Al mes">
            <Select
              name="repeat.yearly.on.month"
              aria-label="Repeat yearly on month"
              className="form-control"
              disabled={!isActive}
              value={on.month}
              onChange={onChangeMonth}
            >
              {moment.months().map((month, index) => <Select.Option key={index + 1} value={index + 1}>{month}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>

        <Col {...twoColumns}>
          <Form.Item label="El dia">
            <Select
              name="repeat.yearly.on.day"
              aria-label="Repeat yearly on a day"
              value={on.day}
              disabled={!isActive}
              onChange={(value) => handleChange("repeat.yearly.on.day", value)}
            >
              {range(0, daysInMonth).map((i) => (
                <Select.Option key={i} value={i + 1}>{i + 1}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default RepeatYearlyOn;
