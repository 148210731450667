import React, {useEffect, useReducer} from "react";
import NotFound from "components/NotFound";
import IncidenceForm from "./form";
import { PageHeaderWrapper, PageLoading } from "@ant-design/pro-layout";
import { Tab } from "rc-tabs/lib/interface";
import {useQuery} from "hooks/useRouter";
import {Tabs} from "antd";
import api from "../../api";
import {IIncidence} from "api/interfaces/Incidence";
import LoadingError from "../../components/LoadingError";
import {JsonApiDocument} from "api/interfaces/JsonApi";
import ActionsList from "./sections/action";
import ParticipantsList from "./sections/participants";

export interface IState {
  item?: IIncidence
  included: JsonApiDocument["included"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: null,
  included: [],
  isFetching: false,
  error: null
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const IncidenceEditPage: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { incidenceId } = useQuery();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.incidences.show(incidenceId);

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data,
            included: result.success().included,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            included: [],
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    if (incidenceId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true
        }
      });

      fetchData();
    }
  }, [incidenceId, dispatch]);

  if (!incidenceId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }
  if (!state.item) { return <LoadingError /> }

  const tabs: Tab[] = [
    { key: "1", label: "Accions", children: <ActionsList incidenceId={incidenceId} /> },
    { key: "2", label: "Involucrats", children: <ParticipantsList incidenceId={incidenceId} /> },
  ];

  return (
    <PageHeaderWrapper title="Editar cessió">
      <IncidenceForm api={api.incidences} record={state.item} />
      <Tabs destroyInactiveTabPane defaultActiveKey="1" items={tabs} animated={false} className="mb-20" />
    </PageHeaderWrapper>
  );
};

export default IncidenceEditPage;
