import React from "react";

import { Button, Form, message, Drawer, Spin } from "antd";
import { FormInstance, FormProps } from "antd/lib/form";
import { isFunction } from "lodash";
import { emit as busDispatchEvent } from 'react-gbus';
import { FieldData } from "rc-field-form/lib/interface";
import { IQueryEditable } from "api/interfaces/Query";
import { JsonApiDocument, JsonApiSingleResponse } from "api/interfaces/JsonApi";
import { ExpandedApiError } from "api/errors";
import { ResultResponse } from "api/interfaces/ResultResponse";

export const BEFORE_CREATE = 'beforeCreate';
export const BEFORE_UPDATE = 'beforeUpdate';
export const FIELDS_CHANGED = 'fieldsChanged';
export const FORM_SHOWN = 'shown';

export interface DrawerFormProps<T extends JsonApiDocument> {
  title: string,
  api: IQueryEditable,
  handleCreated: (item: T | T[]) => void,
  handleUpdated: (item: T) => void,
  form: FormInstance
  initialValues: T,
  alwaysCreateNewRecord?: boolean,
  /**
   * If you pass and event prefix this will emmit some events
   */
  eventBusPrefix?: string,
  /**
   * React children or child render callback
  */
  children?:
    | ((props: any) => React.ReactNode)
    | React.ReactNode;
}

export interface DrawerFormState<T extends JsonApiDocument> {
  multiple: boolean,
  visible: boolean,
  loading: boolean,
  record?: T
}

export interface DrawerFormChildProps {
  form: FormInstance,
  record: JsonApiDocument,
  readOnly: boolean,
  eventBusPrefix: string
}

class DrawerForm<T extends JsonApiDocument, P extends DrawerFormProps<T> = DrawerFormProps<T>> extends React.Component<P, DrawerFormState<T>> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Readonly<P>) {
    super(props);

    this.state = {
      multiple: false,
      visible: false,
      loading: false,
      record: props.initialValues
    };
  }

  emitEvent = (name, payload) : void => {
    const { eventBusPrefix } = this.props;

    if (eventBusPrefix)
      busDispatchEvent(`@@form/${eventBusPrefix}/${name}`, payload);
  };

  handleCancel = () : void => {
    this.setState({ loading: false, visible: false });

    this.formRef.current?.resetFields();
  };

  onFinishFailed: FormProps["onFinishFailed"] = ({ values, errorFields, outOfDate }) => {
    // console.log(values, errorFields);
  }

  onFinish = async (formValues: {[key: string]: any}) : Promise<void> =>  {
    const { api, alwaysCreateNewRecord } = this.props;
    const { record } = this.state;

    this.setState({ loading: true });

    const values = { ...record, ...formValues }
    let response;

    if (record.id && !alwaysCreateNewRecord) {
      this.emitEvent(BEFORE_UPDATE, { values: values });
      response = await api.update(record.id, values)
    } else {
      this.emitEvent(BEFORE_CREATE, { values: values });
      response = await api.create(values)
    }

    if (response.isSuccess())
      this.handleSubmitSuccess(response)
    else
      this.handleSubmitFailure(formValues, response);
  };

  handleSubmitSuccess = (response: ResultResponse<JsonApiSingleResponse>) : void => {
    const { record } = this.state;
    const { handleCreated, handleUpdated } = this.props;

    const { data: item } = response.success();

    if (record?.id)
      handleUpdated(item as T);
    else
      handleCreated(item as T);

    this.handleCancel();
    message.success("Registre guardat correctament");
  };

  handleSubmitFailure = (values: any, response: ResultResponse<JsonApiSingleResponse>) : void => {
    this.setState({ loading: false });

    if (response.fail().name === "ExpandedApiError") {
      const { summary, errors } = response.fail() as ExpandedApiError;

      const fields : FieldData[] = [];

      Object.keys(errors).forEach((field: string) => {
        fields.push({
          name: ["attributes", field],
          value: values.attributes ? values.attributes[field] : "",
          errors: errors[field]
        });
      });

      if (fields.length > 0) {
        this.formRef.current?.setFields(fields);
      } else if (summary) {
        // try to show a general error
        message.error(summary, 10);
      }
    } else {
      message.error("Hi hagut un error desconegut", 10);
    }
  };

  show = (values: T | null = null) : void => {
    if (values) {
      this.formRef.current?.setFieldsValue(values);
      this.emitEvent(FORM_SHOWN, { values: values });
      this.emitEvent(FIELDS_CHANGED, { prevValues: this.state.record, currValues: values });
    } else {
      this.formRef.current?.resetFields();
      this.emitEvent(FORM_SHOWN, { values: this.props.initialValues });
      this.emitEvent(FIELDS_CHANGED, { prevValues: this.state.record, currValues: this.props.initialValues });
    }

    this.setState({ loading: false, visible: true, record: (values ? {...values} : {...this.props.initialValues}) });
  };

  submitForm = () => {
    this.formRef.current?.submit();
  }

  render() : JSX.Element {
    const { eventBusPrefix, title, children } = this.props;
    const { loading, record, visible } = this.state;
    const readOnly = !record?.meta?.permissions.can_edit;

    const footer = (
      // this.state.loading ? null :
      <div style={{textAlign: 'right' }}>
        <Button onClick={this.handleCancel} style={{ marginRight: 8 }}>
          Cancel·lar
        </Button>
        { record?.meta?.permissions.can_edit &&
          <Button onClick={this.submitForm} type="primary" loading={loading}>
            {record?.id ? "Guardar" : "Crear"}
          </Button>
        }
      </div>
    )

    return (
      <Drawer
        title={title}
        width={450}
        placement="right"
        closable
        destroyOnClose
        onClose={this.handleCancel}
        // onOk={this.handleOk}
        // confirmLoading={loading}
        open={visible}
        footer={footer}
      >
        <Spin spinning={loading}>
          <Form form={this.props.form} ref={this.formRef} initialValues={this.props.initialValues}  onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} layout="vertical">
            { children && isFunction(children)
              ? (children as (props: DrawerFormChildProps) => React.ReactNode)({
                  form: this.props.form,
                  record,
                  eventBusPrefix,
                  readOnly
                })
              : null
            }
          </Form>
        </Spin>
      </Drawer>
    );
  };
}

export default DrawerForm;

type DrawerFormWithForwardRefProps<T extends JsonApiDocument> = {
  // ref is not a valid prop name and treated
  // differently by React, so we use forwardedRef
  forwardedRef: React.Ref<DrawerForm<T>>;
} & DrawerFormProps<T>;

export const DrawerFormWithForwardRef = <T extends JsonApiDocument>({
  forwardedRef,
  ...rest
}: DrawerFormWithForwardRefProps<T>) => (
  <DrawerForm {...rest} ref={forwardedRef} />
);
