import React from "react"
import {Col, DatePicker, Form, Input, Row, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "../../../utils/formats";
import {oneColumn, rowConfig, twoColumns} from "../../../utils/constants";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { useAxiosRequest } from "use-axios-request";
import { IDictionary } from "api/interfaces/Dictionary";
import { filterOptionByLabel } from "utils/helpers";


export interface PresenceFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const cancelReasonsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("cancel_reasons"), params: { items: "all" } };

const RegistrationForm: React.FC<PresenceFormProps> = ({ form, readOnly = false }) => {
  const { data: cancelReasonsData, isFetching: isFetchingCancelReasons } = useAxiosRequest<{ data: IDictionary[] }>(cancelReasonsRequest);
  const { data: cancelReasons = [] } = cancelReasonsData || {};

  return (
    <>
      <h3 className="text-center">{ form.getFieldValue(["attributes", "who"]) }</h3>
      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "requested_at"]} label="Rebuda en data">
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "accepted_at"]} label="Acceptada en data">
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "cancelled_at"]} label="Cancel·lada en data">
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "cancel_reason_id"]} label="Motiu de la baixa">
            <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingCancelReasons} filterOption={filterOptionByLabel} showSearch>
              {cancelReasons.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...oneColumn}>
          <Form.Item name={["attributes", "observations"]} label="Observacions">
            <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
};

export default RegistrationForm;
