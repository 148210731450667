import React  from "react"
import {DatePicker, Form, Input, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "../../../utils/formats";
import {axiosConfig} from "../../../utils/request";
import {useAxiosRequest} from "use-axios-request";
import {filterOptionByLabel} from "utils/helpers"
import { Routes } from "api/routes";
import { IDictionary } from "api/interfaces/Dictionary";


export interface DemandNeedFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const kinds: { value: string, label: string }[] = [
  { value: "demand", label: "Demanda" },
  { value: "need", label: "Necessitat" },
];

const demandNeedTypesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("demand_need_types"), params: { items: "all" } };
const activityTypesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("activity_types"), params: { items: "all" } };

const DemandNeedForm: React.FC<DemandNeedFormProps> = ({ readOnly = false }) => {
  const { data: demandNeedTypesData, isFetching: isFetchingDemandNeedTypes } = useAxiosRequest<{ data: IDictionary[] }>(demandNeedTypesRequest);
  const { data: demandNeedTypes = [] } = demandNeedTypesData || {};

  const { data: activityTypesData, isFetching: isFetchingActivityTypes } = useAxiosRequest<{ data: IDictionary[] }>(activityTypesRequest);
  const { data: activityTypes = [] } = activityTypesData || {};

  return (
    <>
      <Form.Item name={["attributes", "date"]} label="Dia" rules={rules.required}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "kind"]} label="Tipus" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" filterOption={filterOptionByLabel} showSearch>
          {kinds.map((item) => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "demand_need_type_id"]} label="Tipus demanda / necessitat" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingDemandNeedTypes} filterOption={filterOptionByLabel} showSearch>
          {demandNeedTypes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "activity_type_id"]} label="Tipus d'activitat" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingActivityTypes} filterOption={filterOptionByLabel} showSearch>
          {activityTypes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "observations"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default DemandNeedForm;
