import React from "react";

import { Form, Table, Alert, Button, Input } from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { PageHeaderWrapper } from "@ant-design/pro-layout";

import { useQuery } from "hooks/useRouter";
import { renderRecordActions, renderObservations } from "utils/helpers";
import { useTable } from "hooks/useTableApi";
import { FormInstance } from "antd/lib/form";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerFormApi";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined } from "@ant-design/icons";
import { useStoreState } from "utils/store";
import { DrawerFormChildProps, DrawerFormWithForwardRef } from "components/DrawerFormApi";
import api from "api";
import {ITallerista} from "api/interfaces/Tallerista";
import TalleristaForm from "./form";

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    name: undefined
  })
}

const TalleristesList: React.FC = () => {
  // useWhyDidYouUpdate('TalleristesList', props);
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();

  const { filters: query } = useQuery();

  const { tableProps, error, refresh, reload, search } = useTable<ITallerista>(api.talleristes, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<ITallerista> => {
    return {
      title: "Nou tallerista",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.talleristes.newInstance()
    }
  }, [refresh]);

  const { create, edit, drawerProps } = useDrawerForm<ITallerista>(api.talleristes, formOptions);

  const columns: ColumnType<ITallerista>[] = [
    {
      title: 'Nom',
      dataIndex: ["attributes", 'name'],
      key: "name"
    },
    {
      title: 'Telèfons',
      key: "phones",
      dataIndex: ["attributes", "phones"]
    },
    {
      title: 'Horari habitual',
      key: "usual_schedule",
      dataIndex: ["attributes", "usual_schedule"]
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="name">
          <Input placeholder="Nom" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["talleristes:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form }: DrawerFormChildProps) => (
          <TalleristaForm form={form} />
        )}
      </DrawerFormWithForwardRef>
    </PageHeaderWrapper>
  );
};

export default TalleristesList;
