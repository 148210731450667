import { AxiosResponse } from 'axios'
import ApiError from './ApiError'

class BasicApiError extends ApiError {
  constructor(serverResponse: AxiosResponse, errorsSummary: string) {
    super(serverResponse)
    Object.setPrototypeOf(this, BasicApiError.prototype)
    this.name = 'BasicApiError'
    this.summary = errorsSummary
  }
}

export default BasicApiError
