import React, {useMemo, useReducer} from "react";
import ActivitySessionForm from "./form";
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import {useQuery, useRouter} from "hooks/useRouter";
import api from "api";
import LoadingError from "components/LoadingError";
import {JsonApiDocument} from "api/interfaces/JsonApi";
import {IActivitySession} from "api/interfaces/activity/Session";

export interface IState {
  item?: IActivitySession
  included: JsonApiDocument["included"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: null,
  included: [],
  isFetching: false,
  error: null
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const ActivityEditPage: React.FC = () => {
  const router = useRouter();
  const { activityId } = useQuery();

  const endpoint = useMemo(() => api.activitySessions(activityId), [activityId])
  const goToActivitySession = (item: IActivitySession) => router.push(`/activities/${activityId}/sessions/${item.id}`);

  const [state] = useReducer(reducer, {...initialState, item: endpoint.newInstance({ activity_id: activityId }) });

  if (!state.item) { return <LoadingError /> }

  return (
    <PageHeaderWrapper title="Crear sessió">
      <ActivitySessionForm api={endpoint} record={state.item} handleCreated={goToActivitySession} />
    </PageHeaderWrapper>
  );
};

export default ActivityEditPage;
