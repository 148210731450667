import { AxiosResponse } from 'axios'
import BasicApiError from './BasicApiError'

export default class ExpandedApiError extends BasicApiError {
  public errors: any

  constructor(serverResponse: AxiosResponse, errorsSummary: string, errors: any) {
    super(serverResponse, errorsSummary)
    Object.setPrototypeOf(this, ExpandedApiError.prototype)
    this.name = 'ExpandedApiError'
    this.errors = errors;
  }

  // public getErrors(path: string[]): string[] | null {
  //   return get(this.errors, path, null)
  // }
}
