import { Canceler } from "axios";
import { createBrowserHistory as createHistory } from "history";

import { CANCEL_REQUEST_MESSAGE } from "utils/constants";


const history = createHistory();

// Setup request cancel
history.listen(() => {
  const { cancelRequest = new Map() } = window

  cancelRequest.forEach((value: {pathname: string, cancel: Canceler}, key: Symbol) => {
    if (value.pathname !== window.location.pathname) {
      value.cancel(CANCEL_REQUEST_MESSAGE)
      cancelRequest.delete(key)
    }
  })
});

export default history;
