import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { ITeamPresenceResult, ITeamPresencesResult, ITeamPresence } from '../../interfaces/team/Presence'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class TeamPresences extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly team_id: string;

  protected dateFields: string[] = ["day"];
  protected dateTimeFields: string[] = ["from", "to"];

  constructor(team_id: string, host?: string) {
    super(host);
    this.team_id = team_id;
  }

  public async list(params: IQuery = {}): Promise<ITeamPresencesResult> {
    return await this.request(GET, Routes.teamPresencesPath(this.team_id), {}, params) as ITeamPresencesResult
  }

  public async show(id: string, params: IQuery = {}): Promise<ITeamPresenceResult> {
    return await this.request(GET, Routes.teamPresencePath(this.team_id, id), {}, params) as ITeamPresenceResult
  }

  public async create(params: JsonApiDocument): Promise<ITeamPresenceResult> {
    return await this.request(POST, Routes.teamPresencesPath(this.team_id), {}, params) as ITeamPresenceResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ITeamPresenceResult> {
    return await this.request(PATCH, Routes.teamPresencePath(this.team_id, id), {}, params) as ITeamPresenceResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.teamPresencePath(this.team_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : ITeamPresence {
    return {
      id: null,
      type: "team_presences",
      attributes: {
        day: moment.utc(),
        observations: "",
        from: moment.utc(),
        to: moment.utc(),
        kind: "",
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
