import React from "react"
import {Form, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {axiosConfig} from "utils/request";
import {useAxiosRequest} from "use-axios-request";
import {filterOptionByLabel} from "utils/helpers"
import {IAttachmentType} from "api/interfaces/AttachmentType";
import { Routes } from "api/routes";

const { Option } = Select;

export interface AttachmentFormProps {
  form: FormInstance;
}


const categoriesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("attachment_types"), params: { items: "all" } };

const AttachmentForm: React.FC<AttachmentFormProps> = ({ form }) => {
  const { data: categories = { data: [] }, isFetching: isFetchingCategories } = useAxiosRequest<{ data: IAttachmentType[] }>(categoriesRequest);

  return (
    <>
      <Form.Item name={["attributes", "attachment_type_id"]} label="Tipus de fitxer" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
        <Select loading={isFetchingCategories} placeholder="Selecciona el tipus de fitxer" style={{ width: "100%" }} filterOption={filterOptionByLabel} showSearch>
          {categories && categories.data.map((cat: IAttachmentType) => <Option key={cat.id} value={parseInt(cat.id)}>{cat.attributes.name}</Option>)}
        </Select>
      </Form.Item>
      {/*<Form.Item name={["attributes", "name"]} label="Nom" rules={[{ required: true, message: "Aquest camp és requerit" }]}>*/}
      {/*  <Input placeholder="Nom del directory" />*/}
      {/*</Form.Item>*/}
    </>
  )
};

export default AttachmentForm;
