import * as Sentry from '@sentry/browser';
import { History } from 'history';
import {
  Action, action,
  Thunk, thunk, ActionOn, actionOn, Computed, computed
} from 'easy-peasy';

import routeList from "pages/menu";
import { logout, whoami, permissions } from "services/auth";
import { pathMatchRegexp } from "utils/helpers"
import { MenuDataItem } from '@ant-design/pro-layout/lib/typings';
import { StoreModel } from 'utils/store';

export interface CurrentUser {
  id?: string;

  attributes: {
    full_name?: string;
    role?: string;
    familiar_name?: string;
    initials?: string;
    avatar_color?: string;
  }
}

export interface AppModel {
  // state
  currentUser: CurrentUser,
  currentPermissions: string[],
  routeList: MenuDataItem[],
  loading: boolean,

  // actions
  setCurrentUser: Action<AppModel, CurrentUser>,
  setCurrentPermissions: Action<AppModel, string[]>,
  setLoading: Action<AppModel, boolean>

  // thunks
  fetchCurrent: Thunk<AppModel>,
  signIn: Thunk<AppModel, { user: CurrentUser, history: History<any>}>
  signOut: Thunk<AppModel, { history: History<any>}>

  // computed
  hasPermissions: Computed<AppModel, boolean, StoreModel>
  isLoggedIn: Computed<AppModel, string | undefined, StoreModel>

  // listeners
  onFetchCurrent: ActionOn<AppModel, StoreModel>
}

const appModel: AppModel = {
  currentUser: {
    attributes: {}
  },
  currentPermissions: [],
  routeList,
  loading: true,

  setCurrentUser: action((state, payload) => {
    state.currentUser = payload;
    Sentry.setUser({"id": payload.id});
  }),

  setCurrentPermissions: action((state, payload) => {
    state.currentPermissions = payload;
  }),

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  fetchCurrent: thunk(async (actions, payload) => {
    actions.setLoading(true);

    const response = await whoami();

    if (response.success) {
      actions.setCurrentUser(response.data.data);
    } else {
      actions.setCurrentUser({ attributes: {} });
    }

    const permissionsResponse = await permissions();

    if (permissionsResponse.success) {
      actions.setCurrentPermissions(permissionsResponse.data.items);
    } else {
      actions.setCurrentPermissions([]);
    }

    actions.setLoading(false);
  }),

  signIn: thunk(async (actions, { user, history }) => {
    await actions.fetchCurrent();

    const from = history.location.pathname;

    if (!pathMatchRegexp('/auth/login', from)) {
      if (['', '/'].includes(from)) {
        history.push('/') // push /dashboard to free the root path
      } else {
        history.push(from)
      }
    } else {
      history.push('/')
    }
  }),

  signOut: thunk(async (actions, { history }) => {
    const response = await logout();

    if(response.success) {
      actions.setCurrentUser({ attributes: {} });
      actions.setCurrentPermissions([]);
      history.push("/auth/login");
    } else {
      throw response;
    }
  }),

  hasPermissions: computed(state => {
    return state.currentPermissions
      && Array.isArray(state.currentPermissions)
      && state.currentPermissions.length > 0
  }),

  isLoggedIn: computed(state => {
    return state.currentUser
      && state.currentUser.id;
  }),

  onFetchCurrent: actionOn(
    (actions, storeActions) => actions.fetchCurrent,
    (state, { payload }) => {
      // state.routeList = routeList.filter(item => )
    },
  )
};

export default appModel;
