import React from 'react';

import { Col, Form, DatePicker } from 'antd';
import { twoColumns } from 'utils/constants';
import { dateFormats } from 'utils/formats';


const EndOnDate = ({
  onDate: {
    date,
    // options,
  },
  handleChange,
}) => {
  console.log("date", date);
  return (
    <Col {...twoColumns}>
      <Form.Item label="Data" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
        <DatePicker
          className="w100"
          format={dateFormats.display}
          placeholder="Data"
          value={date}
          onChange={(value) => handleChange("end.onDate.date", value)}
        />
      </Form.Item>
    </Col>
  );
};

// EndOnDate.propTypes = {
//   id: PropTypes.string.isRequired,
//   onDate: PropTypes.shape({
//     date: PropTypes.string.isRequired,
//     options: PropTypes.shape({
//       weekStartsOnSunday: PropTypes.bool,
//       calendarComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
//     }).isRequired,
//   }).isRequired,
//   handleChange: PropTypes.func.isRequired,
//   translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default EndOnDate;
