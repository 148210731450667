import React, {useMemo} from "react";
import {Form, Button, Table, Alert, Select} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";
import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions, renderRecordDate, renderObservations, handleDestroy} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {IActivityRegistration} from "api/interfaces/activity/Registration";
import RegistrationForm from "../../activities/forms/registration";
import {ModalFormChildProps, ModalFormWithForwardRef} from "../../../components/ModalFormApi";
import CreateRegistrationForm from "../forms/create_registration";
import {useQuery} from "hooks/useRouter";
import { FormInstance } from "antd/lib/form";

interface RegistrationsListProps {
  individualId: string
}

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    status: undefined
  })
}


const RegistrationsList: React.FC<RegistrationsListProps> = ({ individualId }) => {
  const [form] = Form.useForm();
  const { filters: query } = useQuery();

  const apiEndpoint = React.useMemo(() => api.individualRegistrations(individualId), [individualId]);

  const { tableProps, error, refresh, reload, search } = useInnerTable<IActivityRegistration>(apiEndpoint, { form, formResetCallback });
  const { submit: searchSubmit, reset: resetSearch } = search!;

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const createFormOptions = React.useMemo(() : useDrawerFormOptionsType<IActivityRegistration> => {
    return {
      title: "Nova inscripció",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, drawerProps: createDrawerProps } = useDrawerForm<IActivityRegistration>(apiEndpoint, createFormOptions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IActivityRegistration> => {
    return {
      title: "Inscripció",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { edit, drawerProps } = useDrawerForm<IActivityRegistration>(apiEndpoint, formOptions);

  const destroyAction = useMemo(() => {
    return handleDestroy(apiEndpoint, refresh)
  }, [apiEndpoint, refresh]);

  const columns: ColumnType<IActivityRegistration>[] = [
    { title: "Activitat", key: "whow", dataIndex: ["attributes", "activity_name"] },
    { title: "Rebuda", key: "received", render: renderRecordDate("requested_at") },
    { title: "Acceptada", key: "validation", render: renderRecordDate("accepted_at") },
    { title: "Cancel·lada", key: "cancel", render: renderRecordDate("cancelled_at") },
    { title: "Motiu de la baixa", key: "cancel_reason", dataIndex: ["attributes", "cancel_reason_name"] },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, destroyAction)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="status" label="" >
          <Select placeholder="Selecciona l'estat" showSearch allowClear>
            <Select.Option key="validated" value="validated">Acceptades</Select.Option>
            <Select.Option key="waiting" value="waiting">En espera</Select.Option>
            <Select.Option key="cancelled" value="cancelled">Cancel·lades</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearch}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["activities:create"]}>
          <Button icon={<PlusOutlined />} onClick={create} style={{ marginRight: "5px" }}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...createDrawerProps}>
        {({ form, readOnly }: ModalFormChildProps) => (
          <CreateRegistrationForm form={form} readOnly={readOnly} />
        )}
      </ModalFormWithForwardRef>

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <RegistrationForm form={form} readOnly={readOnly}/>
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default RegistrationsList;
