import React from "react";
import { Form, Row, Col, Input, Button, Select, DatePicker, TimePicker } from "antd";
import {rowConfig, oneColumn, sixColumns, threeColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {IIncidence, INewIncidence} from "api/interfaces/Incidence";
import {IQueryEditable} from "../../api/interfaces/Query";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { useAxiosRequest } from "use-axios-request";
import { IDictionary } from "api/interfaces/Dictionary";
import { filterOptionByLabel } from "utils/helpers";
import { IUser } from "api/interfaces/User";
import { dateFormats, timeFormats } from "utils/formats";

interface IncidenceFormProps {
  api: IQueryEditable,
  record: IIncidence|INewIncidence,
  handleCreated?: (item: IIncidence | IIncidence[]) => void,
  handleUpdated?: (item: IIncidence) => void,
}

class InnerForm extends PageForm<IIncidence|INewIncidence> {}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const incidenceTypesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("incidence_types"), params: { items: "all" } };
const closingReasonsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("incidence_closing_reasons"), params: { items: "all" } };
const severityLevelsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("incidence_severity_levels"), params: { items: "all" } };
const spacesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("spaces"), params: { items: "all" } };
const usersRequest = { ...axiosConfig, baseURL: "/", url: Routes.usersPath(), params: { items: "all" } };

const IncidenceForm: React.FC<IncidenceFormProps> = ({ api, record, handleCreated = () => void 0, handleUpdated = () => void 0 }) => {
  const [form] = Form.useForm();

  const { data: incidenceTypesData, isFetching: isFetchingIncidenceTypes } = useAxiosRequest<{ data: IDictionary[] }>(incidenceTypesRequest);
  const { data: incidenceTypes = [] } = incidenceTypesData || {};

  const { data: severityLevelsData, isFetching: isFetchingSeverityLevels } = useAxiosRequest<{ data: IDictionary[] }>(severityLevelsRequest);
  const { data: severityLevels = [] } = severityLevelsData || {};

  const { data: closingReasonsData, isFetching: isFetchingClosingReasons } = useAxiosRequest<{ data: IDictionary[] }>(closingReasonsRequest);
  const { data: closingReasons = [] } = closingReasonsData || {};

  const { data: spacesData, isFetching: isFetchingSpaces } = useAxiosRequest<{ data: IDictionary[] }>(spacesRequest);
  const { data: spaces = [] } = spacesData || {};

  const { data: usersData, isFetching: isFetchingUsers } = useAxiosRequest<{ data: IUser[] }>(usersRequest);
  const { data: users = [] } = usersData || {};

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleUpdated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Row {...rowConfig}>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "incidence_severity_level_id"]} label="Nivell de gravetat" rules={rules.required}>
                <Select disabled={readOnly} placeholder="Selecciona un nivell" loading={isFetchingSeverityLevels} filterOption={filterOptionByLabel} showSearch>
                  {severityLevels.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...threeColumns}>
              <Form.Item name={["attributes", "user_id"]} label="Professional que la registra" rules={rules.required}>
                <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingUsers} filterOption={filterOptionByLabel} showSearch>
                  {users.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.full_name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "start_date"]} label="Data d'inici" rules={rules.required}>
                <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "starts_at"]} label="Hora d'inici" rules={rules.required}>
                <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "end_date"]} label="Data de fi">
                <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "ends_at"]} label="Hora fi">
                <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
              </Form.Item>
            </Col>
            <Col {...threeColumns}>
              <Form.Item name={["attributes", "incidence_closing_reason_id"]} label="Motiu de tancament">
                <Select disabled={readOnly} placeholder="Selecciona els elements" loading={isFetchingClosingReasons} filterOption={filterOptionByLabel} showSearch allowClear>
                  {closingReasons.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...oneColumn}>
              <Form.Item name={["attributes", "incidence_type_ids"]} label="Tipus d'incidències">
                <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els elements" loading={isFetchingIncidenceTypes} filterOption={filterOptionByLabel} showSearch>
                  {incidenceTypes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...oneColumn}>
              <Form.Item name={["attributes", "space_ids"]} label="Espais als que afecta">
                <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els elements" loading={isFetchingSpaces} filterOption={filterOptionByLabel} showSearch>
                  {spaces.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name={["attributes", "observations"]}>
            <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
          </Form.Item>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default IncidenceForm;
