import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { IIndividualResult, IIndividualsResult, IIndividual } from '../interfaces/Individual'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";
import moment from 'moment'

export default class Individuals extends Http implements IQueryList, IQueryEditable {
  protected dateFields: string[] = ["birthdate"];

  public async list(params: IQuery = {}): Promise<IIndividualsResult> {
    return await this.request(GET, Routes.individualsPath(), {}, params) as IIndividualsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IIndividualResult> {
    return await this.request(GET, Routes.individualPath(id), {}, params) as IIndividualResult
  }

  public async create(params: JsonApiDocument): Promise<IIndividualResult> {
    return await this.request(POST, Routes.individualsPath(), {}, params) as IIndividualResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IIndividualResult> {
    return await this.request(PATCH, Routes.individualPath(id), {}, params) as IIndividualResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.individualPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IIndividual {
    return {
      id: null,
      type: "individuals",
      attributes: {
        first_name: "",
        birthdate: moment.utc(),
        diseases: "",
        email: "",
        fb: "",
        observations: "",
        other_networks: "",
        phones: "",
        photos_authorization: false,
        social_security_number: "",
        last_name: "",
        full_name: "",
        vault_id: undefined
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
