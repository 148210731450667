import React from "react"
import {DatePicker, Form, Input, TimePicker} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats, timeFormats} from "../../../utils/formats";


export interface PresenceFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const PresenceForm: React.FC<PresenceFormProps> = ({ readOnly = false }) => {
  return (
    <>
      <Form.Item name={["attributes", "day"]} label="Dia" rules={rules.required}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "from"]} label="Entrada" rules={rules.required}>
        <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
      </Form.Item>
      <Form.Item name={["attributes", "to"]} label="Sortida" rules={rules.required}>
        <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
      </Form.Item>
      <Form.Item name={["attributes", "observations"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default PresenceForm;
