import React  from "react"
import {DatePicker, Form, Input, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "../../../utils/formats";
import {axiosConfig} from "../../../utils/request";
import {useAxiosRequest} from "use-axios-request";
import {filterOptionByLabel} from "utils/helpers"
import { Routes } from "api/routes";
import { IDictionary } from "api/interfaces/Dictionary";
import { IEntity } from "api/interfaces/Entity";
import { IActivity } from "api/interfaces/Activity";


export interface DemandNeedAnswerFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const responseTypesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("demand_need_response_types"), params: { items: "all" } };
const activitiesRequest = { ...axiosConfig, baseURL: "/", url: Routes.activitiesPath(), params: { items: "all" } };
const entitiesRequest = { ...axiosConfig, baseURL: "/", url: Routes.entitiesPath(), params: { items: "all", filters: { kind: "service" } } };

const DemandNeedAnswerForm: React.FC<DemandNeedAnswerFormProps> = ({ readOnly = false }) => {
  const { data: responseTypesData, isFetching: isFetchingResponseTypes } = useAxiosRequest<{ data: IDictionary[] }>(responseTypesRequest);
  const { data: responseTypes = [] } = responseTypesData || {};

  const { data: activitiesData, isFetching: isFetchingActivities } = useAxiosRequest<{ data: IActivity[] }>(activitiesRequest);
  const { data: activities = [] } = activitiesData || {};

  const { data: entitiesData, isFetching: isFetchingEntities } = useAxiosRequest<{ data: IEntity[] }>(entitiesRequest);
  const { data: entities = [] } = entitiesData || {};

  return (
    <>
      <Form.Item name={["attributes", "date"]} label="Dia" rules={rules.required}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "demand_need_response_type_id"]} label="Tipus de resposta" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingResponseTypes} filterOption={filterOptionByLabel} showSearch>
          {responseTypes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "activity_id"]} label="Activitat" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingActivities} filterOption={filterOptionByLabel} showSearch>
          {activities.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "entity_ids"]} label="Serveis">
        <Select disabled={readOnly} placeholder="Selecciona un element" mode="multiple" loading={isFetchingEntities} filterOption={filterOptionByLabel} showSearch>
          {entities.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "observations"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default DemandNeedAnswerForm;
