import React from 'react';
import RepeatYearly from './Yearly/index';
import RepeatMonthly from './Monthly/index';
import RepeatWeekly from './Weekly/index';
import RepeatDaily from './Daily/index';
import RepeatHourly from './Hourly/index';
import { Select, Col, Form, Row } from 'antd';
import { rowConfig, oneColumn } from 'utils/constants';

// import { useWhyDidYouUpdate } from "hooks/useWhyDidYouUpdate"

const Repeat = (props) => {
  // useWhyDidYouUpdate('Repeat', props);

  const {
    repeat: {
      frequency,
      yearly,
      monthly,
      weekly,
      daily,
      hourly,
      options,
    },
    handleChange,
  } = props;

  const isOptionAvailable = option => !options.frequency || options.frequency.indexOf(option) !== -1;
  const isOptionSelected = option => frequency === option;

  return (
    <>
      <Row {...rowConfig}>
        <Col {...oneColumn}>
          <Form.Item label="Repetició">
            <Select value={frequency} onChange={(value) => handleChange('repeat.frequency', value)}>
              <Select.Option key="null" value="">Sense repetició</Select.Option>
              {isOptionAvailable('Yearly') && <Select.Option value="Yearly">Anualment</Select.Option>}
              {isOptionAvailable('Monthly') && <Select.Option value="Monthly">Mensualment</Select.Option>}
              {isOptionAvailable('Weekly') && <Select.Option value="Weekly">Setmanalment</Select.Option>}
              {/* {isOptionAvailable('Daily') && <Select.Option value="Daily">Diariament</Select.Option>}
              {isOptionAvailable('Hourly') && <Select.Option value="Hourly">A hores</Select.Option>} */}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {
        isOptionSelected('Yearly') &&
        <RepeatYearly
          yearly={yearly}
          handleChange={handleChange}
        />
      }
      {
        isOptionSelected('Monthly') &&
        <RepeatMonthly
          monthly={monthly}
          handleChange={handleChange}
        />
      }
      {
        isOptionSelected('Weekly') &&
        <RepeatWeekly
          weekly={weekly}
          handleChange={handleChange}
        />
      }
      {
        isOptionSelected('Daily') &&
        <RepeatDaily
          daily={daily}
          handleChange={handleChange}
        />
      }
      {
        isOptionSelected('Hourly') &&
        <RepeatHourly
          hourly={hourly}
          handleChange={handleChange}
        />
      }

    </>
  );
};

// Repeat.propTypes = {
//   id: PropTypes.string.isRequired,
//   repeat: PropTypes.shape({
//     frequency: PropTypes.oneOf(['Yearly', 'Monthly', 'Weekly', 'Daily', 'Hourly']).isRequired,
//     yearly: PropTypes.object.isRequired,
//     monthly: PropTypes.object.isRequired,
//     weekly: PropTypes.object.isRequired,
//     daily: PropTypes.object.isRequired,
//     hourly: PropTypes.object.isRequired,
//     options: PropTypes.shape({
//       frequency: PropTypes.arrayOf(PropTypes.oneOf(['Yearly', 'Monthly', 'Weekly', 'Daily', 'Hourly'])),
//       yearly: PropTypes.oneOf(['on', 'on the']),
//       monthly: PropTypes.oneOf(['on', 'on the']),
//     }).isRequired,
//   }).isRequired,
//   handleChange: PropTypes.func.isRequired,
//   translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default Repeat;
