import React, {useState} from "react"
import {Col, Form, Row, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {debounce} from "lodash";
import {oneColumn} from "../../../utils/constants";
import api from "api"
import {ITeam} from "../../../api/interfaces/Team";


export interface PresenceFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const CreateRegistrationForm: React.FC<PresenceFormProps> = ({ readOnly = false }) => {
  const [state, setState] = useState({
    data: [],
    loading: false,
    error: null
  })

  const onSearch = debounce(async (value) => {
    if (value.length < 2) {
      setState({
        ...state,
        data: [],
        loading: false,
        error: null
      })
      return;
    }

    const response = await api.activities.list({
      items: "all",
      filters: { name: value }
    });

    if (response.isSuccess()) {
      setState({
        ...state,
        data: response.success().data,
        loading: false,
        error: null
      })
    } else {
      setState({
        ...state,
        data: [],
        loading: false,
        error: response.fail().message
      })
    }
  }, 600);

  return (
    <>
      <Row>
        <Col {...oneColumn}>
          <Form.Item name={["attributes", "activity_id"]} label="Activitat">
            <Select disabled={readOnly} placeholder="Buscar per nom" loading={state.loading}
                    filterOption={false} showSearch onSearch={onSearch}>
              {state.data.map((item: ITeam) => (
                <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
};

export default CreateRegistrationForm;
