import React, {useMemo} from "react";
import {Form, Button, Table, Alert, Select} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";
import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions, renderRecordDate, renderObservations, handleDestroy} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {ITeamIndividual} from "api/interfaces/team/Individual";
import IndividualForm from "../forms/individual";
import {ModalFormChildProps, ModalFormWithForwardRef} from "../../../components/ModalFormApi";
import CreateIndividualForm from "../forms/create_individual";
import {useQuery} from "hooks/useRouter";
import { FormInstance } from "antd/lib/form";

interface IndividualsListProps {
  teamId: string
}

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    status: undefined
  })
}


const IndividualsList: React.FC<IndividualsListProps> = ({ teamId }) => {
  const [form] = Form.useForm();
  const { filters: query } = useQuery();

  const apiEndpoint = React.useMemo(() => api.teamIndividuals(teamId), [teamId]);

  const { tableProps, error, refresh, reload, search } = useInnerTable<ITeamIndividual>(apiEndpoint, { form, formResetCallback });
  const { submit: searchSubmit, reset: resetSearch } = search!;

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const createFormOptions = React.useMemo(() : useDrawerFormOptionsType<ITeamIndividual> => {
    return {
      title: "Nou membre",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, drawerProps: createDrawerProps } = useDrawerForm<ITeamIndividual>(apiEndpoint, createFormOptions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<ITeamIndividual> => {
    return {
      title: "Membre",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { edit, drawerProps } = useDrawerForm<ITeamIndividual>(apiEndpoint, formOptions);

  const destroyAction = useMemo(() => {
    return handleDestroy(apiEndpoint, refresh)
  }, [apiEndpoint, refresh]);

  const columns: ColumnType<ITeamIndividual>[] = [
    { title: "Membre", key: "whow", dataIndex: ["attributes", "individual_name"] },
    { title: "Des de", key: "received", render: renderRecordDate("from") },
    { title: "Fins", key: "validation", render: renderRecordDate("to") },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, destroyAction)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="status" label="" >
          <Select placeholder="Selecciona l'estat" showSearch allowClear>
            <Select.Option key="active" value="active">Actius</Select.Option>
            <Select.Option key="inactive" value="inactive">Inactius</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearch}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["teams:create"]}>
          <Button icon={<PlusOutlined />} onClick={create} style={{ marginRight: "5px" }}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...createDrawerProps}>
        {({ form, readOnly }: ModalFormChildProps) => (
          <CreateIndividualForm form={form} readOnly={readOnly} />
        )}
      </ModalFormWithForwardRef>

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <IndividualForm form={form} readOnly={readOnly}/>
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default IndividualsList;
