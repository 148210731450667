import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { ICessionDateResult, ICessionDatesResult, ICessionDate } from '../../interfaces/cession/Date'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class CessionDates extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly cession_id: string;

  protected dateFields: string[] = ["day"];
  protected dateTimeFields: string[] = ["ends_at", "starts_at"];

  constructor(cession_id: string, host?: string) {
    super(host);
    this.cession_id = cession_id;
  }

  public async list(params: IQuery = {}): Promise<ICessionDatesResult> {
    return await this.request(GET, Routes.cessionDatesPath(this.cession_id), {}, params) as ICessionDatesResult
  }

  public async show(id: string, params: IQuery = {}): Promise<ICessionDateResult> {
    return await this.request(GET, Routes.cessionDatePath(this.cession_id, id), {}, params) as ICessionDateResult
  }

  public async create(params: JsonApiDocument): Promise<ICessionDateResult> {
    return await this.request(POST, Routes.cessionDatesPath(this.cession_id), {}, params) as ICessionDateResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ICessionDateResult> {
    return await this.request(PATCH, Routes.cessionDatePath(this.cession_id, id), {}, params) as ICessionDateResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.cessionDatePath(this.cession_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : ICessionDate {
    return {
      id: null,
      type: "cession_dates",
      attributes: {
        cession_id: null,
        day: moment.utc(),
        ends_at: moment.utc(),
        observations: "",
        starts_at: moment.utc(),
        material_names: "",
        space_id: null,
        space_name: "",
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
