import React from "react"
import { Form, Input } from "antd"
import { FormInstance } from "antd/lib/form";

export interface DictionaryFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  name: [ { required: true, message: "Aquest camp és requerit" } ],
}

const DictionaryForm: React.FC<DictionaryFormProps> = ({ readOnly = false }) => {
  return (
    <>
      <Form.Item name={["attributes", "name"]} label="Nom" rules={rules.name}>
        <Input disabled={readOnly} placeholder="Nom de l'element del diccionari" />
      </Form.Item>
    </>
  )
}

export default DictionaryForm;
