import React from "react";
import { Form, Row, Col, Input, Button } from "antd";
import {rowConfig, twoColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {ITeam} from "api/interfaces/Team";
import {IQueryEditable} from "../../api/interfaces/Query";

interface TeamFormProps {
  api: IQueryEditable,
  record: ITeam
}

class InnerForm extends PageForm<ITeam> {}

const TeamForm: React.FC<TeamFormProps> = ({ api, record }) => {
  const [form] = Form.useForm();

  const handleCreated = () => void 0;

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleCreated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Row {...rowConfig}>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "name"]}  label="Nom de l'equip/grup">
                <Input type="text" disabled={readOnly} placeholder="Nom de l'equip/grup" />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default TeamForm;
