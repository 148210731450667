import { GET, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList } from '../interfaces/Query'
import { IApplicationMessageResult, IApplicationMessagesResult } from '../interfaces/ApplicationMessage'
import { Routes } from '../routes'

export default class ApplicationMessages extends Http implements IQueryList {
  protected dateFields: string[] = ["created_at", "readed_at"];

  public async list(params: IQuery = {}): Promise<IApplicationMessagesResult> {
    return await this.request(GET, Routes.applicationMessagesPath(), {}, params) as IApplicationMessagesResult
  }

  public async markAsReaded(id: string): Promise<IApplicationMessageResult> {
    return await this.request(POST, Routes.applicationMessagePath(id) + '/mark_as_readed') as IApplicationMessageResult
  }
}
