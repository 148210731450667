import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IIndividualDemandNeedResult, IIndividualDemandNeedsResult, IIndividualDemandNeed } from '../../interfaces/individual/DemandNeed'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class IndividualDemandNeeds extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly individual_id: string;

  protected dateFields: string[] = ["date"];

  constructor(individual_id: string, host?: string) {
    super(host);
    this.individual_id = individual_id;
  }

  public async list(params: IQuery = {}): Promise<IIndividualDemandNeedsResult> {
    return await this.request(GET, Routes.individualDemandNeedsPath(this.individual_id), {}, params) as IIndividualDemandNeedsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IIndividualDemandNeedResult> {
    return await this.request(GET, Routes.individualDemandNeedPath(this.individual_id, id), {}, params) as IIndividualDemandNeedResult
  }

  public async create(params: JsonApiDocument): Promise<IIndividualDemandNeedResult> {
    return await this.request(POST, Routes.individualDemandNeedsPath(this.individual_id), {}, params) as IIndividualDemandNeedResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IIndividualDemandNeedResult> {
    return await this.request(PATCH, Routes.individualDemandNeedPath(this.individual_id, id), {}, params) as IIndividualDemandNeedResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.individualDemandNeedPath(this.individual_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IIndividualDemandNeed {
    return {
      id: null,
      type: "individual_demands_needs",
      attributes: {
        date: moment.utc(),
        kind: "",
        kind_name: "",
        observations: "",
        activity_type_id: undefined,
        activity_type_name: "",
        demand_need_type_id: undefined,
        demand_need_type_name: "",
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
