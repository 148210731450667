import React from "react"
import {DatePicker, Form, Select, Row, Col, InputNumber} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "utils/formats";
import { rowConfig, threeColumns } from "utils/constants";
import { useAxiosRequest } from "use-axios-request";
import { IDictionary } from "api/interfaces/Dictionary";
import { filterOptionByLabel } from "utils/helpers";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";


export interface CompositionFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const agesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("ages"), params: { items: "all" } };
const adscriptionsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("adscription_types"), params: { items: "all" } };
const cohesionsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("cohesion_types"), params: { items: "all" } };
const relationsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("community_relation_types"), params: { items: "all" } };
const placesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("residence_place_types"), params: { items: "all" } };

const CompositionForm: React.FC<CompositionFormProps> = ({ readOnly = false }) => {
  const { data: agesData, isFetching: isFetchingAges } = useAxiosRequest<{ data: IDictionary[] }>(agesRequest);
  const { data: ages = [] } = agesData || {};

  const { data: adscriptionsData, isFetching: isFetchingAdscriptions } = useAxiosRequest<{ data: IDictionary[] }>(adscriptionsRequest);
  const { data: adscriptions = [] } = adscriptionsData || {};

  const { data: cohesionsData, isFetching: isFetchingCohesions } = useAxiosRequest<{ data: IDictionary[] }>(cohesionsRequest);
  const { data: cohesions = [] } = cohesionsData || {};

  const { data: relationsData, isFetching: isFetchingRelations } = useAxiosRequest<{ data: IDictionary[] }>(relationsRequest);
  const { data: relations = [] } = relationsData || {};

  const { data: placesData, isFetching: isFetchingPlaces } = useAxiosRequest<{ data: IDictionary[] }>(placesRequest);
  const { data: places = [] } = placesData || {};

  return (
    <>
      <Form.Item name={["attributes", "date"]} label="Dia" rules={rules.required}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Row {...rowConfig}>
        <Col {...threeColumns}>
          <Form.Item name={["attributes", "guys"]} label="Nº nois">
            <InputNumber disabled={readOnly} placeholder="" />
          </Form.Item>
        </Col>
        <Col {...threeColumns}>
          <Form.Item name={["attributes", "girls"]} label="Nº noies">
            <InputNumber disabled={readOnly} placeholder="" />
          </Form.Item>
        </Col>
        <Col {...threeColumns}>
          <Form.Item name={["attributes", "others"]} label="Nº altres">
            <InputNumber disabled={readOnly} placeholder="" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={["attributes", "age_id"]} label="Edat predominant" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingAges} filterOption={filterOptionByLabel} showSearch>
          {ages.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "adscription_type_id"]} label="Adscripció" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingAdscriptions} filterOption={filterOptionByLabel} showSearch>
          {adscriptions.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "cohesion_type_id"]} label="Cohesió" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingCohesions} filterOption={filterOptionByLabel} showSearch>
          {cohesions.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "community_relation_type_id"]} label="Relació comunitat" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingRelations} filterOption={filterOptionByLabel} showSearch>
          {relations.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "residence_place_type_id"]} label="Lloc residència" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingPlaces} filterOption={filterOptionByLabel} showSearch>
          {places.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
    </>
  )
};

export default CompositionForm;
