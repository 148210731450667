import React from "react"
import {Col, Form, Input, Row} from "antd"
import { FormInstance } from "antd/lib/form";
import {oneColumn, rowConfig} from "../../../utils/constants";


export interface PresenceFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const ParticipantForm: React.FC<PresenceFormProps> = ({ form, readOnly = false }) => {
  return (
    <>
      <h3 className="text-center">{ form.getFieldValue(["attributes", "who"]) }</h3>
      <Row {...rowConfig}>
        <Col {...oneColumn}>
          <Form.Item name={["attributes", "observations"]} label="Observacions">
            <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
};

export default ParticipantForm;
