export const DATE_TIME_FORMAT = 'YYYY-MM-DD';

export const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
  'Day',
  'Weekday',
  'Weekend day'
];
