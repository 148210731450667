import React, {useState} from "react";
import { FormInstance } from "antd/lib/form";
import {Form, Select} from "antd";
import {IQueryList} from "../../api/interfaces/Query";
import {debounce, get} from "lodash";

export interface RemoteSelectorProps {
  form: FormInstance;
  readOnly?: boolean;
  endpoint?: IQueryList
  valuePath: string[]
  labelPath: string[]
  queryParam: string,
  attributeName: string[]
  attributeLabel: string
}

const RemoteSelector: React.FC<RemoteSelectorProps> = (props) => {
  const {
    readOnly,
    endpoint
  } = props;

  const [state, setState] = useState({
    data: [],
    loading: false,
    error: null
  })

  const onSearch = debounce(async (value) => {
    if (value.length < 2) {
      setState({
        ...state,
        data: [],
        loading: false,
        error: null
      })
      return;
    }

    const response = await endpoint.list({
      items: "all",
      filters: { [props.queryParam]: value }
    });

    if (response.isSuccess()) {
      setState({
        ...state,
        data: response.success().data,
        loading: false,
        error: null
      })
    } else {
      setState({
        ...state,
        data: [],
        loading: false,
        error: response.fail().message
      })
    }
  }, 600);

  return (
    <Form.Item name={props.attributeName} label={props.attributeLabel}>
      <Select disabled={readOnly} placeholder="Escriu 2 o més caràcters per buscar" loading={state.loading}
              filterOption={false} showSearch onSearch={onSearch}>
        {state.data.map((item) => (
          <Select.Option key={get(item, props.valuePath)} value={parseInt(get(item, props.valuePath))}>
            {get(item, props.labelPath)}
          </Select.Option>)
        )}
      </Select>
    </Form.Item>
  )
}

export default RemoteSelector;
