import Categories from "./endpoints/Categories";
import Queries from "./endpoints/Queries";
import QueryExecutions from "./endpoints/QueryExecutions";

export interface IClientConfig {
  host?: string
}

class Client {
  private readonly host?: string

  public categories: Categories
  public queries: Queries
  public queryExecutions: (id: string) => QueryExecutions

  constructor(config: IClientConfig = {}) {
    this.host = config.host || null
    this.addEndpoints()
  }

  private addEndpoints() {
    this.categories = new Categories(this.host)
    this.queries = new Queries(this.host)
    this.queryExecutions = (id) => new QueryExecutions(id, this.host)
  }
}

export default Client
