import React, {useEffect, useReducer} from "react";
import NotFound from "components/NotFound";
import ActivityForm from "./form";
import { PageHeaderWrapper, PageLoading } from "@ant-design/pro-layout";
import { Tab } from "rc-tabs/lib/interface";
import {useQuery} from "hooks/useRouter";
import {Tabs} from "antd";
import api from "../../api";
import {IActivity} from "api/interfaces/Activity";
import LoadingError from "../../components/LoadingError";
import {JsonApiDocument} from "api/interfaces/JsonApi";
import VaultAttachmentsList from "pages/shared/attachments/vaults_list";
import AttendancesGrid from "./sections/attendances";
import RegistrationsList from "./sections/registrations";
import SessionsList from "./sections/sessions";

export interface IState {
  item?: IActivity
  included: JsonApiDocument["included"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: null,
  included: [],
  isFetching: false,
  error: null
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const ActivityEditPage: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { activityId } = useQuery();

  const attachmentBaseUrl = React.useMemo(() => `activities/${activityId}`, [activityId]);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.activities.show(activityId);

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data,
            included: result.success().included,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            included: [],
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    if (activityId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true
        }
      });

      fetchData();
    }
  }, [activityId, dispatch]);

  if (!activityId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }
  if (!state.item) { return <LoadingError /> }

  const tabs: Tab[] = [
    { key: "1", label: "Sessions", children: <SessionsList activityId={activityId} /> },
    { key: "2", label: "Inscripcions", children: <RegistrationsList activityId={activityId} /> },
    { key: "3", label: "Assistències", children: <AttendancesGrid activityId={activityId} /> },
    { key: "4", label: "Documents", children: <VaultAttachmentsList endpoint={attachmentBaseUrl} initialVault={state.item.attributes.vault_id} /> },
  ];

  return (
    <PageHeaderWrapper title="Editar activitat">
      <ActivityForm api={api.activities} record={state.item} />
      <Tabs destroyInactiveTabPane defaultActiveKey="1" items={tabs} animated={false} className="mb-20" />
    </PageHeaderWrapper>
  );
};

export default ActivityEditPage;
