import React, {useEffect, useMemo, useReducer} from "react";
import moment from "moment"
import NotFound from "components/NotFound";
import {FormInstance} from "antd/lib/form";
import {PageHeaderWrapper, PageLoading} from "@ant-design/pro-layout";
import {useQuery, useRouter} from "hooks/useRouter";
import LoadingError from "components/LoadingError";
import api from "../../api";
import {IQuery} from "../../api/interfaces/Query";
import {useTable} from "hooks/useTableApi";
import {Alert, Button, DatePicker, Form, Pagination, Typography} from "antd";
import {dateFormats} from "utils/formats";
import qs from "qs";
import {Routes} from "packages/progess-consultes/api/routes";
// import {Menu} from "@blueprintjs/core";
// import {Cell, Column, CopyCellsMenuItem, IMenuContext, RenderMode, Table} from "@blueprintjs/table";
import {Cell, Column, RenderMode, Table} from "@blueprintjs/table";

const { Paragraph, Title } = Typography;

interface IState {
  item?: IQuery
  isFetching: boolean
  error: string,
  from: moment.Moment,
  to: moment.Moment
}

class InitialState {
  item = null;
  isFetching = true;
  error = null;
  from = moment.utc();
  to = moment.utc();
}

const reducer = (state: IState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    from: undefined,
    to: undefined,
  })
}

const defaultFilters =  { from: moment.utc(), to: moment.utc() }

const QueryEditPage: React.FC = () => {
  const { filters: query = defaultFilters } = useQuery();
  const { query: { queryId } } = useRouter();

  const [form] = Form.useForm();

  const initialState = useMemo(() => {
    const state = new InitialState();

    if (!moment.isMoment(query.from)) {
      query.from = moment.utc(query.from)
      state.from = moment.utc(query.from)
    }
    if (!moment.isMoment(query.to)) {
      query.to = moment.utc(query.to)
      state.to = moment.utc(query.to)
    }

    return state;
  }, [query]);

  const [state, dispatch] = useReducer(reducer, initialState);

  const excutionEndpoint = useMemo(() => api.queryExecutions(queryId), [queryId])

  const { handlePagination, tableProps, error, refresh, search } = useTable<IQuery>(excutionEndpoint, {
    defaultFilters,
    form,
    formResetCallback: searchFormResetCallback
  });
  const { submit: searchSubmit } = search!;

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.queries.show(queryId);

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    dispatch({
      type: 'updateState',
      payload: {
        isFetching: true
      }
    })

    fetchData();
  }, [queryId, dispatch])

  if (!queryId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }

  const exportUrl = () => {
    const params = { filters: { from: query.from.format(dateFormats.server), to: query.to.format(dateFormats.server) }, export: 1 }

    return `/${Routes.executeQueryPath(queryId)}?${qs.stringify(params, { encodeValuesOnly: true, arrayFormat: 'brackets' })}`
  }

  const onFinish = (formValues: {[key: string]: any}) : void =>  {
    const from = formValues["from"] ? formValues["from"].format('YYYY-MM-DD') : undefined;
    const to = formValues["to"] ? formValues["to"].format('YYYY-MM-DD') : undefined;

    searchSubmit({ ...formValues, from, to});
  }

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={onFinish}>
        <Form.Item name="from" label="Des de">
          <DatePicker className="w100" format={[dateFormats.display, "YYYY-MM-DD"]} placeholder="Sel·lecciona una data" />
        </Form.Item>
        <Form.Item name="to" label="Fins">
        <DatePicker className="w100" format={[dateFormats.display, "YYYY-MM-DD"]} placeholder="Sel·lecciona una data" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={form.submit}>Executar</Button>
        </Form.Item>
      </Form>
      <div>
        <Button href={exportUrl()} target="_blank">Exportar</Button>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error: {error}</span>
      <Button type="link" onClick={refresh}>Reiniciar</Button>
    </div>
  )

  const getCell = (rowIndex, columnIndex) => <Cell>{getCellData(rowIndex, columnIndex)}</Cell>;

  const getCellData = (rowIndex, columnIndex) => {
    if (tableProps.dataSource.length === 0 || tableProps.dataSource.length < rowIndex)
      return ""

    const field = columns[columnIndex].dataIndex
    return tableProps.dataSource[rowIndex][field];
  }

  const columns = state.item.attributes.fields.split(":").map((column) => {
    return {
      name: column,
      dataIndex: column,
      key: column
    };
  });

  // const renderBodyContextMenu = (context: IMenuContext) => {
  //   return (
  //     <Menu>
  //       <CopyCellsMenuItem context={context} getCellData={getCellData} text="Copiar" />
  //     </Menu>
  //   );
  // };

  return (
    <PageHeaderWrapper title="Executar consulta">
      <Title level={4}>{state.item.attributes.name}</Title>
      <Paragraph style={{marginBottom: "25px"}}>{state.item.attributes.description}</Paragraph>

      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table
        numRows={tableProps.dataSource.length}
        // bodyContextMenuRenderer={renderBodyContextMenu}
        getCellClipboardData={getCellData}
        renderMode={RenderMode.NONE}
      >
        {
          columns.map(column => <Column key={column.key} name={column.name} cellRenderer={getCell} />)
        }
      </Table>

      <Paragraph type="secondary" style={{marginTop: "5px"}}>
        Pots fer servir Ctrl + C / Cmd + C per copiar valors
      </Paragraph>

      <Pagination {...tableProps.pagination} onChange={handlePagination} style={{marginTop: "10px", textAlign: "right"}}/>
    </PageHeaderWrapper>
  );
};

export default QueryEditPage;
