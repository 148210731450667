import React, { useEffect } from 'react';
import { useStoreActions } from "utils/store";

import "./BlankLayout.less";

const BlankLayout = ({ children }) => {
  const fetchCurrentUser = useStoreActions(
    actions => actions.app.fetchCurrent
  );

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  return (
    <div className="full-height">
      {children}
    </div>
  );
};

export default BlankLayout;
