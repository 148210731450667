const computeYearlyOnTheMonth = (data, rruleObj) => {
  if (rruleObj.freq !== 0 || !rruleObj.byweekday) {
    return data.repeat.yearly.onThe.month;
  }

  if (typeof rruleObj.bymonth === 'number') {
    return rruleObj.bymonth;
  }

  return rruleObj.bymonth[0];
};

export default computeYearlyOnTheMonth;
