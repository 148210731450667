import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IIndividualDemandNeedAnswerResult, IIndividualDemandNeedAnswersResult, IIndividualDemandNeedAnswer } from '../../interfaces/individual/DemandNeedAnswer'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class IndividualDemandNeedAnswers extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly individual_id: string;

  protected dateFields: string[] = ["date"];

  constructor(individual_id: string, host?: string) {
    super(host);
    this.individual_id = individual_id;
  }

  public async list(params: IQuery = {}): Promise<IIndividualDemandNeedAnswersResult> {
    return await this.request(GET, Routes.individualDemandNeedAnswersPath(this.individual_id), {}, params) as IIndividualDemandNeedAnswersResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IIndividualDemandNeedAnswerResult> {
    return await this.request(GET, Routes.individualDemandNeedAnswerPath(this.individual_id, id), {}, params) as IIndividualDemandNeedAnswerResult
  }

  public async create(params: JsonApiDocument): Promise<IIndividualDemandNeedAnswerResult> {
    return await this.request(POST, Routes.individualDemandNeedAnswersPath(this.individual_id), {}, params) as IIndividualDemandNeedAnswerResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IIndividualDemandNeedAnswerResult> {
    return await this.request(PATCH, Routes.individualDemandNeedAnswerPath(this.individual_id, id), {}, params) as IIndividualDemandNeedAnswerResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.individualDemandNeedAnswerPath(this.individual_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IIndividualDemandNeedAnswer {
    return {
      id: null,
      type: "individual_demands_needs_answers",
      attributes: {
        date: moment.utc(),
        observations: "",
        activity_id: undefined,
        activity_name: "",
        demand_need_response_type_id: undefined,
        demand_need_response_type_name: "",
        entity_ids: [],
        entities_name: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
