import React from "react"
import { Form, Input, Radio } from "antd"
import { FormInstance } from "antd/lib/form";

export interface DictionaryFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  name: [ { required: true, message: "Aquest camp és requerit" } ],
  is_sport: [ { required: true, message: "Aquest camp és requerit" } ],
}

const ActivityTypeForm: React.FC<DictionaryFormProps> = ({ readOnly = false }) => {
  return (
    <>
      <Form.Item name={["attributes", "name"]} label="Nom" rules={rules.name}>
        <Input disabled={readOnly} placeholder="Nom" />
      </Form.Item>
      <Form.Item name={["attributes", "is_sport"]} label="Esportiva" rules={rules.is_sport}>
        <Radio.Group disabled={readOnly}>
          <Radio value={false}>No</Radio>
          <Radio value>Sí</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default ActivityTypeForm;
