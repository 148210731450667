import React, {ReactElement, useCallback, useEffect, useMemo, useReducer} from "react";
import axios from "axios";
import prettyBytes from "pretty-bytes";
import {Button, Form, Table, Alert, Input, Popconfirm, message, Breadcrumb} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faFile,
  faFolder
} from '@fortawesome/free-regular-svg-icons'

import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FolderAddOutlined,
  HomeOutlined,
  UploadOutlined
} from "@ant-design/icons";

import { ModalFormChildProps, ModalFormWithForwardRef } from "components/ModalFormApi";
import UploadForm from "components/UploadForm";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {axiosConfig} from "utils/request";
import {timeFromServer, dateTimeFormats} from "utils/formats";
import AttachmentForm from "pages/shared/attachments/attachment_form";
import {IAttachmentCollection} from "api/interfaces/AttachmentCollection";
import {IAttachment} from "api/interfaces/Attachment";
import api from "api";


export interface VaultAttachmentsListProps {
  endpoint: string,
  initialVault: string|number,
}

export interface IState {
  isFetching: boolean
  showUploadModal: boolean
  error: string,
  vaultId: string|number
  vault: IAttachmentCollection,
  vaultContent: (IAttachment|IAttachmentCollection)[]
  // Counter
  requestCounter: number
}

const initialState: IState = {
  isFetching: false,
  showUploadModal: false,
  error: null,
  vaultId: null,
  vault: null,
  vaultContent: [],
  requestCounter: 0
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const renderName = (handleCollectionClick: Function) => (text: string, record: IAttachment|IAttachmentCollection) : ReactElement => {
  if (record.type === "attachment_collections" ) {
    return (
      <Button type="link"
              icon={<FontAwesomeIcon icon={faFolder} style={{marginRight: "7px"}} />}
              onClick={() : void => handleCollectionClick(record.id)}
              style={{padding: 0}}
      >
        { record.attributes.name }
      </Button>
    );
  } else {
    return <><FontAwesomeIcon icon={faFile} style={{marginRight: "10px"}} />{ record.attributes.name }</>;
  }
};

const renderType = (text: string, record: IAttachment|IAttachmentCollection) : ReactElement => {
  if (record.type === "attachment_collections" ) {
    return <></>;
  } else {
    return <>{ (record as IAttachment).attributes.attachment_type_name }</>;
  }
};

const renderSize = (text: string, record: IAttachment|IAttachmentCollection) : ReactElement => {
  if (record.type === "attachment_collections" ) {
    return <>-</>;
  } else {
    return <>{ prettyBytes((record as IAttachment).attributes.byte_size) }</>;
  }
};

const renderDate = (text: string, record: IAttachment|IAttachmentCollection) : ReactElement => {
  const date = timeFromServer(record.attributes.updated_at);

  return <span>{ date ? date.format(dateTimeFormats.display) : null}</span>;
};

const VaultAttachmentsList: React.FC<VaultAttachmentsListProps> = (props) => {
  // const { data, meta: { pagy }, links, search } = props;
  const { endpoint, initialVault } = props;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    vaultId: initialVault
  });

  const vaultsEndpoint = React.useMemo(() => api.attachmentCollections(endpoint, state.vaultId), [endpoint, state.vaultId]);
  const uploadsEndpoint = React.useMemo(() => api.attachments(endpoint, state.vaultId), [endpoint, state.vaultId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Promise.all([
          axios.get(`${endpoint}/vaults/${state.vaultId}`, { ...axiosConfig }),
          vaultsEndpoint.list({ items: "all" }),
          uploadsEndpoint.list({ items: "all" }),
        ]);

        dispatch({
          type: 'updateState',
          payload: {
            vault: result[0].data.data,
            vaultContent: [...result[1].success().data, ...result[2].success().data],
            isFetching: false,
            error: null
          }
        })
      } catch (e) {
        dispatch({
          type: 'updateState',
          payload: {
            vault: null,
            vaultContent: [],
            isFetching: false,
            error: "Error al carregar la informació"
          }
        })
      }
    }

    dispatch({
      type: 'updateState',
      payload: {
        isFetching: true,
        error: null
      }
    });

    fetchData();
  }, [dispatch, endpoint, vaultsEndpoint, uploadsEndpoint, state.requestCounter, state.vaultId])

  const handleCollectionClick = (id) => {
    dispatch({
      type: 'updateState',
      payload: {
        vaultId: id
      }
    });
  }

  const openModalManager = (): void => {
    dispatch({
      type: 'updateState',
      payload: {
        showUploadModal: true
      }
    });
  };

  const reload = useCallback(() => {
    dispatch({
      type: 'updateState',
      payload: {
        showUploadModal: false,
        requestCounter: state.requestCounter + 1
      }
    });
  }, [state.requestCounter]);

  const handleCancel = () : void => {
    dispatch({
      type: 'updateState',
      payload: {
        showUploadModal: false
      }
    });
  };

  const folderFormOptions = useMemo(() : useDrawerFormOptionsType<IAttachmentCollection> => {
    return {
      title: "Crear directori",
      handleCreated: reload,
      handleUpdated: reload,
      newRecord: vaultsEndpoint.newInstance()
    }
  }, [reload, vaultsEndpoint]);

  const { create: createFolder, edit: editFolderName, drawerProps: folderDrawerProps } = useDrawerForm<IAttachmentCollection>(vaultsEndpoint, folderFormOptions);

  const uploadsFormOptions = useMemo(() : useDrawerFormOptionsType<IAttachment> => {
    return {
      title: "Editar fitxer",
      handleCreated: reload,
      handleUpdated: reload,
      newRecord: uploadsEndpoint.newInstance()
    }
  }, [reload, uploadsEndpoint]);

  const { edit: editAttachment, drawerProps: uploadDrawerProps } = useDrawerForm<IAttachment>(uploadsEndpoint, uploadsFormOptions);

  const handleDestroyFolder = async (id: string) => {
    try {
      const response = await vaultsEndpoint.destroy(id);

      if (response.isSuccess()) {
        reload();
      } else {
        message.error("No s'ha pogut eliminar el directori", 10);
      }
    } catch (e) {
      message.error("No s'ha pogut eliminar el directori", 10);
    }
  }

  const handleDestroyUpload = async (id: string) => {
    try {
      const response = await uploadsEndpoint.destroy(id);

      if (response.isSuccess()) {
        reload();
      } else {
        message.error("No s'ha pogut eliminar el fitxer", 10);
      }
    } catch (e) {
      message.error("No s'ha pogut eliminar el fitxer", 10);
    }
  }

  const renderRecordActions = (text: string, record: IAttachment|IAttachmentCollection) : ReactElement => {
    if (record.type === "attachment_collections" ) {
      return (
        <span>
          { record.meta?.permissions.can_edit &&
            <Button type="link" onClick={() : void => editFolderName(record.id)} icon={<EditOutlined/>}/> }
          { record.meta.permissions.can_destroy &&
            <Popconfirm placement="leftBottom" title="Eliminar directori?" onConfirm={() => handleDestroyFolder(record.id)} okText="Sí" cancelText="No">
              <Button type="link" icon={<DeleteOutlined />} disabled={(record as IAttachmentCollection).attributes.has_files} />
            </Popconfirm>
          }
      </span>
      );
    } else {
      return (
        <span>
          { record.meta?.permissions.can_show && (
            <>
              <Button type="link" href={(record as IAttachment).attributes.view_url} target="_blank" icon={<EyeOutlined/>}/>
              <Button type="link" href={(record as IAttachment).attributes.url} icon={<DownloadOutlined/>}/>
            </>
          )}
          { record.meta?.permissions.can_edit &&
            <Button type="link" onClick={() : void => editAttachment(record.id)} icon={<EditOutlined/>}/> }
          { record.meta.permissions.can_destroy &&
            <Popconfirm placement="leftBottom" title="Eliminar fitxer?" onConfirm={() => handleDestroyUpload(record.id)} okText="Sí" cancelText="No">
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          }
      </span>
      );
    }
  };

  const columns: ColumnType<IAttachment|IAttachmentCollection>[] = [
    // { title: "ID", dataIndex: "id", key: "id" },
    { title: "Nom", key: "name", render: renderName(handleCollectionClick) },
    { title: "Tipus", key: "type", render: renderType },
    { title: "Tamany", align: "center", key: "size", render: renderSize },
    { title: "Última actualització", align: "left", key: "date", render: renderDate },
    { title: " ", align: "right", key: "actions", render: renderRecordActions }
  ];

  const breadcrumbs = useCallback(() => {
    return (
      <Breadcrumb separator=">">
        <Breadcrumb.Item key={initialVault} href="" onClick={(ev) : void => {ev.preventDefault(); handleCollectionClick(initialVault)}}><HomeOutlined /></Breadcrumb.Item>
        {state.vault && state.vault.attributes.ancestors.slice(1).map((item) => (
          <Breadcrumb.Item key={item.id} href="" onClick={(ev) : void => {ev.preventDefault(); handleCollectionClick(item.id)}}>{ item.name }</Breadcrumb.Item>
        ))}
      </Breadcrumb>
    )
  }, [state.vault, initialVault])

  const toolbar = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between', marginBottom: "15px" }}>
      <div>
        <Button icon={<FolderAddOutlined />} onClick={createFolder} style={{marginRight: "5px"}}>Crear carpeta</Button>
        <Button icon={<UploadOutlined />} onClick={openModalManager}>Pujar fitxer</Button>
      </div>
      {/*<div style={{textAlign: "right"}}>*/}
      {/*  <Button icon={<FolderAddOutlined />} onClick={create} />*/}
      {/*  <Button icon={<UploadOutlined />} onClick={openModalManager} />*/}
      {/*</div>*/}
    </div>
  )

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reintentar</Button>
    </div>
  )

  return (
    <>
      <UploadForm
        title="Pujar fitxers"
        visible={state.showUploadModal}
        api={uploadsEndpoint}
        handleCreated={reload}
        handleCancel={handleCancel}
      />

      <ModalFormWithForwardRef {...uploadDrawerProps}>
        {({ form }: ModalFormChildProps) => (
          <AttachmentForm form={form} />
        )}
      </ModalFormWithForwardRef>

      <ModalFormWithForwardRef {...folderDrawerProps}>
        {({ form }: ModalFormChildProps) => (
          <Form.Item name={["attributes", "name"]} label="Nom" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
            <Input placeholder="Nom del directory" />
          </Form.Item>
        )}
      </ModalFormWithForwardRef>

      { toolbar }

      { state.error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table
        bordered={false}
        columns={columns}
        dataSource={state.vaultContent}
        loading={state.isFetching}
        title={breadcrumbs}
        rowKey="id"
        scroll={{ y: 400 }}
        size="small"
      />
    </>
  );
};

export default VaultAttachmentsList;
