import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IIncidenceActionResult, IIncidenceActionsResult, IIncidenceAction } from '../../interfaces/incidence/Action'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class IncidenceActions extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly incidence_id: string;

  protected dateFields: string[] = ["day"];
  protected dateTimeFields: string[] = ["hour"];

  constructor(incidence_id: string, host?: string) {
    super(host);
    this.incidence_id = incidence_id;
  }

  public async list(params: IQuery = {}): Promise<IIncidenceActionsResult> {
    return await this.request(GET, Routes.incidenceActionsPath(this.incidence_id), {}, params) as IIncidenceActionsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IIncidenceActionResult> {
    return await this.request(GET, Routes.incidenceActionPath(this.incidence_id, id), {}, params) as IIncidenceActionResult
  }

  public async create(params: JsonApiDocument): Promise<IIncidenceActionResult> {
    return await this.request(POST, Routes.incidenceActionsPath(this.incidence_id), {}, params) as IIncidenceActionResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IIncidenceActionResult> {
    return await this.request(PATCH, Routes.incidenceActionPath(this.incidence_id, id), {}, params) as IIncidenceActionResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.incidenceActionPath(this.incidence_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IIncidenceAction {
    return {
      id: null,
      type: "incidence_dates",
      attributes: {
        incidence_id: null,
        day: moment.utc(),
        hour: null,
        incidence_action_type_id: null,
        incidence_action_type_name: "",
        observations: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
