import qs from "qs";

import React, { useReducer, useMemo, Reducer, useEffect, useCallback } from "react";
import { useUpdateEffect } from "@umijs/hooks";
import { useHistory } from "react-router-dom";

import { useQuery } from "hooks/useRouter";
import { FormInstance } from "antd/lib/form";
import { IQueryList } from "api/interfaces/Query";
import { Store } from "antd/lib/form/interface";
import { TableProps } from "antd/lib/table";
import { filterObject } from "utils/helpers";

export interface useTableReturnType<RecordType> {
  search?: {
    reset: () => void,
    submit: (formValues: Store) => void
  },
  handlePagination: (pageNumber, pageSize) => void,
  tableProps: Partial<TableProps<RecordType>>,
  refresh: () => void;
  reload: () => void;
  error: string | null;
};

export interface UseTableOptionsType {
  form?: FormInstance;
  formResetCallback?: (form: FormInstance) => void;
  defaultPageSize?: number;
  defaultFilters?: { [name: string]: any };
  requiredFilters?: string[];
  requiredFiltersText?: string | React.ReactNode | (() => React.ReactNode);
}

class UseTableInitialState<RecordType> {
  // Current page
  current = 1;

  // Page size
  pageSize = 15;

  // Total items
  total = 0;

  // Array of records
  data: RecordType[] = [];

  // Form data
  searchQuery: { [name: string]: any } = {};

  // Counter
  requestCounter = 0;

  // Loading
  loading = true;

  // LoadingError
  error = null;

  requiredFilters = [];
  requiredFiltersText = null;
}

const reducer = <RecordType>(state: UseTableInitialState<RecordType>, action: { type: string; payload?: {} }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

export const useTable = <RecordType>(api: IQueryList, options: UseTableOptionsType = {}) : useTableReturnType<RecordType> => {
  const initialState = useMemo(() => new UseTableInitialState<RecordType>(), []);

  const history = useHistory();

  // No hay problema que se cree cada vez, porque el useState y useReducer
  // solo se inicializan la primera vez, por tanto no provoca redender
  const { defaultPageSize = 15, form, formResetCallback, defaultFilters = {}, requiredFilters = [], requiredFiltersText } = options;
  const { page = 1, items = defaultPageSize, filters } = useQuery();

  const [state, dispatch] = useReducer<Reducer<UseTableInitialState<RecordType>, any>>(reducer, {
    ...initialState,
    current: parseInt(page),
    pageSize: parseInt(items),
    searchQuery: { ...defaultFilters, ...filters },
    requiredFilters,
    requiredFiltersText
  });

  const requiredFiltersFilled = useMemo(() => {
    const currentFilters = Object.keys(filterObject(state.searchQuery));

    return state.requiredFilters.every((filter) => currentFilters.includes(filter));
  }, [state.searchQuery, state.requiredFilters]);

  const emptyText = useMemo(() => {
    if (requiredFiltersFilled) {
      return null;
    }

    return state.requiredFiltersText || "Selecciona els filtres per mostrar informació a la taula"
  }, [state.requiredFiltersText, requiredFiltersFilled])

  // Tienen que usar useCallback porque al devolverlas al principal
  // si no son la misma provocaria un re-render?
  const reload = useCallback(() => {
    dispatch({
      type: 'updateState',
      payload: {
        current: 1,
        searchQuery: {},
        requestCounter: state.requestCounter + 1,
      },
    });
  }, [state.requestCounter]);

  const refresh = useCallback(() => {
    dispatch({
      type: 'updateState',
      payload: { requestCounter: state.requestCounter + 1 },
    });
  }, [state.requestCounter]);

  const searchReset = useCallback(() => {
    if (!form) {
      return;
    }

    if (formResetCallback) {
      formResetCallback(form)
    } else {
      form.resetFields();
    }

    dispatch({
      type: 'updateState',
      payload: {
        current: 1,
        searchQuery: form.getFieldsValue()
      }
    });

    refresh();
  }, [dispatch, form, refresh, formResetCallback]);

  const searchSubmit = useCallback(
    (formValues: Store) : void => {
      if (!form) {
        return;
      }

      setTimeout(() => {
        dispatch({
          type: 'updateState',
          payload: {
            searchQuery: { ...state.searchQuery, ...formValues },
          },
        });

        refresh();
      });
    },
    [dispatch, form, refresh, state.searchQuery],
  );

  useEffect(() => {
    refresh();
    // This is OK, we only call this on first mount to fire the axios request
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    reload()
    // This is OK, we only call this if base URL changes
    // if we add reload, we have an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api])

  useUpdateEffect(() => {
    const fetchData = async () => {
      const result = await api.list({
        page: state.current,
        items: state.pageSize,
        filters: state.searchQuery
      });

      if (result.isSuccess()) {
        const { page, count, vars: { items }} = result.success().meta.pagy;

        const query = qs.stringify({ page, items, filters: state.searchQuery }, { encodeValuesOnly: true });
        history.push(`?${query}`);

        dispatch({
          type: 'updateState',
          payload: {
            data: result.success().data,
            current: page,
            total: count,
            pageSize: items,
            loading: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            data: [],
            current: 1,
            total: 0,
            pageSize: defaultPageSize,
            loading: false,
            error: result.fail().summary
          }
        })
      }
    };

    if (requiredFiltersFilled) {
      dispatch({
        type: 'updateState',
        payload: {
          loading: true
        }
      })

      fetchData();
    } else {
      dispatch({
        type: 'updateState',
        payload: {
          data: [],
          current: 1,
          total: 0,
          pageSize: defaultPageSize,
          loading: false,
          error: null
        }
      })
    }
  }, [state.current, state.pageSize, state.requestCounter, dispatch, history, defaultPageSize])

  const handlePagination = useCallback<useTableReturnType<RecordType>["handlePagination"]>(
    (pageNumber, pageSize) => {
      dispatch({
        type: 'updateState',
        payload: {
          current: pageNumber,
          pageSize: pageSize,
          count: state.requestCounter + 1,
        }
      })
    }, [state.requestCounter]);

  const handleTablePagination = useCallback<TableProps<RecordType>["onChange"]>(
    (pagination) => {
      handlePagination(pagination.current,pagination.pageSize)
    }, [handlePagination]);

  const result : useTableReturnType<RecordType> = {
    handlePagination,
    tableProps: {
      bordered: false,
      size: "small",
      dataSource: state.data,
      loading: state.loading,
      onChange: handleTablePagination,
      locale: {
        emptyText
      },
      pagination: {
        size: "small",
        current: state.current,
        pageSize: state.pageSize,
        total: state.total,
        hideOnSinglePage: false
      }
    },
    refresh,
    reload,
    error: state.error
  }

  if (form) {
    result.search = {
      submit: searchSubmit,
      reset: searchReset
    }
  }

  return result;
}
