import React from 'react';
import moment from "moment";
import { Select, Form, Row, Col, InputNumber } from "antd"
import { rowConfig, oneColumn } from 'utils/constants';

const RepeatWeekly = ({
  weekly: {
    interval,
    days,
    options,
  },
  handleChange,
}) => {
  // let daysArray = toPairs(days);
  // if (options.weekStartsOnSunday) {
  //   daysArray = daysArray.slice(-1).concat(daysArray.slice(0, -1));
  // }

  return (
    <>
      <Row {...rowConfig}>
        <Col {...oneColumn}>
          <Form.Item label="Cada quantes setmanes?">
            <InputNumber
              step={1}
              min={1}
              value={interval}
              onChange={(value) => handleChange("repeat.weekly.interval", value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row {...rowConfig}>
        <Col {...oneColumn}>
          <Form.Item label="Els dies">
            <Select
              mode="multiple"
              value={days}
              onChange={(values) => handleChange("repeat.weekly.days", values)}
            >
              {moment.weekdays(true).map((day, i) => <Select.Option key={i} value={i}>{day}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>


      {/* <div className="form-group row">
        <div className="btn-group btn-group-toggle offset-sm-2">
          {daysArray.map(([dayName, isDayActive]) => (
            <label
              htmlFor={`as-${dayName}`}
              key={dayName}
              className={`btn btn-primary ${isDayActive ? 'active' : ''}`}
            >
              <input
                type="checkbox"
                id={`as-${dayName}`}
                name={`repeat.weekly.days[${dayName}]`}
                className="form-control"
                checked={isDayActive}
                onChange={(event) => {
                  const editedEvent = {
                    ...event,
                    target: {
                      ...event.target,
                      value: !isDayActive,
                      name: event.target.name,
                    },
                  };

                  handleChange(editedEvent);
                }}
              />
              {dayName.toLowerCase()}
            </label>))
          }
        </div>
      </div> */}
    </>
  );
};

// RepeatWeekly.propTypes = {
//   id: PropTypes.string.isRequired,
//   weekly: PropTypes.shape({
//     interval: PropTypes.number.isRequired,
//     days: PropTypes.shape({
//       mon: PropTypes.bool.isRequired,
//       tue: PropTypes.bool.isRequired,
//       wed: PropTypes.bool.isRequired,
//       thu: PropTypes.bool.isRequired,
//       fri: PropTypes.bool.isRequired,
//       sat: PropTypes.bool.isRequired,
//       sun: PropTypes.bool.isRequired,
//     }).isRequired,
//     options: PropTypes.shape({
//       weekStartsOnSunday: PropTypes.bool,
//     }).isRequired,
//   }).isRequired,
//   handleChange: PropTypes.func.isRequired,
//   translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default RepeatWeekly;
