import React, {useMemo} from "react";
import {Form, Button, Table, Alert} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";
import {useStoreState} from "utils/store";
import { PlusOutlined  } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions, renderObservations, handleDestroy} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {IIncidenceParticipant} from "api/interfaces/incidence/Participant";
import ParticipantForm from "../forms/participant";
import {ModalFormChildProps, ModalFormWithForwardRef} from "../../../components/ModalFormApi";
import CreateParticipantForm from "../forms/create_participant";
import { FormInstance } from "antd/lib/form";

interface ParticipantsListProps {
  incidenceId: string
}

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    status: undefined
  })
}


const ParticipantsList: React.FC<ParticipantsListProps> = ({ incidenceId }) => {
  const [form] = Form.useForm();

  const apiEndpoint = React.useMemo(() => api.incidenceParticipants(incidenceId), [incidenceId]);

  const { tableProps, error, refresh, reload } = useInnerTable<IIncidenceParticipant>(apiEndpoint, { form, formResetCallback });

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const createFormOptions = React.useMemo(() : useDrawerFormOptionsType<IIncidenceParticipant> => {
    return {
      title: "Afegir individu / equip",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, drawerProps: createDrawerProps } = useDrawerForm<IIncidenceParticipant>(apiEndpoint, createFormOptions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IIncidenceParticipant> => {
    return {
      title: "Individu / Equip",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { edit, drawerProps } = useDrawerForm<IIncidenceParticipant>(apiEndpoint, formOptions);

  const destroyAction = useMemo(() => {
    return handleDestroy(apiEndpoint, refresh)
  }, [apiEndpoint, refresh]);

  const columns: ColumnType<IIncidenceParticipant>[] = [
    { title: "Qui", key: "who", dataIndex: ["attributes", "who"] },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, destroyAction)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'justify-between' }}>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["activities:create"]}>
          <Button icon={<PlusOutlined />} onClick={create} style={{ marginRight: "5px" }}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...createDrawerProps}>
        {({ form, readOnly }: ModalFormChildProps) => (
          <CreateParticipantForm form={form} readOnly={readOnly} />
        )}
      </ModalFormWithForwardRef>

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <ParticipantForm form={form} readOnly={readOnly}/>
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default ParticipantsList;
