import React from "react"
import { Form, Input, Radio, Select } from "antd"
import { FormInstance } from "antd/lib/form";
import { IDictionary } from "api/interfaces/Dictionary";
import { axiosConfig } from "utils/request";
import { filterOptionByLabel } from "utils/helpers";
import { useAxiosRequest } from "use-axios-request";
import { Routes } from "api/routes";

export interface ContactFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
}

const ambitsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("entity_ambits"), params: { items: "all" } };

const ContactForm: React.FC<ContactFormProps> = ({ readOnly = false }) => {
  const { data: ambitsData, isFetching: isFetchingAmbits } = useAxiosRequest<{ data: IDictionary[] }>(ambitsRequest);
  const { data: ambits = [] } = ambitsData || {};

  return (
    <>
      <Form.Item name={["attributes", "name"]} label="Nom" rules={rules.required}>
        <Input disabled={readOnly} placeholder="Nom" />
      </Form.Item>
      <Form.Item name={["attributes", "is_service"]} label="Tipus" rules={rules.required}>
        <Radio.Group disabled={readOnly}>
          <Radio value={false}>Entitat</Radio>
          <Radio value>Servei</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={["attributes", "ambit_ids"]} label="Àmbits">
        <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els ambits" loading={isFetchingAmbits} filterOption={filterOptionByLabel} showSearch>
          {ambits.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "phones"]} label="Telèfons">
        <Input disabled={readOnly} placeholder="Telèfons" />
      </Form.Item>
      <Form.Item name={["attributes", "email"]} label="Correu electrònic">
        <Input disabled={readOnly} placeholder="Correu electrònic" />
      </Form.Item>
    </>
  )
}

export default ContactForm;
