import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { ITeamResult, ITeamsResult, ITeam } from '../interfaces/Team'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";

export default class Teams extends Http implements IQueryList, IQueryEditable {
  public async list(params: IQuery = {}): Promise<ITeamsResult> {
    return await this.request(GET, Routes.teamsPath(), {}, params) as ITeamsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<ITeamResult> {
    return await this.request(GET, Routes.teamPath(id), {}, params) as ITeamResult
  }

  public async create(params: JsonApiDocument): Promise<ITeamResult> {
    return await this.request(POST, Routes.teamsPath(), {}, params) as ITeamResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ITeamResult> {
    return await this.request(PATCH, Routes.teamPath(id), {}, params) as ITeamResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.teamPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : ITeam {
    return {
      id: null,
      type: "teams",
      attributes: {
        name: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
