import React from "react"
import {DatePicker, Form, Input} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "utils/formats";


export interface ObservationFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const ObservationForm: React.FC<ObservationFormProps> = ({ readOnly = false }) => {
  return (
    <>
      <Form.Item name={["attributes", "starts_at"]} label="Inici" rules={rules.required}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "ends_at"]} label="Fi">
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "observation"]} label="Observacions" rules={rules.required}>
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default ObservationForm;
