import React, { useMemo } from "react";

import { Form, Table, Alert, Button, Input } from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { FormInstance } from "antd/lib/form";
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined } from "@ant-design/icons";

import {useRouter, useQuery} from "hooks/useRouter";
import { useTable } from "hooks/useTableApi";
import {IIndividual} from "api/interfaces/Individual";
import {handleDestroy, renderRecordActions, renderRecordDate} from "utils/helpers";
import { useStoreState } from "utils/store";
import api from "api";


const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    name: undefined
  })
}

const editURL = (id: string) => `/individuals/${id}`;

const IndividualsList: React.FC = () => {
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();
  const router = useRouter();
  const { filters: query } = useQuery();

  const { tableProps, error, reload, refresh, search } = useTable<IIndividual>(api.individuals, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const goToIndividual = (id: string) => router.push(editURL(id));
  const goToCreateIndividual = () => router.push(`/individuals/new`);

  const destroyAction = useMemo(() => {
    return handleDestroy(api.individuals, refresh)
  }, [refresh]);

  const columns: ColumnType<IIndividual>[] = [
    {
      title: 'Nom',
      key: "name",
      dataIndex: ["attributes", "full_name"]
    },
    { title: "Data de naixement", key: "birthdate", render: renderRecordDate("birthdate") },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToIndividual, destroyAction, { editURL })
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="name">
          <Input placeholder="Nom" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["individuals:create"]}>
          <Button icon={<PlusOutlined />} onClick={goToCreateIndividual}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );


  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper >
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />
    </PageHeaderWrapper>
  );
};

export default IndividualsList;
