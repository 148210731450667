import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { ITeamCompositionResult, ITeamCompositionsResult, ITeamComposition } from '../../interfaces/team/Composition'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class TeamCompositions extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly team_id: string;

  protected dateFields: string[] = ["date"];

  constructor(team_id: string, host?: string) {
    super(host);
    this.team_id = team_id;
  }

  public async list(params: IQuery = {}): Promise<ITeamCompositionsResult> {
    return await this.request(GET, Routes.teamCompositionsPath(this.team_id), {}, params) as ITeamCompositionsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<ITeamCompositionResult> {
    return await this.request(GET, Routes.teamCompositionPath(this.team_id, id), {}, params) as ITeamCompositionResult
  }

  public async create(params: JsonApiDocument): Promise<ITeamCompositionResult> {
    return await this.request(POST, Routes.teamCompositionsPath(this.team_id), {}, params) as ITeamCompositionResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ITeamCompositionResult> {
    return await this.request(PATCH, Routes.teamCompositionPath(this.team_id, id), {}, params) as ITeamCompositionResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.teamCompositionPath(this.team_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : ITeamComposition {
    return {
      id: null,
      type: "team_compositions",
      attributes: {
        date: moment.utc(),
        girls: 0,
        guys: 0,
        others: 0,
        adscription_type_id: undefined,
        adscription_type_name: "",
        age_id: undefined,
        age_name: "",
        cohesion_type_id: undefined,
        cohesion_type_name: "",
        community_relation_type_id: undefined,
        community_relation_type_name: "",
        residence_place_type_id: undefined,
        residence_place_type_name: "",
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
