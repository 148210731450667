import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import {IQuery, IQueryList, IQueryUploadable} from '../interfaces/Query'
import { IAttachmentResult, IAttachmentsResult, IAttachment } from '../interfaces/Attachment'
import {EmptyResponse, JsonApiDocument, JsonApiUpload} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";
import {Routes} from "../routes";

export default class Attachments extends Http implements IQueryList, IQueryUploadable {
  private readonly path: string;
  private readonly vaultId: string|number;

  constructor(path: string, vaultId: string|number, host?: string) {
    super(host);
    this.path = path;
    this.vaultId = vaultId;
  }

  public async list(params: IQuery = {}): Promise<IAttachmentsResult> {
    return await this.request(GET, Routes.attachmentsPath(this.path, this.vaultId), {}, params) as IAttachmentsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IAttachmentResult> {
    return await this.request(GET, Routes.attachmentPath(this.path, this.vaultId, id), {}, params) as IAttachmentResult
  }

  public async create(params: JsonApiDocument): Promise<IAttachmentResult> {
    return await this.request(POST, Routes.attachmentsPath(this.path, this.vaultId), {}, params) as IAttachmentResult
  }

  public async createUploads(params: JsonApiUpload): Promise<IAttachmentsResult> {
    return await this.request(POST, Routes.attachmentsPath(this.path, this.vaultId), {}, params) as IAttachmentsResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IAttachmentResult> {
    return await this.request(PATCH, Routes.attachmentPath(this.path, this.vaultId, id), {}, params) as IAttachmentResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.attachmentPath(this.path, this.vaultId, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance(): IAttachment {
    return {
      id: null,
      type: "Attachments",
      attributes: {
        name: "",
        updated_at: null,
        attachment_type_name: "",
        attachment_type_id: null,
        byte_size: 0,
        url: "",
        view_url: ""
      },
      meta: {
        permissions: {
          can_edit: true,
          can_show: true,
          can_destroy: true
        }
      }
    };
  }
};
