import moment from "moment";

const computeEndOnDate = (data, rruleObj) => {
  console.log("fromString", data, rruleObj);
  if (!rruleObj.until) {
    return data.end.onDate.date;
  }

  return moment(rruleObj.until);
};

export default computeEndOnDate;
