const computeWeeklyDays = (data, rruleObj) => {
  let weekdays = [];

  if (rruleObj.freq !== 2) {
    return data.repeat.weekly.days;
  }

  if (rruleObj.byweekday) {
    weekdays = rruleObj.byweekday.map(weekday => weekday.weekday);
  }

  return weekdays;
};

export default computeWeeklyDays;
