import React from "react"
import { Form, Input, Select } from "antd"
import { FormInstance } from "antd/lib/form";
import { axiosConfig } from "utils/request";
import { filterOptionByLabel } from "utils/helpers";
import { useAxiosRequest } from "use-axios-request";
import { Routes } from "api/routes";
import { IUser } from "api/interfaces/User";

export interface ContactFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
}

const usersRequest = { ...axiosConfig, baseURL: "/", url: Routes.usersPath(), params: { items: "all" } };

const ContactForm: React.FC<ContactFormProps> = ({ readOnly = false }) => {
  const { data: usersData, isFetching: isFetchingUsers } = useAxiosRequest<{ data: IUser[] }>(usersRequest);
  const { data: users = [] } = usersData || {};

  return (
    <>
      <Form.Item name={["attributes", "name"]} label="Nom" rules={rules.required}>
        <Input disabled={readOnly} placeholder="Nom" />
      </Form.Item>
      <Form.Item name={["attributes", "phones"]} label="Telèfons de contacte">
        <Input disabled={readOnly} placeholder="Telèfons" />
      </Form.Item>
      <Form.Item name={["attributes", "usual_schedule"]} label="Horaris habituals">
        <Input disabled={readOnly} placeholder="Horaris habituals" />
      </Form.Item>
      <Form.Item name={["attributes", "user_id"]} label="Usuari d'accès" >
        <Select disabled={readOnly} placeholder="Selecciona l'usuari" loading={isFetchingUsers} filterOption={filterOptionByLabel} showSearch allowClear>
          <Select.Option key="none" value="">Sense usuari</Select.Option>
          {users.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.full_name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "observations"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{minRows: 3}} />
      </Form.Item>
    </>
  )
}

export default ContactForm;
