import React, {useCallback, useEffect, useReducer} from "react";
import {PageLoading} from "@ant-design/pro-layout";
import { find } from "lodash";

import api from "api";
import LoadingError from "components/LoadingError";
import NotFound from "components/NotFound";
import {IActivityAttendancesResponse} from "api/interfaces/activity/Attendance";
import {Col, Row, Radio, Divider, message} from "antd";
import {rowConfig, threeColumns} from "utils/constants";

interface AttendancesProps {
  activityId: string
  sessionId: string
}

interface IState {
  data?: IActivityAttendancesResponse["data"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  data: null,
  isFetching: false,
  error: null,
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const findAttendance = (registration, attendances) => {
  const registrationId = parseInt(registration.id)
  const attendance = find(attendances.data, (a) => (a.attributes.activity_registration_id === registrationId));

  return attendance ? attendance.attributes.kind : "absence";
};

const Attendances: React.FC<AttendancesProps> = ({ activityId, sessionId }) => {
  const apiEndpoint = React.useMemo(() => api.activityAttendances(activityId, sessionId), [activityId, sessionId]);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const result = await apiEndpoint.list({});

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            data: result.success().data,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            data: null,
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    if (activityId && sessionId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true
        }
      });

      fetchData();
    }
  }, [apiEndpoint, activityId, sessionId, dispatch]);

  const onChange = useCallback((registration) => async (ev) => {
    const response = await apiEndpoint.save({
      activity_registration_id: registration.id,
      kind: ev.target.value
    });

    if (response.isFail()) {
      message.error(`No s'ha pogut guardar l'assistència de: ${registration.attributes.who}`, 10);
    }

  }, [apiEndpoint]);

  if (!activityId || !sessionId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }

  const {registrations = {data: []}, attendances = {data: []}} = state.data || {};

  return (
    <Row gutter={32}>
      {registrations.data.map((registration) => (
        <Col key={registration.id} {...threeColumns}>
          <Row {...rowConfig} wrap={false} align="middle">
            <Col flex="none">
              {registration.attributes.who}
            </Col>
            <Col flex="auto" style={{textAlign: "right"}}>
              <Radio.Group defaultValue={findAttendance(registration, attendances)} buttonStyle="solid" onChange={onChange(registration)} size="small">
                <Radio.Button className="attends" value="attends">Sí</Radio.Button>
                <Radio.Button className="justified_absence" value="justified_absence">Abs. just.</Radio.Button>
                <Radio.Button className="absence" value="absence">No</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          <Divider style={{margin: "12px 0"}} />
        </Col>
      ))}
    </Row>
  )
};

export default Attendances;
