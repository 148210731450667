import React, {useMemo} from "react";
import {Form, Row, Col, Input, Button, DatePicker, Select, Collapse, TimePicker, Radio} from "antd";
import {rowConfig, fourColumns, threeColumns, sixColumns, oneColumn, eightColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {IActivitySession} from "api/interfaces/activity/Session";
import {IQueryEditable} from "api/interfaces/Query";
import {dateFormats, timeFormats} from "utils/formats";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { useAxiosRequest } from "use-axios-request";
import { IDictionary } from "api/interfaces/Dictionary";
import { filterOptionByLabel } from "utils/helpers";
import {ITallerista} from "api/interfaces/Tallerista";
import {IActivityGoalRelation} from "api/interfaces/activity/GoalRelation";


interface ActivitySessionFormProps {
  api: IQueryEditable,
  record: IActivitySession,
  handleCreated?: (item: IActivitySession | IActivitySession[]) => void,
  handleUpdated?: (item: IActivitySession) => void,
}

class InnerForm extends PageForm<IActivitySession> {}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const materialsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("materials"), params: { items: "all" } };
const spacesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("spaces"), params: { items: "all" } };
const talleristesRequest = { ...axiosConfig, baseURL: "/", url: Routes.talleristesPath(), params: { items: "all" } };

const ActivitySessionForm: React.FC<ActivitySessionFormProps> = ({ api, record, handleCreated = () => void 0, handleUpdated = () => void 0 }) => {
  const [form] = Form.useForm();

  const activityGoalsRequest = useMemo(() => (
    { ...axiosConfig, baseURL: "/", url: Routes.activityGoalsPath(record.attributes.activity_id), params: { items: "all" } }
  ), [record.attributes.activity_id]);

  const { data: activityGoalsData, isFetching: isFetchingActivityGoals } = useAxiosRequest<{ data: IActivityGoalRelation[] }>(activityGoalsRequest);
  const { data: activityGoals = [] } = activityGoalsData || {};

  const { data: materialsData, isFetching: isFetchingMaterials } = useAxiosRequest<{ data: IDictionary[] }>(materialsRequest);
  const { data: materials = [] } = materialsData || {};

  const { data: spacesData, isFetching: isFetchingSpaces } = useAxiosRequest<{ data: IDictionary[] }>(spacesRequest);
  const { data: spaces = [] } = spacesData || {};

  const { data: talleristesData, isFetching: isFetchingTalleristes } = useAxiosRequest<{ data: ITallerista[] }>(talleristesRequest);
  const { data: talleristes = [] } = talleristesData || {};

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleUpdated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
           <Collapse bordered={false} className="mb-20" defaultActiveKey={[1]}>
             <Collapse.Panel header="Sessió" key="1">
               <Row {...rowConfig}>
                 <Col {...eightColumns}>
                   <Form.Item name={["attributes", "session_date"]} label="Data" rules={rules.required}>
                     <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
                   </Form.Item>
                 </Col>
                 <Col {...eightColumns}>
                   <Form.Item name={["attributes", "starts_at"]} label="Hora d'inici" rules={rules.required}>
                     <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
                   </Form.Item>
                 </Col>
                 <Col {...eightColumns}>
                   <Form.Item name={["attributes", "ends_at"]} label="Hora fi" rules={rules.required}>
                     <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
                   </Form.Item>
                 </Col>
                 <Col {...fourColumns}>
                   <Form.Item name={["attributes", "space_id"]} label="Espai" rules={rules.required}>
                     <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingSpaces} filterOption={filterOptionByLabel} showSearch>
                       {spaces.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                     </Select>
                   </Form.Item>
                 </Col>
                 <Col {...eightColumns}>
                   <Form.Item name={["attributes", "canceled"]} label="Cancel·lada" rules={rules.required}>
                     <Select disabled={readOnly} placeholder="Selecciona un element">
                       <Select.Option key="No" value={false}>No</Select.Option>
                       <Select.Option key="Sí" value={true}>Sí</Select.Option>
                     </Select>
                   </Form.Item>
                 </Col>
               </Row>
               <Row {...rowConfig}>
                 <Col {...oneColumn}>
                   <Form.Item name={["attributes", "tallerista_ids"]} label="Talleristes" rules={rules.required}>
                     <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingTalleristes} filterOption={filterOptionByLabel} showSearch>
                       {talleristes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                     </Select>
                   </Form.Item>
                 </Col>
              </Row>
               <Row {...rowConfig}>
                 <Col {...oneColumn}>
                   <Form.Item name={["attributes", "material_ids"]} label="Materials">
                     <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingMaterials} filterOption={filterOptionByLabel} showSearch>
                       {materials.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                     </Select>
                   </Form.Item>
                 </Col>
                </Row>
               <Row {...rowConfig}>
                 <Col {...oneColumn}>
                   <Form.Item name={["attributes", "activities_goal_ids"]} label="Objectius">
                     <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingActivityGoals} filterOption={filterOptionByLabel} showSearch>
                       {activityGoals.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.activity_goal.name}</Select.Option>)}
                     </Select>
                   </Form.Item>
                 </Col>
               </Row>
             </Collapse.Panel>
             <Collapse.Panel header="Fitxa de la sessió" key="2">
               <Row {...rowConfig}>
                 <Col {...threeColumns}>
                   <Form.Item name={["attributes", "activity_session_report_attributes", "initial_description"]} label="Descripció part inicial">
                     <Input.TextArea disabled={readOnly} placeholder="" autoSize={{ minRows: 4 }} />
                   </Form.Item>
                 </Col>
                 <Col {...threeColumns}>
                   <Form.Item name={["attributes", "activity_session_report_attributes", "main_description"]} label="Descripció part principal">
                     <Input.TextArea disabled={readOnly} placeholder="" autoSize={{ minRows: 4 }} />
                   </Form.Item>
                 </Col>
                 <Col {...threeColumns}>
                   <Form.Item name={["attributes", "activity_session_report_attributes", "final_description"]} label="Descripció tornada a la calma">
                     <Input.TextArea disabled={readOnly} placeholder="" autoSize={{ minRows: 4 }} />
                   </Form.Item>
                 </Col>
               </Row>
               <Row {...rowConfig}>
                 <Col {...threeColumns}>
                   <Form.Item name={["attributes", "activity_session_report_attributes", "considerations"]} label="Consideracions a tenir en compte">
                     <Input.TextArea disabled={readOnly} placeholder="" autoSize={{ minRows: 4 }} />
                   </Form.Item>
                 </Col>
                 <Col {...threeColumns}>
                   <Form.Item name={["attributes", "activity_session_report_attributes", "adaptations_to_diversity"]} label="Adaptacions a la diversitat">
                     <Input.TextArea disabled={readOnly} placeholder="" autoSize={{ minRows: 4 }} />
                   </Form.Item>
                 </Col>
                 <Col {...threeColumns}>
                   <Form.Item name={["attributes", "activity_session_report_attributes", "feedback"]} label="Feedback dels alumnes">
                     <Input.TextArea disabled={readOnly} placeholder="" autoSize={{ minRows: 4 }} />
                   </Form.Item>
                 </Col>
               </Row>
             </Collapse.Panel>
             <Collapse.Panel header="Observacions" key="3">
               <Form.Item name={["attributes", "observations"]}>
                 <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
               </Form.Item>
             </Collapse.Panel>
           </Collapse>
           <Row {...rowConfig} justify="center">
             <Form.Item style={{marginBottom: 0}}>
               <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
             </Form.Item>
           </Row>
        </>
      )}
    </InnerForm>
  );
};

export default ActivitySessionForm;
