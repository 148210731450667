import React from "react";
import { Form, Input } from "antd";
import { FormInstance } from "antd/lib/form";

interface CreateServeiFormProps {
  form: FormInstance;
}

const CreateTeamForm: React.FC<CreateServeiFormProps> = ({ form }) => {
  return (
    <>
      <Form.Item name={["attributes", "name"]} label="Nom">
        <Input type="text" />
      </Form.Item>
    </>
  );
};

export default CreateTeamForm;
