import React, { useState, useEffect } from 'react';
import { PageLoading } from '@ant-design/pro-layout';
import { useStoreActions, useStoreState } from 'utils/store';
import { Redirect, RouteProps } from 'react-router-dom';
import Unauthorized from 'components/Unauthorized';

const SecurityLayout: React.FC<RouteProps> = (props) : React.ReactElement => {
  const { children } = props;

  const [isReady, setReady] = useState<Boolean>(false);

  const loading = useStoreState(state => state.app.loading);
  const isLoggedIn = useStoreState(state => state.app.isLoggedIn);
  const hasPermissions = useStoreState(state => state.app.hasPermissions);

  const fetchCurrentUser = useStoreActions(actions => actions.app.fetchCurrent);

  useEffect(() => {
    fetchCurrentUser();
    setReady(true);
  }, [fetchCurrentUser]);

  if ((!isLoggedIn && loading) || (!hasPermissions && loading) || !isReady) {
    return <PageLoading />;
  }

  if (!isLoggedIn) {
    const searchParams = new URLSearchParams();
    searchParams.set('redirect', window.location.href);

    return <Redirect to={`/auth/login?${searchParams.toString()}}`}></Redirect>;
  }

  if (!hasPermissions) {
    return <Unauthorized />
  }

  return <>{children}</>;
};

export default SecurityLayout;
