import React, {useEffect, useReducer} from "react";
import NotFound from "components/NotFound";
import QueryForm from "./form";
import { PageHeaderWrapper, PageLoading } from "@ant-design/pro-layout";
import { useRouter } from "hooks/useRouter";
import LoadingError from "components/LoadingError";
import api from "../../api";
import {IQuery} from "../../api/interfaces/Query";


interface IState {
  item?: IQuery
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: null,
  isFetching: false,
  error: null
}

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const QueryEditPage: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { query: { queryId } } = useRouter();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.queries.show(queryId);

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    dispatch({
      type: 'updateState',
      payload: {
        isFetching: true
      }
    })

    fetchData();
  }, [queryId, dispatch])

  if (!queryId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }

  return (
    <PageHeaderWrapper title="Editar consulta">
      <QueryForm api={api.queries} record={state.item}/>
    </PageHeaderWrapper>
  );
};

export default QueryEditPage;
