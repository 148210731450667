import React from 'react';
import RepeatMonthlyOn from './On';
import RepeatMonthlyOnThe from './OnThe';
import { Row, Col, Form, InputNumber } from 'antd';
import { rowConfig, twoColumns } from 'utils/constants';

const RepeatMonthly = ({
  monthly: {
    mode,
    interval,
    on,
    onThe,
    options,
  },
  handleChange,
}) => {
  const isTheOnlyOneMode = option => options.modes === option;
  const isOptionAvailable = option => !options.modes || isTheOnlyOneMode(option);

  return (
    <>


      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item label="Cada quants mesos?">
            <InputNumber
              step={1}
              min={1}
              value={interval}
              onChange={(value) => handleChange("repeat.monthly.interval", value)}
            />
          </Form.Item>
        </Col>


      {isOptionAvailable('on') && (
        <Col {...twoColumns}>
          <RepeatMonthlyOn
            mode={mode}
            on={on}
            hasMoreModes={!isTheOnlyOneMode('on')}
            handleChange={handleChange}
          />
        </Col>
      )}
      {isOptionAvailable('on the') && (
        <RepeatMonthlyOnThe
          mode={mode}
          onThe={onThe}
          hasMoreModes={!isTheOnlyOneMode('on the')}
          handleChange={handleChange}
        />
      )}
      </Row>

    </>
  );
};

// RepeatMonthly.propTypes = {
//   id: PropTypes.string.isRequired,
//   monthly: PropTypes.shape({
//     mode: PropTypes.oneOf(['on', 'on the']).isRequired,
//     interval: PropTypes.number.isRequired,
//     on: PropTypes.object.isRequired,
//     onThe: PropTypes.object.isRequired,
//     options: PropTypes.shape({
//       modes: PropTypes.oneOf(['on', 'on the']),
//     }).isRequired,
//   }).isRequired,
//   handleChange: PropTypes.func.isRequired,
//   translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default RepeatMonthly;
