import React, { useMemo } from "react";

import { Form, Table, Alert, Button, Input } from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { FormInstance } from "antd/lib/form";
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined } from "@ant-design/icons";

import { ModalFormChildProps, ModalFormWithForwardRef } from "components/ModalFormApi";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerFormApi";
import {useRouter, useQuery} from "hooks/useRouter";
import { useTable } from "hooks/useTableApi";
import {ITeam} from "api/interfaces/Team";
import {handleDestroy, renderRecordActions} from "utils/helpers";
import { useStoreState } from "utils/store";
import api from "api";
import CreateTeamForm from "./create";


const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    name: undefined
  })
}

const editURL = (id: string) => `/teams/${id}`;

const TeamsList: React.FC = () => {
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();
  const router = useRouter();
  const { filters: query } = useQuery();

  const { tableProps, error, refresh, reload, search } = useTable<ITeam>(api.teams, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const goToTeam = (id: string) => router.push(editURL(id));

  const destroyAction = useMemo(() => {
    return handleDestroy(api.teams, refresh)
  }, [refresh]);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<ITeam> => {
    return {
      title: "Nou equip / grup",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.teams.newInstance()
    }
  }, [refresh])

  const { create, drawerProps } = useDrawerForm<ITeam>(api.teams, formOptions);

  const columns: ColumnType<ITeam>[] = [
    {
      title: 'Nom',
      key: "name",
      dataIndex: ["attributes", "name"]
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToTeam, destroyAction, { editURL })
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="name">
          <Input placeholder="Nom" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["talleristes:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );


  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper >
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...drawerProps}>
        {({ form }: ModalFormChildProps) => (
          <CreateTeamForm form={form} />
        )}
      </ModalFormWithForwardRef>
    </PageHeaderWrapper>
  );
};

export default TeamsList;
