import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IIncidenceParticipantResult, IIncidenceParticipantsResult, IIncidenceParticipant } from '../../interfaces/incidence/Participant'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";

export default class IncidenceParticipants extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly incidence_id: string;

  protected dateFields: string[] = ["cancelled_at", "accepted_at", "requested_at"];

  constructor(incidence_id: string, host?: string) {
    super(host);
    this.incidence_id = incidence_id;
  }

  public async list(params: IQuery = {}): Promise<IIncidenceParticipantsResult> {
    return await this.request(GET, Routes.incidenceParticipantsPath(this.incidence_id), {}, params) as IIncidenceParticipantsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IIncidenceParticipantResult> {
    return await this.request(GET, Routes.incidenceParticipantPath(this.incidence_id, id), {}, params) as IIncidenceParticipantResult
  }

  public async create(params: JsonApiDocument): Promise<IIncidenceParticipantResult> {
    return await this.request(POST, Routes.incidenceParticipantsPath(this.incidence_id), {}, params) as IIncidenceParticipantResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IIncidenceParticipantResult> {
    return await this.request(PATCH, Routes.incidenceParticipantPath(this.incidence_id, id), {}, params) as IIncidenceParticipantResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.incidenceParticipantPath(this.incidence_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IIncidenceParticipant {
    return {
      id: null,
      type: "incidence_registrations",
      attributes: {
        observations: "",
        who: "",
        participant_id: null,
        incidence_id: null
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
