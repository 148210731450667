import React from "react"
import {Col, Form, Row, Select, DatePicker, TimePicker, Input} from "antd"
import { FormInstance } from "antd/lib/form";
import {oneColumn, repeatPeriod, rowConfig, twoColumns, weekdays} from "utils/constants";
import {filterOptionByLabel} from "utils/helpers";
import {dateFormats, timeFormats} from "utils/formats";
import { Routes } from "api/routes";
import { useAxiosRequest } from "use-axios-request";
import { IDictionary } from "api/interfaces/Dictionary";
import { axiosConfig } from "utils/request";


export interface PresenceFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const spacesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("spaces"), params: { items: "all" } };

const CreateMultipleDatesForms: React.FC<PresenceFormProps> = ({ form, readOnly = false }) => {
  const { data: spacesData, isFetching: isFetchingSpaces } = useAxiosRequest<{ data: IDictionary[] }>(spacesRequest);
  const { data: spaces = [] } = spacesData || {};

  return (
    <>
      <Form.Item name="multiple" initialValue="true" style={{display: "none"}}>
        <Input type="hidden" value="true" />
      </Form.Item>
      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name="start_date" label="Data d'inici" rules={rules.required}>
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name="end_date" label="Data fi" rules={rules.required}>
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "starts_at"]} label="Hora d'inici" rules={rules.required}>
            <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "ends_at"]} label="Hora fi" rules={rules.required}>
            <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig}>
        <Col {...oneColumn}>
          <Form.Item name={["attributes", "space_id"]} label="Espai" rules={rules.required}>
            <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingSpaces} filterOption={filterOptionByLabel} showSearch>
              {spaces.map((item) => (
                <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig}>
        <Col {...oneColumn}>
          <Form.Item name="weekdays" label="Dies de la setmana" rules={rules.required}>
            <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els dies de la setmana" filterOption={filterOptionByLabel} showSearch>
              {weekdays.map((item) => (
                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig}>
        <Col {...oneColumn}>
          <Form.Item name="period" label="Periodicitat" rules={rules.required}>
            <Select disabled={readOnly} placeholder="Selecciona la periodicitat" filterOption={filterOptionByLabel} showSearch>
              {repeatPeriod.map((item) => (
                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig}>
        <Col {...oneColumn}>
            <Form.Item name={["attributes", "observations"]}>
              <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
            </Form.Item>
          </Col>
      </Row>
    </>
  )
};

export default CreateMultipleDatesForms;
