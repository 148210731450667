export const basePath = `api`

export const Routes = {
  activitiesPath: () => `${basePath}/activities`,
  activityPath: (id: string) => `${basePath}/activities/${id}`,
  activityAttendancesPath: (activity_id: string, session_id: string) => `${basePath}/activities/${activity_id}/sessions/${session_id}/attendances`,
  activityGoalsPath: (activity_id: string|number) => `${basePath}/activities/${activity_id}/goals`,
  activityRegistrationsPath: (activity_id: string) => `${basePath}/activities/${activity_id}/registrations`,
  activityRegistrationPath: (activity_id: string, id: string) => `${basePath}/activities/${activity_id}/registrations/${id}`,
  activitySessionsPath: (activity_id: string) => `${basePath}/activities/${activity_id}/sessions`,
  activitySessionPath: (activity_id: string, id: string) => `${basePath}/activities/${activity_id}/sessions/${id}`,
  applicationMessagesPath: () => `${basePath}/application_messages`,
  applicationMessagePath: (id: string) => `${basePath}/application_messages/${id}`,
  attachmentsPath: (base: string, vaultId: string|number) => `${basePath}/${base}/vaults/${vaultId}/uploads`,
  attachmentPath: (base: string, vaultId: string|number, id: string) => `${basePath}/${base}/vaults/${vaultId}/uploads/${id}`,
  attachmentCollectionsPath: (base: string, vaultId: string|number) => `${basePath}/${base}/vaults/${vaultId}/vaults`,
  attachmentCollectionPath: (base: string, vaultId: string|number, id: string) => `${basePath}/${base}/vaults/${vaultId}/vaults/${id}`,
  calendarTasksPath: () => `${basePath}/calendar/tasks`,
  calendarTaskPath: (id: string) => `${basePath}/calendar/tasks/${id}`,
  cessionsPath: () => `${basePath}/cessions`,
  cessionPath: (id: string) => `${basePath}/cessions/${id}`,
  cessionDatesPath: (cession_id: string) => `${basePath}/cessions/${cession_id}/dates`,
  cessionDatePath: (cession_id: string, id: string) => `${basePath}/cessions/${cession_id}/dates/${id}`,
  cessionEntitiesPath: (cession_id: string) => `${basePath}/cessions/${cession_id}/entities`,
  cessionEntityPath: (cession_id: string, id: string) => `${basePath}/cessions/${cession_id}/entities/${id}`,
  cessionIndividualsPath: (cession_id: string) => `${basePath}/cessions/${cession_id}/individuals`,
  cessionIndividualPath: (cession_id: string, id: string) => `${basePath}/cessions/${cession_id}/individuals/${id}`,
  cessionTeamsPath: (cession_id: string) => `${basePath}/cessions/${cession_id}/teams`,
  cessionTeamPath: (cession_id: string, id: string) => `${basePath}/cessions/${cession_id}/teams/${id}`,
  dictionariesPath: (name: string) => `${basePath}/dictionaries/${name}`,
  dictionaryPath: (name: string, id: string) => `${basePath}/dictionaries/${name}/${id}`,
  entitiesPath: () => `${basePath}/entities`,
  entityPath: (id: string) => `${basePath}/entities/${id}`,
  incidencesPath: () => `${basePath}/incidences`,
  incidencePath: (id: string) => `${basePath}/incidences/${id}`,
  incidenceActionsPath: (incidence_id: string) => `${basePath}/incidences/${incidence_id}/actions`,
  incidenceActionPath: (incidence_id: string, id: string) => `${basePath}/incidences/${incidence_id}/actions/${id}`,
  incidenceParticipantsPath: (incidence_id: string) => `${basePath}/incidences/${incidence_id}/participants`,
  incidenceParticipantPath: (incidence_id: string, id: string) => `${basePath}/incidences/${incidence_id}/participants/${id}`,
  individualsPath: () => `${basePath}/individuals`,
  individualPath: (id: string) => `${basePath}/individuals/${id}`,
  individualPresencesPath: (individual_id: string) => `${basePath}/individuals/${individual_id}/presences`,
  individualPresencePath: (individual_id: string, id: string) => `${basePath}/individuals/${individual_id}/presences/${id}`,
  individualDemandNeedsPath: (individual_id: string) => `${basePath}/individuals/${individual_id}/demands_needs`,
  individualDemandNeedPath: (individual_id: string, id: string) => `${basePath}/individuals/${individual_id}/demands_needs/${id}`,
  individualDemandNeedAnswersPath: (individual_id: string) => `${basePath}/individuals/${individual_id}/demands_needs_answers`,
  individualDemandNeedAnswerPath: (individual_id: string, id: string) => `${basePath}/individuals/${individual_id}/demands_needs_answers/${id}`,
  individualRegistrationsPath: (individual_id: string) => `${basePath}/individuals/${individual_id}/registrations`,
  individualRegistrationPath: (individual_id: string, id: string) => `${basePath}/individuals/${individual_id}/registrations/${id}`,
  individualTeamsPath: (individual_id: string) => `${basePath}/individuals/${individual_id}/teams`,
  individualTeamPath: (individual_id: string, id: string) => `${basePath}/individuals/${individual_id}/teams/${id}`,
  spaceStatsPath: () => `${basePath}/space_stats`,
  spaceStatPath: (id: string) => `${basePath}/space_stats/${id}`,
  talleristesPath: () => `${basePath}/talleristes`,
  talleristaPath: (id: string) => `${basePath}/talleristes/${id}`,
  teamsPath: () => `${basePath}/teams`,
  teamPath: (id: string) => `${basePath}/teams/${id}`,
  teamCompositionsPath: (team_id: string) => `${basePath}/teams/${team_id}/compositions`,
  teamCompositionPath: (team_id: string, id: string) => `${basePath}/teams/${team_id}/compositions/${id}`,
  teamIndividualsPath: (team_id: string) => `${basePath}/teams/${team_id}/individuals`,
  teamIndividualPath: (team_id: string, id: string) => `${basePath}/teams/${team_id}/individuals/${id}`,
  teamMisbehavioursPath: (team_id: string) => `${basePath}/teams/${team_id}/misbehaviours`,
  teamMisbehaviourPath: (team_id: string, id: string) => `${basePath}/teams/${team_id}/misbehaviours/${id}`,
  teamObservationsPath: (team_id: string) => `${basePath}/teams/${team_id}/observations`,
  teamObservationPath: (team_id: string, id: string) => `${basePath}/teams/${team_id}/observations/${id}`,
  teamPresencesPath: (team_id: string) => `${basePath}/teams/${team_id}/presences`,
  teamPresencePath: (team_id: string, id: string) => `${basePath}/teams/${team_id}/presences/${id}`,
  teamRegistrationsPath: (team_id: string) => `${basePath}/teams/${team_id}/registrations`,
  teamRegistrationPath: (team_id: string, id: string) => `${basePath}/teams/${team_id}/registrations/${id}`,
  usersPath: () => `${basePath}/users`,
  userPath: (id: string) => `${basePath}/users/${id}`,
};
