import React, {useReducer} from "react";
import QueryForm from "./form";
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import { useRouter } from "hooks/useRouter";
import api from "../../api";
import LoadingError from "components/LoadingError";
import {JsonApiDocument} from "api/interfaces/JsonApi";
import { IQuery } from "../../api/interfaces/Query";

export interface IState {
  item?: IQuery
  included: JsonApiDocument["included"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: api.queries.newInstance(),
  included: [],
  isFetching: false,
  error: null
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const QueryEditPage: React.FC = () => {
  const router = useRouter();
  const goToQuery = (item: IQuery) => router.push(`/indicators/${item.id}`);

  const [state] = useReducer(reducer, initialState);

  if (!state.item) { return <LoadingError /> }

  return (
    <PageHeaderWrapper title="Crear indicador">
      <QueryForm api={api.queries} record={state.item} handleCreated={goToQuery} />
    </PageHeaderWrapper>
  );
};

export default QueryEditPage;
