import React from "react";
import { HeaderViewProps } from "@ant-design/pro-layout/lib/Header";

import Avatar from "./AvatarDropdown";
import styles from "./index.module.less";

const GlobalHeaderRight: React.FC<HeaderViewProps> = (props) => {
  return (
    <div className={styles.right}>
      <Avatar />
    </div>
  );
};

export default GlobalHeaderRight;
