import React from "react"
import {Col, DatePicker, Form, Input, Row} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "../../../utils/formats";
import {oneColumn, rowConfig, twoColumns} from "../../../utils/constants";


export interface PresenceFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const IndividualForm: React.FC<PresenceFormProps> = ({ form, readOnly = false }) => {
  return (
    <>
      <h3 className="text-center">
        { form.getFieldValue(["attributes", "individual_name"]) } / { form.getFieldValue(["attributes", "team_name"]) }
      </h3>
      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "from"]} label="Des de">
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "to"]} label="Fins">
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...oneColumn}>
          <Form.Item name={["attributes", "observations"]} label="Observacions">
            <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
};

export default IndividualForm;
