import { createStore, createTypedHooks } from "easy-peasy";
import app, { AppModel } from "models/app";

// The interface representing our entire store model
export interface StoreModel {
  app: AppModel;
}

// Provide our model to the helper      👇
const typedHooks = createTypedHooks<StoreModel>();

// 👇 export the typed hooks
export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export const storeModel: StoreModel = {
  app,
}

const store = createStore(storeModel);

export default store;
