import React, {useMemo} from "react";
import {Button, Table, Alert} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {
  renderRecordActions,
  renderRecordDate,
  renderObservations,
  renderBooleanColumn,
  handleDestroy
} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {ITeamMisbehaviour} from "api/interfaces/team/Misbehaviour";
import MisbehaviourForm from "../forms/misbehaviour";

interface MisbehavioursListProps {
  teamId: string
}

const MisbehavioursList: React.FC<MisbehavioursListProps> = ({ teamId }) => {
  const apiEndpoint = React.useMemo(() => api.teamMisbehaviours(teamId), [teamId]);

  const { tableProps, error, refresh, reload } = useInnerTable<ITeamMisbehaviour>(apiEndpoint);

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<ITeamMisbehaviour> => {
    return {
      title: "Conducta",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<ITeamMisbehaviour>(apiEndpoint, formOptions);

  const destroyAction = useMemo(() => {
    return handleDestroy(apiEndpoint, refresh)
  }, [apiEndpoint, refresh]);

  const columns: ColumnType<ITeamMisbehaviour>[] = [
    { title: "Dia", key: "date", render: renderRecordDate("date") },
    { title: "Actes incívics", align: "center", key: "incivive_behaviors", render: renderBooleanColumn("incivive_behaviors") },
    { title: "Apostes", align: "center", key: "bets", render: renderBooleanColumn("bets") },
    { title: "Alcohol", align: "center", key: "alcohol", render: renderBooleanColumn("alcohol") },
    { title: "Tòxics", align: "center", key: "toxics", render: renderBooleanColumn("toxics") },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, destroyAction)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["teams:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <MisbehaviourForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default MisbehavioursList;
