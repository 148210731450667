import React, {useCallback, useMemo, useReducer} from "react";
import axios from "axios";
import moment from "moment";
import { DateTime } from "luxon";

import {DatePicker, Form, Input, Select, Row, Col, Modal} from "antd";
import {useUpdateEffect} from "@umijs/hooks";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import api from "api";
import {DrawerFormWithForwardRef, DrawerFormChildProps} from "components/DrawerFormApi";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {axiosConfig} from "utils/request";
import {dateFormats, dateTimeFormats} from "utils/formats";
import {FormInstance} from "antd/lib/form";
import {useAxiosRequest} from "use-axios-request";
import {filterOptionByLabel} from "utils/helpers";
import {ICalendarEvent} from "api/interfaces/calendar/Event";

import "./Home.less";
import {IUser} from "../api/interfaces/User";
import {ICalendarCategory} from "../api/interfaces/calendar/Category";
import DateAndRecurrenceInput from "packages/progess-calendar/components/DateAndRecurrenceInput"
import { rowConfig, twoColumns } from "utils/constants";
import { IActivitySession } from "api/interfaces/activity/Session";
import { ICessionDate } from "api/interfaces/cession/Date";
import { Routes } from "api/routes";
import { IDictionary } from "api/interfaces/Dictionary";
import { find } from "lodash";


export interface IState {
  isFetching: boolean
  error: string,
  startDate: moment.Moment,
  endDate: moment.Moment,
  events: any[]
  // Counter
  requestCounter: number
  spaceFilter: string
  showSpaceFilter: boolean
}

const initialState: IState = {
  isFetching: false,
  error: null,
  startDate: moment.utc(),
  endDate: moment.utc(),
  events: [],
  requestCounter: 0,
  spaceFilter: null,
  showSpaceFilter: false
};

export interface EventFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const categoriesRequest = { ...axiosConfig, url: "calendar/categories", params: { items: "all" } };
const spacesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("spaces"), params: { items: "all" } };
const usersRequest = { ...axiosConfig, url: "users", params: { items: "all" } };

const EventForm: React.FC<EventFormProps> = ({ readOnly = false }) => {
  const { data: categoriesData, isFetching: isFetchingCategories } = useAxiosRequest<{ data: ICalendarCategory[] }>(categoriesRequest);
  const { data: categories = [] } = categoriesData || {};

  const { data: usersData, isFetching: isFetchingUsers } = useAxiosRequest<{ data: IUser[] }>(usersRequest);
  const { data: users = [] } = usersData || {};

  return (
    <>
      <Form.Item name={["attributes", "name"]} label="Nom" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
        <Input placeholder="Nom del event" />
      </Form.Item>
      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "starts_at"]} label="Inici" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
            <DatePicker className="w100" disabled={readOnly} showTime minuteStep={15} format={dateTimeFormats.display} placeholder="Desde" />
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "ends_at"]} label="Fi" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
            <DatePicker className="w100" disabled={readOnly} showTime minuteStep={15} format={dateTimeFormats.display} placeholder="Fins" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={["attributes", "recurrence"]} style={{marginBottom: 0}}>
        <DateAndRecurrenceInput />
      </Form.Item>
      <Form.Item name={["attributes", "category_id"]} label="Tipus" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
        <Select disabled={readOnly} placeholder="Selecciona la categoría" loading={isFetchingCategories} filterOption={filterOptionByLabel} showSearch>
          {categories.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "user_id"]} label="Responsable" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
        <Select disabled={readOnly} placeholder="Selecciona el responsable" loading={isFetchingUsers} filterOption={filterOptionByLabel} showSearch>
          {users.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.full_name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "description"]} label="Descripció">
        <Input.TextArea disabled={readOnly} placeholder="Descripció" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
}

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const buttonsText = {
  "today": "Avui",
  "month": "Mes",
  'week': "Setmana",
  'day': "Dia"
}

const Home: React.FC = () => {
  const [formFilter] = Form.useForm();

  const { data: spacesData, isFetching: isFetchingSpaces } = useAxiosRequest<{ data: IDictionary[] }>(spacesRequest);
  const { data: spaces = [] } = spacesData || {};

  const [state, dispatch] = useReducer(reducer, initialState);

  const endpoint = useMemo(() => 'calendar/tasks', [])

  useUpdateEffect(() => {
    const fetchData = async () => {
      try {
        const tasksRequest = axios.get(endpoint, {
          ...axiosConfig,
          params: {
            from: state.startDate.format(dateFormats.server),
            to: state.endDate.format(dateFormats.server)
          }
        });

        const sessionsRequest = axios.get("calendar/activity_sessions", {
          ...axiosConfig,
          params: {
            from: state.startDate.format(dateFormats.server),
            to: state.endDate.format(dateFormats.server),
            space: state.spaceFilter
          }
        });

        const cessionsRequest = axios.get("calendar/cession_dates", {
          ...axiosConfig,
          params: {
            from: state.startDate.format(dateFormats.server),
            to: state.endDate.format(dateFormats.server),
            space: state.spaceFilter
          }
        });

        const events = []
        const [tasks, sessions, cessions] = await Promise.all([tasksRequest, sessionsRequest, cessionsRequest])

        events.push(...tasks.data.data.flatMap((event: ICalendarEvent) => {
          if (event.attributes.recurrences.length > 0) {
            return event.attributes.recurrences.map((instance: any) => {
              return {
                id: instance.uid,
                title: `${instance.category_name}: ${instance.name}`,
                start: instance.starts_at,
                end: instance.ends_at,
                allDay: instance.all_day,
                backgroundColor: instance.event_color,
                borderColor: instance.event_color,
              }
            })
          } else {
            return [{
              id: event.attributes.uid,
              title: `${event.attributes.category_name}: ${event.attributes.name}`,
              start: event.attributes.starts_at,
              end: event.attributes.ends_at,
              allDay: event.attributes.all_day,
              backgroundColor: event.attributes.event_color,
              borderColor: event.attributes.event_color
            }]
          }
        }));

        events.push(...sessions.data.data.flatMap((event: IActivitySession) =>  {
          const date = DateTime.fromISO(event.attributes.session_date, {zone: 'utc'});
          const starts_at = DateTime.fromISO(event.attributes.starts_at, {zone: 'utc'});
          const ends_at = DateTime.fromISO(event.attributes.ends_at, {zone: 'utc'});

          return {
            id: `${event.attributes.activity_id}-${event.id}`,
            title: `${event.attributes.activity_name}: ${event.attributes.space_name} - ${event.attributes.tallerista_names}`,
            start: date.set({ hours: starts_at.hour, minutes: starts_at.minute }).toString(),
            end: date.set({ hours: ends_at.hour, minutes: ends_at.minute }).toString(),
            allDay: false,
            backgroundColor: "#1F2937",
            borderColor: "#1F2937",
            url: `/activities/${event.attributes.activity_id}`
          }
        }));

        events.push(...cessions.data.data.flatMap((event: ICessionDate) => {
          const date = DateTime.fromISO(event.attributes.day, {zone: 'utc'});
          const starts_at = DateTime.fromISO(event.attributes.starts_at, {zone: 'utc'});
          const ends_at = DateTime.fromISO(event.attributes.ends_at, {zone: 'utc'});

          return {
            id: `${event.attributes.cession_id}-${event.id}`,
            title: `Cessió: ${event.attributes.space_name || "Espai no especificat"}`,
            start: date.set({ hours: starts_at.hour, minutes: starts_at.minute }).toString(),
            end: date.set({ hours: ends_at.hour, minutes: ends_at.minute }).toString(),
            allDay: false,
            backgroundColor: "#6B7280",
            borderColor: "#6B7280",
            url: `/cessions/${event.attributes.cession_id}`
          }
        }));

        dispatch({
          type: 'updateState',
          payload: {
            events: events,
            isFetching: false,
            error: null
          }
        })
      } catch (e) {
        console.error(e)
        dispatch({
          type: 'updateState',
          payload: {
            events: [],
            isFetching: false,
            error: "Error al carregar la informació"
          }
        })
      }
    }

    dispatch({
      type: 'updateState',
      payload: {
        isFetching: true,
        error: null
      }
    });

    fetchData();
  }, [dispatch, endpoint, state.requestCounter, state.startDate, state.spaceFilter])

  const handleDateChange = ({view}) => {
    dispatch({
      type: 'updateState',
      payload: {
        startDate: moment.utc(view.activeStart),
        endDate: moment.utc(view.activeEnd)
      }
    })
  }

  const reload = useCallback(() => {
    dispatch({
      type: 'updateState',
      payload: {
        requestCounter: state.requestCounter + 1
      }
    });
  }, [state.requestCounter]);

  const eventFormOptions = useMemo(() : useDrawerFormOptionsType<ICalendarEvent> => {
    return {
      title: "Crear event",
      handleCreated: reload,
      handleUpdated: reload,
      newRecord: api.calendarTasks.newInstance()
    }
  }, [reload]);

  const { create: createEvent, edit: editEvent, drawerProps: eventDrawerProps } = useDrawerForm<ICalendarEvent>(api.calendarTasks, eventFormOptions);

  const handelEventClick = ({ event }) => {
    if (event.url) { return };

    editEvent(event.id.split("_")[0]);
  };

  const hideFilter = () => {
    dispatch({
      type: 'updateState',
      payload: {
        showSpaceFilter: false
      }
    });
  };

  const showFilter = () => {
    dispatch({
      type: 'updateState',
      payload: {
        showSpaceFilter: true
      }
    });
  };

  const applyFilters = (formValues) => {
    dispatch({
      type: 'updateState',
      payload: {
        spaceFilter: formValues["spaces"],
        showSpaceFilter: false
      }
    });
  };

  const customButtons = useMemo(() => ({
    createNewEvent: {
      text: 'Afegir event',
      click: createEvent
    },
    filterEvents: {
      text: state.spaceFilter ? `Espai: ${find(spaces, { id: String(state.spaceFilter)}).attributes.name}` : "Filtrar per espai",
      click: showFilter,
    }
  }), [createEvent, spaces, state.spaceFilter]);

  return (
    <>
      <div className="fc-wrapper-full-height">
        <FullCalendar
          timeZone="UTC"
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today createNewEvent",
            center: "title",
            right: "filterEvents dayGridMonth,timeGridWeek,timeGridDay"
          }}
          firstDay={1}
          height='100%'
          locale="ca"
          plugins={[dayGridPlugin, timeGridPlugin]}
          events={state.events}
          datesSet={handleDateChange}
          customButtons={customButtons}
          fixedWeekCount={false}
          buttonText={buttonsText}
          eventClick={handelEventClick}
        />
      </div>

      <DrawerFormWithForwardRef {...eventDrawerProps}>
        {({ form }: DrawerFormChildProps) => (
          <EventForm form={form} />
        )}
      </DrawerFormWithForwardRef>

      <Modal
        title="Filtrar per espai"
        open={state.showSpaceFilter}
        okText="Filtrar"
        onCancel={hideFilter}
        onOk={() => {
          formFilter
            .validateFields()
            .then(values => {
              formFilter.resetFields();
              applyFilters(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form form={formFilter}>
          <Form.Item name={["spaces"]}>
            <Select placeholder="Selecciona l'espai" loading={isFetchingSpaces} filterOption={filterOptionByLabel} showSearch>
              <Select.Option key="all" value={null}>Tots</Select.Option>
              {spaces.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Home;
