import {GET, POST} from '../../constants'
import Http from '../../Http'
import { IQuery } from '../../interfaces/Query'
import { IActivityAttendancesResult, IActivityAttendance, IActivityAttendancesGridResult } from '../../interfaces/activity/Attendance'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";

export default class ActivityAttendance extends Http implements JsonApiDocumentGenerator {
  private readonly activity_id: string;
  private readonly session_id: string;

  constructor(activity_id: string, session_id: string, host?: string) {
    super(host);
    this.activity_id = activity_id;
    this.session_id = session_id;
  }

  public async list(params: IQuery = {}): Promise<IActivityAttendancesResult> {
    return await this.request(GET, Routes.activityAttendancesPath(this.activity_id, this.session_id), {}, params) as IActivityAttendancesResult
  }

  public async grid(params: IQuery = {}): Promise<IActivityAttendancesGridResult> {
    return await this.request(GET, Routes.activityAttendancesPath(this.activity_id, this.session_id), {}, params) as IActivityAttendancesGridResult
  }

  public async save(params?: { activity_registration_id: any; kind: any }): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(POST, Routes.activityAttendancesPath(this.activity_id, this.session_id), {}, params) as ResultResponse<EmptyResponse>
  }

  public async saveGrid(activity_session_id, params?: { activity_registration_id: any; kind: any }): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(POST, Routes.activityAttendancesPath(this.activity_id, activity_session_id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance(attributes = {}) : IActivityAttendance {
    return {
      id: null,
      type: "activity_attendance",
      attributes: {
        activity_session_id: "",
        activity_registration_id: "",
        kind: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: false,
          can_show: false
        }
      },
    };
  }
}
