import React from 'react';
import EndAfter from './After';
import EndOnDate from './OnDate';

import { Select, Row, Col,Form } from 'antd';
import { rowConfig, twoColumns } from 'utils/constants';

const End = ({
  end: {
    mode,
    after,
    onDate,
    options,
  },
  handleChange,
}) => {
  const isOptionAvailable = option => !options.modes || options.modes.indexOf(option) !== -1;
  const isOptionSelected = option => mode === option;

  return (
    <>
      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item label="Finalitza">
            <Select
              name="end.mode"
              value={mode}
              onChange={(value) => handleChange("end.mode", value)}
            >
              {isOptionAvailable('Never') && <Select.Option value="Never">Mai</Select.Option>}
              {isOptionAvailable('After') && <Select.Option value="After">Desprès de</Select.Option>}
              {isOptionAvailable('On date') && <Select.Option value="On date">El dia</Select.Option>}
            </Select>
          </Form.Item>
        </Col>

        {
          isOptionSelected('After') &&
          <EndAfter
            after={after}
            handleChange={handleChange}
          />
        }

        {
          isOptionSelected('On date') &&
          <EndOnDate
            onDate={onDate}
            handleChange={handleChange}
          />
        }
      </Row>
    </>
  );
};

// End.propTypes = {
//   id: PropTypes.string.isRequired,
//   end: PropTypes.shape({
//     mode: PropTypes.string.isRequired,
//     after: PropTypes.number.isRequired,
//     onDate: PropTypes.object.isRequired,
//     options: PropTypes.shape({
//       modes: PropTypes.arrayOf(PropTypes.oneOf(['Never', 'After', 'On date'])),
//       weekStartsOnSunday: PropTypes.bool,
//     }).isRequired,
//   }).isRequired,
//   handleChange: PropTypes.func.isRequired,
//   translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default End;
