import { GET, DELETE, PATCH, POST } from '../../../../api/constants'
import Http from '../../../../api/Http'
import { IQuery  as IRequestParams, IQueryList, IQueryEditable } from '../../../../api/interfaces/Query'
import { IQueryResult, IQueriesResult, IQuery } from '../interfaces/Query'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from '../../../../api/interfaces/JsonApi'
import {ResultResponse} from "../../../../api/interfaces/ResultResponse";

export default class Queries extends Http implements IQueryList, IQueryEditable {
  public async list(params: IRequestParams = {}): Promise<IQueriesResult> {
    return await this.request(GET, Routes.queriesPath(), {}, params) as IQueriesResult
  }

  public async show(id: string, params: IRequestParams = {}): Promise<IQueryResult> {
    return await this.request(GET, Routes.queryPath(id), {}, params) as IQueryResult
  }

  public async create(params: JsonApiDocument): Promise<IQueryResult> {
    return await this.request(POST, Routes.queriesPath(), {}, params) as IQueryResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IQueryResult> {
    return await this.request(PATCH, Routes.queryPath(id), {}, params) as IQueryResult
  }

  public async destroy(id: string, params: IRequestParams = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.queryPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public async changePosition(id: string, params: { category_id: number, position: number }): Promise<IQueryResult> {
    return await this.request(POST, Routes.queryChangePosition(id), {}, params) as IQueryResult
  }

  public newInstance() : IQuery {
    return {
      id: null,
      type: "queries",
      attributes: {
        category_ids: [],
        category_names: "",
        description: "",
        fields: "",
        name: "",
        sql: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
