import React, {useState} from "react"
import {Col, Form, Row, Radio, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {debounce} from "lodash";
import {oneColumn, rowConfig, twoColumns} from "../../../utils/constants";
import api from "api"
import {ITeam} from "../../../api/interfaces/Team";
import {IIndividual} from "../../../api/interfaces/Individual";


export interface PresenceFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const CreateRegistrationForm: React.FC<PresenceFormProps> = ({ form, readOnly = false }) => {
  const [state, setState] = useState({
    data: [],
    loading: false,
    error: null,
    isTeamInscription: false
  })

  const onChangeInscriptionType = (ev) => {
    setState({error: null, loading: false, isTeamInscription: ev.target.value, data: []});
    form.setFieldsValue({ individual_id: null, team_id: null })
  }

  const onSearch = debounce(async (value) => {
    if (value.length < 2) {
      setState({
        ...state,
        data: [],
        loading: false,
        error: null
      })
      return;
    }

    const response = await api[state.isTeamInscription ? "teams" : "individuals"].list({
      items: "all",
      filters: { name: value }
    });

    if (response.isSuccess()) {
      setState({
        ...state,
        data: response.success().data,
        loading: false,
        error: null
      })
    } else {
      setState({
        ...state,
        data: [],
        loading: false,
        error: response.fail().message
      })
    }
  }, 600);

  return (
    <>
      <Row {...rowConfig} className="mb-20">
        <Col {...twoColumns}>
          <Form.Item label="Tipus d'inscripció" initialValue={false}>
            <Radio.Group disabled={readOnly} defaultValue={false} onChange={onChangeInscriptionType}>
              <Radio value={false}>Individu</Radio>
              <Radio value>Equip</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...oneColumn}>
          {!state.isTeamInscription &&
          <Form.Item name={["attributes", "individual_id"]} label="Individus">
            <Select disabled={readOnly} placeholder="Buscar per nom" loading={state.loading}
                    filterOption={false} showSearch onSearch={onSearch}>
              {state.data.map((item: IIndividual) => (
                <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.full_name}</Select.Option>)
              )}
            </Select>
          </Form.Item>
          }
          {state.isTeamInscription &&
            <Form.Item name={["attributes", "team_id"]} label="Equips">
              <Select disabled={readOnly} placeholder="Buscar per nom" loading={state.loading}
                      filterOption={false} showSearch onSearch={onSearch}>
                {state.data.map((item: ITeam) => (
                  <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)
                )}
              </Select>
            </Form.Item>
          }
        </Col>
      </Row>
    </>
  )
};

export default CreateRegistrationForm;
