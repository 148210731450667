import React from "react";
import { Form, Row, Col, Input, Button, Radio, DatePicker, Select, Collapse } from "antd";
import {rowConfig, fourColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {IIndividual} from "api/interfaces/Individual";
import {IQueryEditable} from "../../api/interfaces/Query";
import { dateFormats } from "utils/formats";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { useAxiosRequest } from "use-axios-request";
import { IDictionary } from "api/interfaces/Dictionary";
import { filterOptionByLabel } from "utils/helpers";

interface IndividualFormProps {
  api: IQueryEditable,
  record: IIndividual,
  handleCreated?: (item: IIndividual | IIndividual[]) => void,
  handleUpdated?: (item: IIndividual) => void,
}

class InnerForm extends PageForm<IIndividual> {}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const countriesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("countries"), params: { items: "all" } };
const gendersRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("genders"), params: { items: "all" } };

const IndividualForm: React.FC<IndividualFormProps> = ({ api, record, handleCreated = () => void 0, handleUpdated = () => void 0 }) => {
  const [form] = Form.useForm();

  const { data: countriesData, isFetching: isFetchingCountries } = useAxiosRequest<{ data: IDictionary[] }>(countriesRequest);
  const { data: countries = [] } = countriesData || {};

  const { data: gendersData, isFetching: isFetchingGenders } = useAxiosRequest<{ data: IDictionary[] }>(gendersRequest);
  const { data: genders = [] } = gendersData || {};

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleUpdated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Collapse defaultActiveKey={['1']} bordered={false} className="mb-20">
            <Collapse.Panel header="General" key="1">
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "first_name"]} label="Nom" rules={rules.required}>
                    <Input type="text" disabled={readOnly} placeholder="Nom" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "last_name"]} label="Cognoms" rules={rules.required}>
                    <Input type="text" disabled={readOnly} placeholder="Cognoms" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "birthdate"]} label="Data de naixement">
                    <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "country_id"]} label="Pais" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingCountries} filterOption={filterOptionByLabel} showSearch>
                      {countries.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "phones"]} label="Telèfons de contacte">
                    <Input type="text" disabled={readOnly} placeholder="Telèfons de contacte" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "email"]} label="Correu electrònic">
                    <Input type="text" disabled={readOnly} placeholder="Correu electrònic" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "fb"]} label="FB">
                    <Input type="text" disabled={readOnly} placeholder="FB" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "other_networks"]} label="Altres xarxes">
                    <Input type="text" disabled={readOnly} placeholder="Altres xarxes" />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                <Form.Item name={["attributes", "gender_id"]} label="Sexe" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingGenders} filterOption={filterOptionByLabel} showSearch>
                      {genders.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "social_security_number"]} label="Nº seguretat social">
                    <Input type="text" disabled={readOnly} placeholder="Nº seguretat social" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "photos_authorization"]} label="Autorització fotos">
                    <Radio.Group disabled={readOnly}>
                      <Radio value={false}>No</Radio>
                      <Radio value>Sí</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Malalties" key="2">
              <Form.Item name={["attributes", "diseases"]}>
                <Input.TextArea disabled={readOnly} placeholder="Malalties" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel header="Observacions" key="3">
              <Form.Item name={["attributes", "observations"]}>
                <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default IndividualForm;
