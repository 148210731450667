import React, {useEffect, useReducer} from "react";
import NotFound from "components/NotFound";
import IndividualForm from "./form";
import { PageHeaderWrapper, PageLoading } from "@ant-design/pro-layout";
import { Tab } from "rc-tabs/lib/interface";
import {useQuery} from "hooks/useRouter";
import {Tabs} from "antd";
import api from "../../api";
import {IIndividual} from "api/interfaces/Individual";
import LoadingError from "../../components/LoadingError";
import {JsonApiDocument} from "api/interfaces/JsonApi";
import TeamsList from "./sections/teams";
import RegistrationsList from "./sections/registrations";
import PresencesList from "./sections/presences";
import DemandsNeedsList from "./sections/demands_needs";
import DemandsNeedsAnswersList from "./sections/demands_needs_answers";
import VaultAttachmentsList from "pages/shared/attachments/vaults_list";

export interface IState {
  item?: IIndividual
  included: JsonApiDocument["included"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: null,
  included: [],
  isFetching: false,
  error: null
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const IndividualEditPage: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { individualId } = useQuery();

  const attachmentBaseUrl = React.useMemo(() => `individuals/${individualId}`, [individualId]);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.individuals.show(individualId);

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data,
            included: result.success().included,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            included: [],
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    if (individualId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true
        }
      });

      fetchData();
    }
  }, [individualId, dispatch]);

  if (!individualId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }
  if (!state.item) { return <LoadingError /> }

  const tabs: Tab[] = [
    { key: "1", label: "Inscripcions", children: <RegistrationsList individualId={individualId} /> },
    { key: "2", label: "Presències", children: <PresencesList individualId={individualId} /> },
    { key: "3", label: "Equips / Grups", children: <TeamsList individualId={individualId} /> },
    { key: "4", label: "Demandes / Necessitats", children: <DemandsNeedsList individualId={individualId} /> },
    { key: "5", label: "Respostes", children: <DemandsNeedsAnswersList individualId={individualId} /> },
    { key: "6", label: "Documents", children: <VaultAttachmentsList endpoint={attachmentBaseUrl} initialVault={state.item.attributes.vault_id} /> },
    // { key: "6", label: "Malalities", children: <DiseasesList individualId={individualId} /> },
    // { key: "6", label: "Observacions", children: <ObservationsList individualId={individualId} /> },
  ];

  return (
    <PageHeaderWrapper title="Editar individu">
      <IndividualForm api={api.individuals} record={state.item} />
      <Tabs destroyInactiveTabPane defaultActiveKey="1" items={tabs} animated={false} className="mb-20" />
    </PageHeaderWrapper>
  );
};

export default IndividualEditPage;
