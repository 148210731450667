import React, {useEffect, useReducer} from "react";
import NotFound from "components/NotFound";
import TeamForm from "./form";
import { PageHeaderWrapper, PageLoading } from "@ant-design/pro-layout";
import { Tab } from "rc-tabs/lib/interface";
import {useQuery} from "hooks/useRouter";
import {Tabs} from "antd";
import api from "../../api";
import {ITeam} from "api/interfaces/Team";
import LoadingError from "../../components/LoadingError";
import {JsonApiDocument} from "api/interfaces/JsonApi";
import RegistrationsList from "./sections/registrations";
import CompositionsList from "./sections/compositions";
import MisbehavioursList from "./sections/misbehaviours";
import PresencesList from "./sections/presences";
import IndividualsList from "./sections/individuals";
import ObservationsList from "./sections/observations";

export interface IState {
  item?: ITeam
  included: JsonApiDocument["included"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: null,
  included: [],
  isFetching: false,
  error: null
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const TeamEditPage: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { teamId } = useQuery();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.teams.show(teamId);

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data,
            included: result.success().included,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            included: [],
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    if (teamId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true
        }
      });

      fetchData();
    }
  }, [teamId, dispatch]);

  if (!teamId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }
  if (!state.item) { return <LoadingError /> }

  const tabs: Tab[] = [
    { key: "1", label: "Inscripcions", children: <RegistrationsList teamId={teamId} /> },
    { key: "2", label: "Presències", children: <PresencesList teamId={teamId} /> },
    { key: "3", label: "Conductes", children: <MisbehavioursList teamId={teamId} /> },
    { key: "4", label: "Membres", children: <IndividualsList teamId={teamId} /> },
    { key: "5", label: "Composicions", children: <CompositionsList teamId={teamId} /> },
    { key: "6", label: "Observacions", children: <ObservationsList teamId={teamId} /> },
  ];

  return (
    <PageHeaderWrapper title="Editar equip/grup">
      <TeamForm api={api.teams} record={state.item}/>
      <Tabs destroyInactiveTabPane defaultActiveKey="1" items={tabs} animated={false} className="mb-20" />
    </PageHeaderWrapper>
  );
};

export default TeamEditPage;
